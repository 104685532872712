/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./one-line-list.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../card/card.component.ngfactory";
import * as i3 from "../card/card.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./one-line-list.component";
var styles_OneLineListComponent = [i0.styles];
var RenderType_OneLineListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OneLineListComponent, data: { "animation": [{ type: 7, name: "listAnimation", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":leave", animation: [{ type: 12, timings: 100, animation: [{ type: 6, styles: { opacity: 1, transform: "translateY(0px)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(20px)" }, offset: null }, timings: "0.3s" }] }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 12, timings: 100, animation: [{ type: 6, styles: { opacity: 0, transform: "translateY(-20px)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0px)" }, offset: null }, timings: "0.3s" }] }], options: { optional: true } }], options: null }], options: {} }, { type: 7, name: "fadeInOut", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "scale(1)" }, offset: null }, timings: "275ms ease-in-out" }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "scale(0)" }, offset: null }, timings: "275ms ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_OneLineListComponent as RenderType_OneLineListComponent };
function View_OneLineListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cmj-card", [], null, null, null, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardComponent, [i4.DomSanitizer], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_OneLineListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "scroll-button"]], [[24, "@fadeInOut", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollRight() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "feather feather-chevron-right"], ["fill", "none"], ["height", "24"], ["stroke", "currentColor"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:polyline", [["points", "9 18 15 12 9 6"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
function View_OneLineListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "scroll-button scroll-left"]], [[24, "@fadeInOut", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.scrollLeft() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, ":svg:svg", [["class", "feather feather-chevron-left"], ["fill", "none"], ["height", "24"], ["stroke", "currentColor"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "2"], ["viewBox", "0 0 24 24"], ["width", "24"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:polyline", [["points", "15 18 9 12 15 6"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_OneLineListComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { scrollCardContainer: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["scrollCardContainer", 1]], null, 2, "div", [["class", "card-scroll-container"]], [[8, "scrollLeft", 0], [24, "@listAnimation", 0]], [[null, "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scroll" === en)) {
        var pd_0 = (_co.measureOverflow() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OneLineListComponent_1)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OneLineListComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OneLineListComponent_3)), i1.ɵdid(7, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.items; _ck(_v, 3, 0, currVal_2); var currVal_3 = _co.scrollRightEnabled; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.scrollLeftEnabled; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.scrollLeftValue; var currVal_1 = (_co.items ? ((_co.items == null) ? null : _co.items.length) : 0); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_OneLineListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cmj-one-line-list", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).measureOverflow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_OneLineListComponent_0, RenderType_OneLineListComponent)), i1.ɵdid(1, 4833280, null, 0, i6.OneLineListComponent, [i1.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var OneLineListComponentNgFactory = i1.ɵccf("cmj-one-line-list", i6.OneLineListComponent, View_OneLineListComponent_Host_0, { items: "items" }, {}, []);
export { OneLineListComponentNgFactory as OneLineListComponentNgFactory };
