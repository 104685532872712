var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef, OnChanges, Injector, NgZone } from '@angular/core';
import { WindowService } from 'cmj/window.service';
import { debounce } from 'cmj/debounce.decorator';
import { MissingService } from 'cmj/panel/missing.service';
var States;
(function (States) {
    States[States["Initializing"] = 0] = "Initializing";
    States[States["Ready"] = 1] = "Ready";
    States[States["Opening"] = 2] = "Opening";
    States[States["Open"] = 3] = "Open";
    States[States["Closing"] = 4] = "Closing";
})(States || (States = {}));
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(windowRef, doc, 
    // private missing: MissingService
    injector, ngZone) {
        this.windowRef = windowRef;
        this.doc = doc;
        this.injector = injector;
        this.ngZone = ngZone;
        this.SIZES = [320, 480, 768, 992, 1200, 1680, 1920].reverse();
        this.drawRunning = false;
        this.state = States.Initializing;
        this.ctx = null;
        // @HostBinding('style.height.px') h = 360;
        this.h = 360;
        this.opening = 0;
        this.scroll = 0;
        this.mouseX = null;
        this.mouseY = null;
        this.iconGrid = [];
        this.iconImg = null;
        this.scrollClicked = false;
        if (window.location.pathname.indexOf('/panel') === 0) {
            this.missing = this.injector.get(MissingService);
        }
    }
    Object.defineProperty(HeaderComponent.prototype, "animalPhotoOut", {
        get: function () {
            return this.animalPhoto || (this.eat && this.eat.animalPhoto);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "foodPhotoOut", {
        get: function () {
            return this.foodPhoto || (this.eat && this.eat.foodPhoto);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "foodIconOut", {
        get: function () {
            return (this.foodIcon || (this.eat && this.eat.food && this.eat.food.iconPNG));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HeaderComponent.prototype, "foodColor", {
        // @HostBinding('style.background-color')
        get: function () {
            return this.foodPhotoOut ? this.foodPhotoOut.color : '#999';
        },
        enumerable: true,
        configurable: true
    });
    HeaderComponent.prototype.getOutW = function (w) {
        if (w > this.SIZES[0])
            return this.SIZES[0];
        var found = 0;
        this.SIZES.forEach(function (s) {
            if (w <= s)
                found = s;
        });
        return found;
    };
    HeaderComponent.prototype.onScroll = function (e) {
        this.scroll = e.path[1].scrollY;
    };
    HeaderComponent.prototype.onOrientation = function (e) {
        if (!this.initialDevGamma)
            this.initialDevGamma = e.gamma;
        if (!this.initialDevBeta)
            this.initialDevBeta = e.beta;
        this.devGamma = e.gamma;
        this.devBeta = e.beta;
    };
    HeaderComponent.prototype.onResize = function (e) {
        if (this.outW !== this.getOutW(window.innerWidth)) {
            this.sizeInit();
            this.redraw();
        }
    };
    HeaderComponent.prototype.onMoueseMove = function (e) {
        this.mouseX = e.x;
        this.mouseY = e.y;
    };
    // @HostListener('window:resize', ['$event'])
    // onEveryResize(e: any) {
    // 	console.log('resizingXXX...');
    // }
    HeaderComponent.prototype.ngOnInit = function () {
        if (!this.cRef)
            throw new Error('no element');
        this.c = this.cRef.nativeElement;
        this.sizeInit();
    };
    HeaderComponent.prototype.sizeInit = function () {
        var window = this.windowRef.nativeWindow;
        this.rawW = window.innerWidth;
        this.outW = this.getOutW(window.innerWidth);
        this.adjustHeight();
        this.scroll = window.scrollY;
    };
    HeaderComponent.prototype.ngOnChanges = function (changes) {
        this.redraw();
    };
    HeaderComponent.prototype.adjustHeight = function () {
        if (this.outW && this.outW <= 320)
            this.h = 180;
        else if (this.outW && this.outW <= 480)
            this.h = 260;
        else
            this.h = 360;
    };
    HeaderComponent.prototype.redraw = function () {
        var _this = this;
        this.opening = 0;
        this.state = States.Initializing;
        this.headerImage = undefined;
        setTimeout(function () { return _this.initDraw(); });
    };
    HeaderComponent.prototype.makeCanvasForText = function (w) {
        if (w === void 0) { w = this.outW; }
        if (!w)
            throw new Error('no w');
        var c = this.doc.createElement('canvas');
        c.height = this.h;
        c.width = w;
        var ctx = c.getContext('2d');
        if (!ctx)
            throw new Error('no ctx');
        ctx.fillStyle = '#000000';
        ctx.font = 2 * this.h + 'px Arial';
        ctx.textAlign = 'center';
        return { c: c, ctx: ctx };
    };
    HeaderComponent.prototype.checkChangeH = function (c0) {
        var c = this.doc.createElement('canvas');
        c.height = c0.height / 2;
        c.width = c0.width / 2;
        var ctx = c.getContext('2d');
        if (!ctx)
            throw new Error('no ctx');
        // this.logImage(c0);
        ctx.drawImage(c0, 0, 0, c0.width, c0.height, 0, 0, c0.width / 2, c0.height / 2);
        var imgData = ctx.getImageData(0, 0, c.width, c.height);
        var data = imgData.data;
        // this.logImage(c0);
        // this.logImage(c);
        var found = 0;
        var found2 = 9999;
        var hChecked = null;
        for (var i = 0; i < data.length; i += 4) {
            var pixel = i / 4;
            var w = pixel % c.width;
            var h = (pixel - w) / c.width;
            if (h === hChecked)
                continue;
            // console.log('wh', w, h);
            var alpha = data[i + 3];
            // console.log('aa', alpha);
            if (alpha === 0) {
                hChecked = h;
                found = Math.max(found, w);
            }
            if (alpha < 255) {
                found2 = Math.min(found2, w);
            }
        }
        return [found * 2 * 1.05, found2 * 2 * 0.95];
    };
    HeaderComponent.prototype.makeMasks = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!_this.outW)
                throw new Error('no out-w');
            var _a = _this.makeCanvasForText(_this.outW / 2), c1h = _a.c, ctx1h = _a.ctx;
            ctx1h.fillText('?', 0, 1.4 * _this.h);
            var _b = _this.makeCanvasForText(), c1 = _b.c, ctx1 = _b.ctx;
            ctx1.fillText('?', _this.outW / 2, 1.4 * _this.h);
            for (var i = 0; i < 95; i++) {
                ctx1.drawImage(c1h, _this.outW / 2 + i * (_this.h * 0.05), 0);
            }
            var _c = _this.makeCanvasForText(), c2 = _c.c, ctx2 = _c.ctx;
            for (var i = 0; i < 65; i++) {
                ctx2.fillText('?', _this.outW / 2 - i * (_this.h * 0.05), 1.4 * _this.h);
            }
            var points = _this.checkChangeH(c2);
            resolve({ l: c1, r: c2, p: points[0], p2: points[1] });
        });
    };
    HeaderComponent.prototype.loadIcon = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var img = new Image();
            _this.iconImg = img;
            img.crossOrigin = 'Anonymous';
            var loaded = function () { return resolve(); };
            img.onload = loaded;
            img.crossOrigin = 'anonymous';
            if (!_this.foodIconOut)
                return _this.missing && _this.missing.newMiss('icon');
            img.src = _this.foodIconOut;
        });
    };
    HeaderComponent.prototype.loadImage = function (photo, side, point, point2) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var img = new Image();
            img.crossOrigin = 'Anonymous';
            var loaded = function () {
                var c = _this.doc.createElement('canvas');
                c.height = _this.h;
                if (!_this.outW)
                    throw new Error('no out-w');
                c.width = _this.outW;
                var ctx = c.getContext('2d');
                if (!ctx)
                    throw new Error('no ctx');
                var targetW = side === 'l' ? point : _this.outW - point2;
                var targetH = targetW / (img.width / img.height);
                var pullLeft = 0;
                if (targetH < _this.h) {
                    var prevH = targetH;
                    var prevW = targetW;
                    targetH = _this.h;
                    targetW = targetW / (prevH / targetH);
                    pullLeft = prevW - targetW;
                }
                ctx.save();
                if (photo.flipped) {
                    ctx.translate(targetW, 0);
                    ctx.scale(-1, 1);
                    pullLeft = pullLeft * -1;
                }
                var outX = (side === 'l' ? 0 : point2) + pullLeft / 2;
                var outY = (targetH - _this.h) / -2;
                ctx.drawImage(img, outX, outY, targetW, targetH);
                ctx.restore();
                // this.logImage(c);
                resolve(c);
            };
            img.onload = loaded;
            img.src = _this.outW && _this.outW >= 1200 ? photo.urlBig : photo.url;
        });
    };
    HeaderComponent.prototype.logImage = function (c, name) {
        if (name)
            console.log('Image ' + name + ' :');
        console.log("%c       ", "\n\t\t\tfont-size: 100px;\n\t\t\tbackground-image: url(" + c.toDataURL() + ");\n\t\t\tbackground-repeat: no-repeat;\n\t\t\tbackground-size: contain;\n\t\t");
    };
    HeaderComponent.prototype.maskImages = function () {
        var _this = this;
        var leftMask;
        var rightMask;
        var point = null;
        var point2 = null;
        return this.makeMasks()
            .then(function (_a) {
            var l = _a.l, r = _a.r, p = _a.p, p2 = _a.p2;
            leftMask = l;
            rightMask = r;
            point = p;
            point2 = p2;
            // this.logImage(leftMask, 'leftMask');
            // this.logImage(rightMask, 'rightMask');
            if (!_this.animalPhotoOut)
                throw new Error('no this.animalPhoto');
            if (!_this.foodPhotoOut)
                throw new Error('no this.foodPhoto');
            return Promise.all([
                _this.loadImage(_this.animalPhotoOut, 'l', point, point2),
                _this.loadImage(_this.foodPhotoOut, 'r', point, point2)
            ]);
        })
            .then(function (canvses) {
            var animalImageCanvas = canvses[0], foodImageCanvas = canvses[1];
            var ctxA = animalImageCanvas.getContext('2d');
            if (!ctxA)
                throw new Error('canvas context creation failed');
            ctxA.save();
            ctxA.globalCompositeOperation = 'destination-out';
            if (!leftMask)
                throw new Error('no left');
            ctxA.drawImage(leftMask, 0, 0);
            ctxA.restore();
            _this.maskedLeft = animalImageCanvas;
            // }).then(foodImageCanvas => {
            var ctxF = foodImageCanvas.getContext('2d');
            if (!ctxF)
                throw new Error('canvas context creation failed');
            ctxF.save();
            ctxF.globalCompositeOperation = 'destination-out';
            if (!rightMask)
                throw new Error('no right');
            ctxF.drawImage(rightMask, 0, 0);
            ctxF.restore();
            _this.maskedRight = foodImageCanvas;
            if (!_this.ctx || !_this.foodColor || !_this.outW)
                throw new Error('no context');
            _this.ctx.fillStyle = _this.foodColor;
            _this.ctx.fillRect(0, 0, _this.outW, _this.h);
            // this.logImage(this.maskedRight);
            _this.ctx.drawImage(foodImageCanvas, 0, 0);
            if (!_this.maskedLeft)
                throw new Error('no this.maskedLeft');
            // this.logImage(this.maskedLeft, 'this.maskedLeft');
            _this.ctx.drawImage(animalImageCanvas, 0, 0);
            // this.logImage(this.c!);
            _this.state = States.Ready;
            // alert('aaa');
            // console.log('%c       ', 'font-size: 100px; background: url(' + this.c.toDataURL() + ') no-repeat;');
        });
    };
    HeaderComponent.prototype.initDraw = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.c)
                            return [2 /*return*/, false];
                        this.ctx = this.ctx || this.c.getContext('2d');
                        if (!this.ctx || !this.outW)
                            return [2 /*return*/, false];
                        this.ctx.fillStyle = this.foodColor;
                        this.ctx.fillRect(0, 0, this.outW, this.h);
                        // if (this.eat) {
                        // 	const tmpSizes = [...this.SIZES].reverse();
                        // 	this.srcSet = tmpSizes.map(s => {
                        // 		if (!this.eat) throw new Error('impossibiru');
                        // 		return this.eat.getPhotoUrl('s' + s) + ' ' + s + 'w';
                        // 	}).join(', ');
                        // 	this.srcSetSizes = tmpSizes.map(s => {
                        // 		return `(max-width: ${s}px) ${s}px`;
                        // 	}).join(', ');
                        // 	this.headerImage = this.eat.getPhotoUrl('s' + this.outW);
                        // }
                        if (this.eat)
                            this.headerImage = this.eat.getPhotoUrl('s' + this.outW);
                        if (!(this.foodPhotoOut && this.animalPhotoOut) && !this.eat) {
                            if (this.c)
                                this.c.style.opacity = '0';
                            return [2 /*return*/, false];
                        }
                        if (this.c)
                            this.c.style.opacity = '1';
                        this.createIconGrid();
                        return [4 /*yield*/, this.loadIcon()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.maskImages()];
                    case 2:
                        _a.sent();
                        if (!this.drawRunning)
                            window.requestAnimationFrame(function () { return _this.draw(); });
                        this.drawRunning = true;
                        return [2 /*return*/];
                }
            });
        });
    };
    HeaderComponent.prototype.makeHeaderImg = function () {
        return __awaiter(this, void 0, void 0, function () {
            var prevW, out, _i, _a, size;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.scrollClicked = false;
                        this.scroll = 0;
                        prevW = this.rawW;
                        out = {};
                        _i = 0, _a = this.SIZES;
                        _b.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 4];
                        size = _a[_i];
                        if (!this.c)
                            throw new Error('no canvas');
                        this.rawW = size;
                        this.outW = this.getOutW(this.rawW);
                        this.adjustHeight();
                        this.c.width = this.outW;
                        this.c.width = this.h;
                        this.state = States.Initializing;
                        return [4 /*yield*/, this.initDraw()];
                    case 2:
                        _b.sent();
                        out['s' + size] = this.c.toDataURL('image/jpeg', 0.8);
                        _b.label = 3;
                    case 3:
                        _i++;
                        return [3 /*break*/, 1];
                    case 4:
                        this.rawW = prevW;
                        this.outW = this.getOutW(window.innerWidth);
                        this.adjustHeight();
                        this.state = States.Initializing;
                        return [4 /*yield*/, this.initDraw()];
                    case 5:
                        _b.sent();
                        return [2 /*return*/, out];
                }
            });
        });
    };
    HeaderComponent.prototype.createIconGrid = function () {
        if (!this.outW || !this.h)
            throw new Error('uu');
        var offset = 40;
        var space = 60;
        var x = -offset;
        var y = -offset;
        var i = 1;
        while (x < this.outW + offset) {
            while (y < this.h + offset) {
                var tmp = i % 2 ? space / 2 : 0;
                this.iconGrid.push([x, y + tmp]);
                y += space;
            }
            y = -offset;
            x += space;
            i++;
        }
    };
    HeaderComponent.prototype.draw = function () {
        var _this = this;
        if (!this.c)
            return false;
        if (!this.ctx)
            return false;
        var isScrolled = this.preview ? this.scrollClicked : this.scroll > 4;
        if (this.state === States.Ready && isScrolled)
            this.state = States.Opening;
        if (this.maskedLeft &&
            this.maskedRight &&
            this.outW &&
            this.rawW &&
            this.iconImg &&
            this.foodColor &&
            ![States.Initializing, States.Ready].includes(this.state)) {
            this.ctx.fillStyle = this.foodColor;
            this.ctx.fillRect(0, 0, this.outW, this.h);
            var opening0 = this.opening + 1;
            // 2000 - 2
            // 320 - 4
            var speed = 1 + Math.pow(opening0, 1 / Math.pow(16000 / this.outW, 1 / 3));
            if (this.state === States.Opening)
                this.opening += speed;
            else if (this.state === States.Closing)
                this.opening -= speed;
            this.opening = Math.min(100, Math.max(0, this.opening));
            var position = (this.opening / 100) * this.outW;
            var offset = 40;
            var space = 60;
            var x = -offset;
            var y = 2 - offset;
            var i = 1;
            var parallaxScroll = this.scroll / 3;
            var mouseEffDist = this.outW / 8;
            while (x < this.outW + offset) {
                while (y - parallaxScroll < this.h + offset) {
                    var tmp = i % 2 ? space / 2 : 0;
                    var targetY = y + tmp - parallaxScroll;
                    if (targetY < -offset || targetY > this.h + offset) {
                        y += space;
                        continue;
                    }
                    if (this.initialDevBeta &&
                        this.devBeta &&
                        this.initialDevGamma &&
                        this.devGamma) {
                        var scaleX = 1 -
                            (0.01 *
                                (x / this.outW - 0.5) *
                                (this.initialDevGamma - this.devGamma)) /
                                this.initialDevGamma;
                        var scaleY = 1 +
                            (0.002 *
                                (targetY / this.h - 0.5) *
                                (this.initialDevBeta - this.devBeta)) /
                                this.initialDevBeta;
                        var scale = scaleX * scaleY;
                        this.ctx.scale(scale, scale);
                        var drawX = x / scale - this.iconImg.width / 2;
                        var drawY = targetY / scale - this.iconImg.height / 2;
                        this.ctx.globalAlpha =
                            (this.opening / 100) * 0.08 * Math.sqrt(scale);
                        this.ctx.drawImage(this.iconImg, drawX, drawY);
                        this.ctx.scale(1 / scale, 1 / scale);
                    }
                    else if (this.mouseX !== null && this.mouseY !== null) {
                        var realMouseX = this.mouseX - (this.rawW - this.outW) / 2;
                        var distX = x - realMouseX;
                        var distY = targetY - this.mouseY;
                        var dist = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2));
                        var scale = 1;
                        if (dist < mouseEffDist)
                            scale = 1 + (mouseEffDist - dist) / (mouseEffDist * 2);
                        this.ctx.scale(scale, scale);
                        var drawX = x / scale - this.iconImg.width / 2;
                        var drawY = targetY / scale - this.iconImg.height / 2;
                        this.ctx.globalAlpha = (this.opening / 100) * 0.08 * scale;
                        this.ctx.drawImage(this.iconImg, drawX, drawY);
                        this.ctx.scale(1 / scale, 1 / scale);
                    }
                    else {
                        this.ctx.globalAlpha = (this.opening / 100) * 0.08;
                        var drawX = x - this.iconImg.width / 2;
                        var drawY = targetY - this.iconImg.height / 2;
                        this.ctx.drawImage(this.iconImg, drawX, drawY);
                        // this.ctx.drawImage(this.iconImg, x, targetY);
                    }
                    // this.ctx.drawImage(this.iconImg, x, targetY);
                    y += space;
                }
                y = -offset;
                x += space;
                i++;
            }
            this.ctx.globalAlpha = (100 - this.opening) / 100;
            if (this.state !== States.Open) {
                this.ctx.drawImage(this.maskedLeft, -1 * position, 0);
                this.ctx.drawImage(this.maskedRight, position, 0);
            }
            this.ctx.globalAlpha = 1;
            if (this.state === States.Opening && this.opening >= 100) {
                this.opening = 100;
                this.state = States.Open;
            }
            else if (!isScrolled && this.state !== States.Closing) {
                this.state = States.Closing;
            }
            else if (this.state === States.Closing && this.opening <= 0) {
                this.opening = 0;
                this.state = States.Ready;
            }
        }
        this.ngZone.runOutsideAngular(function () {
            window.requestAnimationFrame(function () { return _this.draw(); });
        });
    };
    __decorate([
        debounce(),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], HeaderComponent.prototype, "onResize", null);
    return HeaderComponent;
}());
export { HeaderComponent };
