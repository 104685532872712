import * as Parse from 'parse';
import { CachedQuery } from 'cmj/model/cachedQuery';
import { Animal } from 'cmj/model/animal';
import { Food } from 'cmj/model/food';
import { Photo } from 'cmj/model/photo';
import { environment } from 'environments/environment';
import { Helpers } from 'cmj/helpers';
// const Parse = require('parse');

const NAME = 'Eat';

interface RawConn {
	[type: string]: { i: boolean; id: string }[];
}
interface ConnEl {
	id: string; // object id
	t: string; // type
	i: boolean; // need to include it?
	saved?: boolean;
}
export enum AffTypes {
	KAKADU = 'kakadu'
}
export class Eat extends Parse.Object {
	// static get safetyLevels() {
	// 	return Object.keys(safetyLevels).map(k => ({k: k, v: safetyLevels[k]}));
	// }

	constructor(params?: any) {
		super(NAME, params);
	}

	get title() {
		if (environment.lang === 'pl')
			return `Czy ${this.animal.usedName} może ${this.food.eatOrDrinkText} ${
				this.food.title
			}?`;
	}

	get animal(): Animal {
		return this.get('animal');
	}

	get food(): Food {
		return this.get('food');
	}

	set safety(safety: Eat.SafetyLevels) {
		this.set('safety', safety);
	}

	get safety(): Eat.SafetyLevels {
		return this.get('safety');
	}

	get safetyReadable(): string {
		return Eat.SafetyLevels[this.get('safety')];
	}

	getSafetyReadable(): string {
		return Eat.SafetyLevels[this.get('safety')];
	}

	colors: any = {
		safe: '#00d01b',
		ok: '#f1bf00',
		nok: '#e67700',
		bad: '#d0005e'
	};
	get safetyColor(): string {
		return this.colors[this.safety];
	}

	set text(text: string) {
		this.set('text', text);
	}

	get text(): string {
		return this.get('text') || '';
	}

	get preview(): string {
		const space = this.text.indexOf(' ', 54);
		if (space === -1) return this.text;
		else return this.text.substr(0, space) + '...';
	}

	set summary(summary: string) {
		this.set('summary', summary);
	}

	get summary(): string {
		return this.get('summary') || '';
	}

	get headersOK(): boolean {
		return this.get('headersOK');
	}

	set headersOK(ok: boolean) {
		this.set('headersOK', ok);
	}

	set dangers(dangers: string) {
		this.set('dangers', dangers) || '';
	}

	get dangers(): string {
		return this.get('dangers');
	}

	set source(source: string) {
		this.set('source', source);
	}

	get source(): string {
		return this.get('source');
	}

	get chars() {
		return this.summary.length + this.text.length + this.dangers.length;
	}

	get sourceReadable(): string[] {
		return (this.source || '')
			.split(',')
			.map(s => s.trim())
			.filter(s => s.length > 0)
			.map(s =>
				s
					.replace('www.', '')
					.replace('https://', '')
					.replace('http://', '')
			)
			.map(s => {
				const endsWithSlash = s[s.length - 1] === '/';
				return endsWithSlash ? s.slice(0, s.length - 1) : s;
			});
	}

	get animalPhoto(): Photo | undefined {
		return this.get('animalPhoto');
	}
	set animalPhoto(photo: Photo | undefined) {
		this.set('animalPhoto', photo);
	}

	get foodPhoto(): Photo | undefined {
		return this.get('foodPhoto');
	}
	set foodPhoto(photo: Photo | undefined) {
		this.set('foodPhoto', photo);
	}

	get connected(): ConnEl[] {
		const c: RawConn = this.get('connected');
		const connections: ConnEl[] = [];
		Object.entries(c).forEach(el => {
			el[1].forEach(subel =>
				connections.push({
					t: el[0],
					id: subel.id,
					i: subel.i,
					saved: true
				})
			);
		});
		return connections;
	}

	set connected(conn: ConnEl[]) {
		const cObj = conn.reduce(
			(o, el) => {
				if (!o[el.t]) o[el.t] = [];
				o[el.t].push({ i: el.i, id: el.id });
				return o;
			},
			{} as RawConn
		);
		this.set('connected', cObj);
	}

	get gotMiniPhoto() {
		const file: Parse.File = this.get('mini');
		const fileFallback: Parse.File = this.get('s480');
		return file || fileFallback ? true : false;
	}

	get miniPhotoUrl() {
		const file: Parse.File = this.get('mini');
		const fileFallback: Parse.File = this.get('s480');
		if (!file && !fileFallback) {
			console.error('no eat MINI photo file');
			return Photo.errorPixel;
		}
		const url = file ? file.url() : fileFallback.url();
		return url.replace('http://', 'https://');
	}

	get microPhotoUrl() {
		const base64: string = this.get('micro');
		return base64;
	}

	get microPhotoStyle() {
		return `url(${this.microPhotoUrl})`;
	}

	get canonicalUrl() {
		const loc = window.location;
		const title = (this.title || '')
			.toLowerCase()
			.replace(/\s/g, '-')
			.replace('?', '');
		return `${loc.protocol}//${loc.host}/${title}`;
	}

	get summaryLine() {
		let foodPart = '';
		if (this.food.plural === this.food.title) {
			foodPart = this.food.plural;
			const safety = Food.genderSafetyWords['nijaki'][this.get('safety')];
			return `${foodPart} są <span>${safety}</span>`;
		} else {
			foodPart = this.food.singular;
			const safety =
				Food.genderSafetyWords[this.food.get('gender') || 'meski'][
					this.get('safety')
				];
			return `${foodPart} jest <span>${safety}</span>`;
		}
	}

	public static get(id: string) {
		const q = new CachedQuery(Eat);
		q.include('food');
		q.include('animal');
		q.exists('mini');
		q.notEqualTo('disabled', true);
		return Promise.resolve(q.get(id));
	}

	public static getAll(withErrors = false) {
		const q = new CachedQuery(Eat);
		q.limit(1000);
		q.include('food');
		q.include('animal');
		if (!withErrors) q.exists('mini');
		q.notEqualTo('disabled', true);
		return Promise.resolve(q.find());
	}

	public static async get5() {
		const q = new CachedQuery(Eat);
		q.limit(5);
		q.exists('mini');
		q.notEqualTo('disabled', true);
		return await Promise.resolve(q.find());
	}

	public static getManyFromAF<T>(
		this: new () => T,
		a: Animal | undefined,
		f: Food | undefined,
		withErrors?: boolean,
		start?: number,
		limit?: number
	): Promise<Eat[]>;
	public static getManyFromAF<T>(
		this: new () => T,
		a: string | undefined,
		f: string | undefined,
		withErrors?: boolean,
		start?: number,
		limit?: number
	): Promise<Eat[]>;
	public static getManyFromAF<T>(
		this: new () => T,
		a: string | Animal | undefined,
		f: string | Food | undefined,
		withErrors = false,
		start = 0,
		limit = 100
	) {
		const idA = a ? (typeof a === 'string' ? a : a.id) : null;
		const idF = f ? (typeof f === 'string' ? f : f.id) : null;
		const q = new CachedQuery(Eat);
		q.include('food');
		q.include('food.photos');
		q.include('animal');
		q.include('animal.photos');
		if (limit) q.limit(limit);
		if (start) q.skip(start);
		if (!withErrors) q.exists('mini');

		if (a)
			q.equalTo('animal', {
				__type: 'Pointer',
				className: 'Animal',
				objectId: idA
			});
		if (f)
			q.equalTo('food', {
				__type: 'Pointer',
				className: 'Food',
				objectId: idF
			});
			q.notEqualTo('disabled', true);
		return Promise.resolve(q.find());
	}

	public static async getFromAF(
		a: Animal,
		f: Food,
		includePhotos: boolean | undefined
	): Promise<Eat>;
	public static async getFromAF(
		a: string,
		f: string,
		includePhotos: boolean | undefined
	): Promise<Eat>;
	public static async getFromAF(
		a: string | Animal,
		f: string | Food,
		includePhotos = false
	) {
		const idA = typeof a === 'string' ? a : a.id;
		const idF = typeof f === 'string' ? f : f.id;
		const q = new CachedQuery(Eat);
		q.include('food');
		q.include('food.mainPhoto');
		q.include('animal');
		q.include('animal.mainPhoto');
		q.notEqualTo('disabled', true);
		q.equalTo('animal', {
			__type: 'Pointer',
			className: 'Animal',
			objectId: idA
		});
		q.equalTo('food', { __type: 'Pointer', className: 'Food', objectId: idF });
		const eat = await q.first();

		if (eat && includePhotos) {
			const qPa = new CachedQuery(Photo);
			qPa.equalTo('animal', eat.animal);
			qPa.exists('big');
			eat.animal.photos = await qPa.find();
			console.log('eat.animal.photos', eat.animal.photos);

			const qPf = new CachedQuery(Photo);
			qPf.equalTo('food', eat.food);
			qPa.exists('big');
			eat.food.photos = await qPf.find();
			console.log('eat.food.photos', eat.food.photos);
		}
		return eat;
	}

	public static getFromNames(a: string, f: string) {
		const q = new CachedQuery(Eat);
		console.log('fff', f);

		const animalQ = new Parse.Query(Animal);
		animalQ.equalTo('aliases.' + environment.lang, a);
		q.matchesQuery('animal', animalQ);

		const foodQ = new Parse.Query(Food);
		foodQ.equalTo('aliases.' + environment.lang, f);
		q.matchesQuery('food', foodQ);

		q.include('food');
		q.include('foodPhoto');
		q.include('animal');
		q.include('animalPhoto');
		q.notEqualTo('disabled', true);

		return Promise.resolve(q.first());
	}

	public static create(animal: Animal, food: Food) {
		const e = new Eat();
		e.set('animal', animal);
		e.set('food', food);
		return e;
	}

	async addHeaders(headers: { [s: string]: string }) {
		for (const str in headers) {
			if (headers.hasOwnProperty(str)) {
				const base64 = headers[str];
				const name = await Helpers.removeDiacritics(
					this.animal.name + '-' + this.food.name
				);
				const parseFile = new Parse.File(name + '.jpg', { base64 });
				this.set(str, parseFile);

				if (str === 's480') {
					const base64mini = await Helpers.createResized(base64, 480);
					const parseFileMini = new Parse.File(name + '.jpg', {
						base64: base64mini
					});
					this.set('mini', parseFileMini);

					const base64micro = await Helpers.createResized(base64, 12);
					// const parseFileMicro = new Parse.File(name + ".jpg", {
					// 	base64: base64micro
					// });
					this.set('micro', base64micro);
				}
			}
		}
		this.set('headersOK', true);
		await this.save();
		return this;
	}

	getPhotoUrl(size: string): string {
		const tmp = this.get(size);
		if (!tmp || !tmp.url()) {
			console.error('no eat photo file');
			return Photo.errorPixel;
		}
		return tmp.url().replace('http://', 'https://');
	}

	setAffiliate(type: string, link: string) {
		const tmp = this.get('affiliate') || {};
		tmp[type] = link;
		this.set('affiliate', tmp);
		this.save();
	}

	get affiliate() {
		return this.get('affiliate') || {};
	}

	getAffShopLink(type: AffTypes) {
		if ((type = AffTypes.KAKADU)) {
			const ids: { [name: string]: string } = {
				pies: '1',
				kot: '2'
			};
			const id: string = ids[this.animal.singular];
			const name = encodeURIComponent(this.food.singular);
			return `https://e-sklep.kakadu.pl/szukaj.htm?opt=${id}&find=${name}&image.x=6&image.y=7`;
		}
	}

	getRandomAffLink() {
		const r = Math.floor((Math.random() * Object.keys(AffTypes).length) / 2);
		return this.getAffLink(AffTypes[Object.keys(AffTypes)[r] as any as keyof typeof AffTypes] as any);
	}

	getAffLink(type: AffTypes) {
		const tmp = this.get('affiliate');
		if (!tmp) return undefined;
		const raw = tmp[type];
		if (!raw) return undefined;
		if ((type = AffTypes.KAKADU)) {
			const base =
				'http://nsm.tr.netsalesmedia.pl/ts/i5034330/tsc?amc=con.cubegroup.450211.465353.149470&smc=aaa&rmd=3&trg=';
			return base + encodeURIComponent(raw);
		}
	}

	getAffRawLink(type: AffTypes): string | undefined {
		const tmp = this.get('affiliate');
		if (!tmp) return undefined;
		return tmp[type];
	}

	get gotAffiliateLink(): boolean {
		const tmp = this.get('affiliate');
		return tmp && Object.keys(tmp).length > 0;
	}

	getDefaultAffLinkUsed(type: string): boolean {
		return (this.affiliate || {})[type] === this.getAffShopLink(type as any);
	}
}

export namespace Eat {
	export enum SafetyLevels {
		safe = 'bezpieczne',
		ok = 'dopuszczalne',
		nok = 'niewskazane',
		bad = 'szkodliwe'
	}
}

Parse.Object.registerSubclass(NAME, Eat);
