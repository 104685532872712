import {
	Directive,
	Output,
	EventEmitter,
	ElementRef,
	HostListener
} from '@angular/core';

@Directive({
	selector: '[checkLoadMore]'
})
export class CheckLoadMoreDirective {
	@Output() checkLoadMore = new EventEmitter<boolean>();
	timeout = false;

	@HostListener('window:scroll', [])
	onWindowScroll() {
		if (this.timeout) return;
		const bb = this.el.getBoundingClientRect();
		const bottom = bb.top + bb.height;
		const scrollBottom = window.scrollY + window.innerHeight;
		if (bottom < scrollBottom) {
			this.checkLoadMore.emit(true);
			this.timeout = true;
			setTimeout(() => (this.timeout = false), 1000);
		}
	}
	el: HTMLElement;
	constructor(el: ElementRef) {
		this.el = el.nativeElement;
	}
}
