import { Injectable } from '@angular/core';
// import { AppModule } from './app.module';

// export interface ICustomWindow extends Window {
// 	__custom_global_stuff: string;
// }

function getWindow (): any {
	return window;
}

// @Injectable({
// 	providedIn: AppModule,
// })
@Injectable()
export class WindowService {

	get nativeWindow (): Window {
		return getWindow();
	}
}
