<div
	class="main"
	[style.height.px]="(height + width) * (animal || food ? 0.15 : 0.3)"
	[ngClass]="{ category: animal || food }"
>
	<div class="wrapper">
		<div class="bg-wrapper"></div>
		<div class="container" [ngClass]="{ category: animal || food }">
			<app-title
				[food]="food"
				[foodName]="foodName"
				[animal]="animal"
				[animalName]="animalName"
				(change)="titleChanged($event)"
			></app-title>
			<div class="subtitle">
				<p>Porady żywieniowe dla twojego pupila</p>
			</div>
		</div>
	</div>
</div>
<div class="section-container"><router-outlet></router-outlet></div>
