// import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
// import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
// import { HttpClientModule } from '@angular/common/http';
import { MainComponent } from './main/main.component';
import { AppConfig } from './app.config';
import { FormsModule } from '@angular/forms';
import { TitleComponent } from './title/title.component';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NoscrollDirective } from './title/noscroll.directive';
import { WindowService } from 'cmj/window.service';
import { HeadModule } from 'cmj/head/head.module';
import { ItemComponent } from './item/item.component';
import { LandingComponent } from './landing/landing.component';
import { CategoryComponent } from './category/category.component';
import { CategorySelectedComponent } from './category-selected/category-selected.component';
import { Overlay404Component } from './overlay404/overlay404.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FabButtonComponent } from './fab-button/fab-button.component';
import { FooterComponent } from './footer/footer.component';
import {
	NgModule,
	Injectable,
	ErrorHandler,
	Injector,
	InjectionToken
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CardComponent } from './modules/card/card.component';
import { OneLineListComponent } from './modules/one-line-list/one-line-list.component';
import * as Rollbar from 'rollbar';
import { CheckLoadMoreDirective } from './check-load-more.directive';
import { AdsenseModule } from 'ng2-adsense';
import { AdsensearticleComponent } from './adsensearticle/adsensearticle.component';

const rollbarConfig = {
	accessToken: 'fabbd596309e41dfb630334a963be636',
	captureUncaught: true,
	captureUnhandledRejections: true
};
export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
	constructor(private injector: Injector) {}

	handleError(err: any): void {
		const rollbar = this.injector.get(RollbarService);
		rollbar.error(err.originalError || err);
	}
}

export function rollbarFactory() {
	return new Rollbar(rollbarConfig);
}

// import * as Sentry from '@sentry/browser';
// import { environment } from 'environments/environment';

// Sentry.init({
// 	dsn: 'https://184fa1eeb2754740b9504eb1e7d7edfc@sentry.io/1399731',
// 	release: 'cmj@' + environment.VERSION
// });

// @Injectable()
// export class SentryErrorHandler implements ErrorHandler {
// 	constructor() {}
// 	handleError(error: any) {
// 		Sentry.captureException(error.originalError || error);
// 		throw error;
// 	}
// }

@NgModule({
	declarations: [
		AppComponent,
		HomeComponent,
		MainComponent,
		TitleComponent,
		NoscrollDirective,
		ItemComponent,
		LandingComponent,
		CategoryComponent,
		CategorySelectedComponent,
		Overlay404Component,
		PageNotFoundComponent,
		FabButtonComponent,
		FooterComponent,
		CardComponent,
		OneLineListComponent,
		CheckLoadMoreDirective,
		AdsensearticleComponent
	],
	imports: [
		BrowserModule,
		HttpClientModule,
		AppRoutingModule,
		RouterModule,
		FormsModule,
		BrowserAnimationsModule,
		// BrowserTransferStateModule
		HeadModule,
		AdsenseModule.forRoot({
			adClient: 'ca-pub-6319611442758024',
			pageLevelAds: true
		})
	],
	providers: [
		AppConfig,
		WindowService
		// { provide: ErrorHandler, useClass: SentryErrorHandler }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(config: AppConfig) {}
}
