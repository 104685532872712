import * as Parse from 'parse';
import { environment } from 'environments/environment';
import { Category } from './category';

const NAME = 'Animal';

export class Animal extends Category {
	private static names: { [lang: string]: string } = {
		pl: 'zwierzak'
	};
	public static get NAME() {
		return this.names[environment.lang];
	}
	constructor(params?: any) {
		super(NAME, params);
	}

	get title(): string {
		if (this.get('title')) return this.get('title')[environment.lang];
		return this.get('name') ? this.get('name')[environment.lang] : '';
	}
}

Parse.Object.registerSubclass(NAME, Animal);
