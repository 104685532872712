var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Parse from 'parse';
var CachedQuery = /** @class */ (function (_super) {
    __extends(CachedQuery, _super);
    function CachedQuery(objectClass) {
        return _super.call(this, objectClass) || this;
    }
    CachedQuery.prototype.makeKey = function (type, param) {
        if (param === void 0) { param = ''; }
        return (this.className +
            '>>>' +
            type +
            this.className +
            JSON.stringify(this) +
            param);
    };
    CachedQuery.prototype.find = function (options) {
        var key = this.makeKey('find');
        if (CachedQuery.cache[key])
            if (CachedQuery.cache[key].time > Date.now() - CachedQuery.CACHE_TIME)
                return Parse.Promise.as(CachedQuery.cache[key].data);
        if (CachedQuery.promises[key])
            if (CachedQuery.promises[key].time >
                Date.now() - CachedQuery.PROMISE_TIME)
                return CachedQuery.promises[key].promise;
        var promise = _super.prototype.find.call(this, options).then(function (r) {
            delete CachedQuery.promises[key];
            CachedQuery.cache[key] = { time: Date.now(), data: r };
            return r;
        });
        CachedQuery.promises[key] = { time: Date.now(), promise: promise };
        return promise;
    };
    CachedQuery.prototype.first = function (options) {
        var key = this.makeKey('first');
        if (CachedQuery.cache[key])
            if (CachedQuery.cache[key].time > Date.now() - CachedQuery.CACHE_TIME)
                return Parse.Promise.as(CachedQuery.cache[key].data);
        if (CachedQuery.promises[key])
            if (CachedQuery.promises[key].time >
                Date.now() - CachedQuery.PROMISE_TIME)
                return CachedQuery.promises[key].promise;
        var promise = _super.prototype.first.call(this, options).then(function (r) {
            delete CachedQuery.promises[key];
            CachedQuery.cache[key] = { time: Date.now(), data: r };
            return r;
        });
        CachedQuery.promises[key] = { time: Date.now(), promise: promise };
        return promise;
    };
    CachedQuery.prototype.get = function (objectId, options) {
        var key = this.makeKey('get', objectId);
        if (CachedQuery.cache[key])
            if (CachedQuery.cache[key].time > Date.now() - CachedQuery.CACHE_TIME)
                return Parse.Promise.as(CachedQuery.cache[key].data);
        if (CachedQuery.promises[key])
            if (CachedQuery.promises[key].time >
                Date.now() - CachedQuery.PROMISE_TIME)
                return CachedQuery.promises[key].promise;
        var promise = _super.prototype.get.call(this, objectId, options).then(function (r) {
            delete CachedQuery.promises[key];
            CachedQuery.cache[key] = { time: Date.now(), data: r };
            return r;
        });
        CachedQuery.promises[key] = { time: Date.now(), promise: promise };
        return promise;
    };
    CachedQuery.CACHE_TIME = 5 * 60 * 1000;
    CachedQuery.PROMISE_TIME = 0.1 * 60 * 1000;
    CachedQuery.cache = {};
    CachedQuery.promises = {};
    return CachedQuery;
}(Parse.Query));
export { CachedQuery };
