var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Parse from 'parse';
import { CachedQuery } from 'cmj/model/cachedQuery';
import { Animal } from 'cmj/model/animal';
import { Food } from 'cmj/model/food';
import { Photo } from 'cmj/model/photo';
import { environment } from 'environments/environment';
import { Helpers } from 'cmj/helpers';
// const Parse = require('parse');
var NAME = 'Eat';
export var AffTypes;
(function (AffTypes) {
    AffTypes["KAKADU"] = "kakadu";
})(AffTypes || (AffTypes = {}));
var Eat = /** @class */ (function (_super) {
    __extends(Eat, _super);
    // static get safetyLevels() {
    // 	return Object.keys(safetyLevels).map(k => ({k: k, v: safetyLevels[k]}));
    // }
    function Eat(params) {
        var _this = _super.call(this, NAME, params) || this;
        _this.colors = {
            safe: '#00d01b',
            ok: '#f1bf00',
            nok: '#e67700',
            bad: '#d0005e'
        };
        return _this;
    }
    Object.defineProperty(Eat.prototype, "title", {
        get: function () {
            if (environment.lang === 'pl')
                return "Czy " + this.animal.usedName + " mo\u017Ce " + this.food.eatOrDrinkText + " " + this.food.title + "?";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "animal", {
        get: function () {
            return this.get('animal');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "food", {
        get: function () {
            return this.get('food');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "safety", {
        get: function () {
            return this.get('safety');
        },
        set: function (safety) {
            this.set('safety', safety);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "safetyReadable", {
        get: function () {
            return Eat.SafetyLevels[this.get('safety')];
        },
        enumerable: true,
        configurable: true
    });
    Eat.prototype.getSafetyReadable = function () {
        return Eat.SafetyLevels[this.get('safety')];
    };
    Object.defineProperty(Eat.prototype, "safetyColor", {
        get: function () {
            return this.colors[this.safety];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "text", {
        get: function () {
            return this.get('text') || '';
        },
        set: function (text) {
            this.set('text', text);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "preview", {
        get: function () {
            var space = this.text.indexOf(' ', 54);
            if (space === -1)
                return this.text;
            else
                return this.text.substr(0, space) + '...';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "summary", {
        get: function () {
            return this.get('summary') || '';
        },
        set: function (summary) {
            this.set('summary', summary);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "headersOK", {
        get: function () {
            return this.get('headersOK');
        },
        set: function (ok) {
            this.set('headersOK', ok);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "dangers", {
        get: function () {
            return this.get('dangers');
        },
        set: function (dangers) {
            this.set('dangers', dangers) || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "source", {
        get: function () {
            return this.get('source');
        },
        set: function (source) {
            this.set('source', source);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "chars", {
        get: function () {
            return this.summary.length + this.text.length + this.dangers.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "sourceReadable", {
        get: function () {
            return (this.source || '')
                .split(',')
                .map(function (s) { return s.trim(); })
                .filter(function (s) { return s.length > 0; })
                .map(function (s) {
                return s
                    .replace('www.', '')
                    .replace('https://', '')
                    .replace('http://', '');
            })
                .map(function (s) {
                var endsWithSlash = s[s.length - 1] === '/';
                return endsWithSlash ? s.slice(0, s.length - 1) : s;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "animalPhoto", {
        get: function () {
            return this.get('animalPhoto');
        },
        set: function (photo) {
            this.set('animalPhoto', photo);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "foodPhoto", {
        get: function () {
            return this.get('foodPhoto');
        },
        set: function (photo) {
            this.set('foodPhoto', photo);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "connected", {
        get: function () {
            var c = this.get('connected');
            var connections = [];
            Object.entries(c).forEach(function (el) {
                el[1].forEach(function (subel) {
                    return connections.push({
                        t: el[0],
                        id: subel.id,
                        i: subel.i,
                        saved: true
                    });
                });
            });
            return connections;
        },
        set: function (conn) {
            var cObj = conn.reduce(function (o, el) {
                if (!o[el.t])
                    o[el.t] = [];
                o[el.t].push({ i: el.i, id: el.id });
                return o;
            }, {});
            this.set('connected', cObj);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "gotMiniPhoto", {
        get: function () {
            var file = this.get('mini');
            var fileFallback = this.get('s480');
            return file || fileFallback ? true : false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "miniPhotoUrl", {
        get: function () {
            var file = this.get('mini');
            var fileFallback = this.get('s480');
            if (!file && !fileFallback) {
                console.error('no eat MINI photo file');
                return Photo.errorPixel;
            }
            var url = file ? file.url() : fileFallback.url();
            return url.replace('http://', 'https://');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "microPhotoUrl", {
        get: function () {
            var base64 = this.get('micro');
            return base64;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "microPhotoStyle", {
        get: function () {
            return "url(" + this.microPhotoUrl + ")";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "canonicalUrl", {
        get: function () {
            var loc = window.location;
            var title = (this.title || '')
                .toLowerCase()
                .replace(/\s/g, '-')
                .replace('?', '');
            return loc.protocol + "//" + loc.host + "/" + title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Eat.prototype, "summaryLine", {
        get: function () {
            var foodPart = '';
            if (this.food.plural === this.food.title) {
                foodPart = this.food.plural;
                var safety = Food.genderSafetyWords['nijaki'][this.get('safety')];
                return foodPart + " s\u0105 <span>" + safety + "</span>";
            }
            else {
                foodPart = this.food.singular;
                var safety = Food.genderSafetyWords[this.food.get('gender') || 'meski'][this.get('safety')];
                return foodPart + " jest <span>" + safety + "</span>";
            }
        },
        enumerable: true,
        configurable: true
    });
    Eat.get = function (id) {
        var q = new CachedQuery(Eat);
        q.include('food');
        q.include('animal');
        q.exists('mini');
        q.notEqualTo('disabled', true);
        return Promise.resolve(q.get(id));
    };
    Eat.getAll = function (withErrors) {
        if (withErrors === void 0) { withErrors = false; }
        var q = new CachedQuery(Eat);
        q.limit(1000);
        q.include('food');
        q.include('animal');
        if (!withErrors)
            q.exists('mini');
        q.notEqualTo('disabled', true);
        return Promise.resolve(q.find());
    };
    Eat.get5 = function () {
        return __awaiter(this, void 0, void 0, function () {
            var q;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        q = new CachedQuery(Eat);
                        q.limit(5);
                        q.exists('mini');
                        q.notEqualTo('disabled', true);
                        return [4 /*yield*/, Promise.resolve(q.find())];
                    case 1: return [2 /*return*/, _a.sent()];
                }
            });
        });
    };
    Eat.getManyFromAF = function (a, f, withErrors, start, limit) {
        if (withErrors === void 0) { withErrors = false; }
        if (start === void 0) { start = 0; }
        if (limit === void 0) { limit = 100; }
        var idA = a ? (typeof a === 'string' ? a : a.id) : null;
        var idF = f ? (typeof f === 'string' ? f : f.id) : null;
        var q = new CachedQuery(Eat);
        q.include('food');
        q.include('food.photos');
        q.include('animal');
        q.include('animal.photos');
        if (limit)
            q.limit(limit);
        if (start)
            q.skip(start);
        if (!withErrors)
            q.exists('mini');
        if (a)
            q.equalTo('animal', {
                __type: 'Pointer',
                className: 'Animal',
                objectId: idA
            });
        if (f)
            q.equalTo('food', {
                __type: 'Pointer',
                className: 'Food',
                objectId: idF
            });
        q.notEqualTo('disabled', true);
        return Promise.resolve(q.find());
    };
    Eat.getFromAF = function (a, f, includePhotos) {
        if (includePhotos === void 0) { includePhotos = false; }
        return __awaiter(this, void 0, void 0, function () {
            var idA, idF, q, eat, qPa, _a, qPf, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        idA = typeof a === 'string' ? a : a.id;
                        idF = typeof f === 'string' ? f : f.id;
                        q = new CachedQuery(Eat);
                        q.include('food');
                        q.include('food.mainPhoto');
                        q.include('animal');
                        q.include('animal.mainPhoto');
                        q.notEqualTo('disabled', true);
                        q.equalTo('animal', {
                            __type: 'Pointer',
                            className: 'Animal',
                            objectId: idA
                        });
                        q.equalTo('food', { __type: 'Pointer', className: 'Food', objectId: idF });
                        return [4 /*yield*/, q.first()];
                    case 1:
                        eat = _c.sent();
                        if (!(eat && includePhotos)) return [3 /*break*/, 4];
                        qPa = new CachedQuery(Photo);
                        qPa.equalTo('animal', eat.animal);
                        qPa.exists('big');
                        _a = eat.animal;
                        return [4 /*yield*/, qPa.find()];
                    case 2:
                        _a.photos = _c.sent();
                        console.log('eat.animal.photos', eat.animal.photos);
                        qPf = new CachedQuery(Photo);
                        qPf.equalTo('food', eat.food);
                        qPa.exists('big');
                        _b = eat.food;
                        return [4 /*yield*/, qPf.find()];
                    case 3:
                        _b.photos = _c.sent();
                        console.log('eat.food.photos', eat.food.photos);
                        _c.label = 4;
                    case 4: return [2 /*return*/, eat];
                }
            });
        });
    };
    Eat.getFromNames = function (a, f) {
        var q = new CachedQuery(Eat);
        console.log('fff', f);
        var animalQ = new Parse.Query(Animal);
        animalQ.equalTo('aliases.' + environment.lang, a);
        q.matchesQuery('animal', animalQ);
        var foodQ = new Parse.Query(Food);
        foodQ.equalTo('aliases.' + environment.lang, f);
        q.matchesQuery('food', foodQ);
        q.include('food');
        q.include('foodPhoto');
        q.include('animal');
        q.include('animalPhoto');
        q.notEqualTo('disabled', true);
        return Promise.resolve(q.first());
    };
    Eat.create = function (animal, food) {
        var e = new Eat();
        e.set('animal', animal);
        e.set('food', food);
        return e;
    };
    Eat.prototype.addHeaders = function (headers) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, _i, str, base64, name_1, parseFile, base64mini, parseFileMini, base64micro;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = [];
                        for (_b in headers)
                            _a.push(_b);
                        _i = 0;
                        _c.label = 1;
                    case 1:
                        if (!(_i < _a.length)) return [3 /*break*/, 6];
                        str = _a[_i];
                        if (!headers.hasOwnProperty(str)) return [3 /*break*/, 5];
                        base64 = headers[str];
                        return [4 /*yield*/, Helpers.removeDiacritics(this.animal.name + '-' + this.food.name)];
                    case 2:
                        name_1 = _c.sent();
                        parseFile = new Parse.File(name_1 + '.jpg', { base64: base64 });
                        this.set(str, parseFile);
                        if (!(str === 's480')) return [3 /*break*/, 5];
                        return [4 /*yield*/, Helpers.createResized(base64, 480)];
                    case 3:
                        base64mini = _c.sent();
                        parseFileMini = new Parse.File(name_1 + '.jpg', {
                            base64: base64mini
                        });
                        this.set('mini', parseFileMini);
                        return [4 /*yield*/, Helpers.createResized(base64, 12)];
                    case 4:
                        base64micro = _c.sent();
                        // const parseFileMicro = new Parse.File(name + ".jpg", {
                        // 	base64: base64micro
                        // });
                        this.set('micro', base64micro);
                        _c.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 1];
                    case 6:
                        this.set('headersOK', true);
                        return [4 /*yield*/, this.save()];
                    case 7:
                        _c.sent();
                        return [2 /*return*/, this];
                }
            });
        });
    };
    Eat.prototype.getPhotoUrl = function (size) {
        var tmp = this.get(size);
        if (!tmp || !tmp.url()) {
            console.error('no eat photo file');
            return Photo.errorPixel;
        }
        return tmp.url().replace('http://', 'https://');
    };
    Eat.prototype.setAffiliate = function (type, link) {
        var tmp = this.get('affiliate') || {};
        tmp[type] = link;
        this.set('affiliate', tmp);
        this.save();
    };
    Object.defineProperty(Eat.prototype, "affiliate", {
        get: function () {
            return this.get('affiliate') || {};
        },
        enumerable: true,
        configurable: true
    });
    Eat.prototype.getAffShopLink = function (type) {
        if ((type = AffTypes.KAKADU)) {
            var ids = {
                pies: '1',
                kot: '2'
            };
            var id = ids[this.animal.singular];
            var name_2 = encodeURIComponent(this.food.singular);
            return "https://e-sklep.kakadu.pl/szukaj.htm?opt=" + id + "&find=" + name_2 + "&image.x=6&image.y=7";
        }
    };
    Eat.prototype.getRandomAffLink = function () {
        var r = Math.floor((Math.random() * Object.keys(AffTypes).length) / 2);
        return this.getAffLink(AffTypes[Object.keys(AffTypes)[r]]);
    };
    Eat.prototype.getAffLink = function (type) {
        var tmp = this.get('affiliate');
        if (!tmp)
            return undefined;
        var raw = tmp[type];
        if (!raw)
            return undefined;
        if ((type = AffTypes.KAKADU)) {
            var base = 'http://nsm.tr.netsalesmedia.pl/ts/i5034330/tsc?amc=con.cubegroup.450211.465353.149470&smc=aaa&rmd=3&trg=';
            return base + encodeURIComponent(raw);
        }
    };
    Eat.prototype.getAffRawLink = function (type) {
        var tmp = this.get('affiliate');
        if (!tmp)
            return undefined;
        return tmp[type];
    };
    Object.defineProperty(Eat.prototype, "gotAffiliateLink", {
        get: function () {
            var tmp = this.get('affiliate');
            return tmp && Object.keys(tmp).length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Eat.prototype.getDefaultAffLinkUsed = function (type) {
        return (this.affiliate || {})[type] === this.getAffShopLink(type);
    };
    return Eat;
}(Parse.Object));
export { Eat };
(function (Eat) {
    var SafetyLevels;
    (function (SafetyLevels) {
        SafetyLevels["safe"] = "bezpieczne";
        SafetyLevels["ok"] = "dopuszczalne";
        SafetyLevels["nok"] = "niewskazane";
        SafetyLevels["bad"] = "szkodliwe";
    })(SafetyLevels = Eat.SafetyLevels || (Eat.SafetyLevels = {}));
})(Eat || (Eat = {}));
Parse.Object.registerSubclass(NAME, Eat);
