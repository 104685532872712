import { Directive, HostListener, Input } from "@angular/core";

function isWheelEv(ev: any): ev is WheelEvent {
	return (<WheelEvent>ev).deltaY !== undefined;
}

@Directive({
	selector: "[appNoscroll]"
})
export class NoscrollDirective {
	touchStart = 0;

	constructor() {}

	@Input() ov = false;

	keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

	preventDefault(e: any) {
		// console.log('aaa00');
		if (!this.ov) return true;
		let el = e.target as HTMLElement;
		if (!el || !el.parentElement) throw new Error("no el");
		if (el.tagName.toLowerCase() !== "ul") el = el.parentElement;

		if (el.tagName.toLowerCase() !== "ul") {
			e.returnValue = false;
			return false;
		}
		const elStyle = window.getComputedStyle(el, undefined);
		const h = elStyle.getPropertyValue("height");
		// console.log('aaa', el);

		const deltaY = isWheelEv(e)
			? e.deltaY
			: this.touchStart - e.touches[0].clientY;
		if (deltaY < 0) {
			// console.log('bbb', el.scrollTop > 0);
			e.returnValue = el.scrollTop > 0;
			return e.returnValue;
		} else {
			// console.log('ccc', el.scrollTop, parseInt(h, 10) , el.scrollHeight);
			e.returnValue =
				Math.ceil(el.scrollTop + parseInt(h, 10)) < el.scrollHeight;
			return e.returnValue;
		}
	}

	@HostListener("wheel", ["$event"]) onWheel($event: WheelEvent) {
		this.preventDefault($event);
	}

	@HostListener("touchmove", ["$event"]) onTouchMove($event: any) {
		this.preventDefault($event);
	}

	@HostListener("touchstart", ["$event"]) onTouchStart($event: any) {
		this.touchStart = $event.touches[0].clientY;
	}
}
