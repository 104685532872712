// import { of as observableOf, Observable } from 'rxjs';
import * as Parse from 'parse';
import { environment } from 'environments/environment';

import { Helpers } from 'cmj/helpers';
import { Photo } from 'cmj/model/photo';
import { CachedQuery } from 'cmj/model/cachedQuery';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';

export class Category extends Parse.Object {
	private static getSuggestionsCache: { [search: string]: Category[] } = {};

	public photos: Photo[] = [];

	constructor(private parseClassName: string, params?: any) {
		super(parseClassName, params);
	}

	public static async get<T extends Category>(
		this: new () => T,
		id: string,
		includePhotos = false
	) {
		console.log('ppp', id, includePhotos);
		const q: Parse.Query<T> = new CachedQuery(this) as any;
		q.include('mainPhoto');
		const obj = await q.get(id);
		if (includePhotos) {
			const qP = new CachedQuery(Photo);
			qP.equalTo((this as any).className.toLowerCase(), obj);
			qP.exists('big');
			console.log('qqq', qP);
			obj.photos = await qP.find();
		}
		return obj;
	}

	public static async getByName<T extends Category>(
		this: new () => T,
		name: string,
		includePhotos = false,
		start = 0,
		limit = 100
	): Promise<T> {
		const q: Parse.Query<T> = new CachedQuery(this) as any;
		q.include('mainPhoto');
		q.skip(start);
		q.limit(limit);
		q.equalTo('aliases.' + environment.lang, name);
		const obj = await q.first();
		if (obj && includePhotos) {
			const qP = new CachedQuery(Photo);
			qP.equalTo((this as any).className.toLowerCase(), obj);
			qP.exists('big');
			obj.photos = await qP.find();
		}
		return obj as any;
	}

	public static async getAll<T extends Category>(
		this: new () => T,
		withMainPhoto = false,
		onlyPublished = true
	) {
		const q: Parse.Query<T> = new CachedQuery(this) as any;
		if (withMainPhoto) q.include('mainPhoto');
		if (onlyPublished) q.equalTo('published', true);
		const els = await q.find();
		return els.filter(
			el => !withMainPhoto || (el.mainPhoto && el.mainPhoto.get('mini'))
		);
	}

	public static get5<T extends Category>(this: new () => T, onlyPublished = true) {
		const q: Parse.Query<T> = new CachedQuery(this) as any;
		q.include('mainPhoto');
		if (onlyPublished) q.equalTo('published', true);
		q.limit(5);
		return Promise.resolve(
			q
				.find()
				.then(els => els.filter(el => el.mainPhoto && el.mainPhoto.get('mini')))
		);
	}

	static getSuggestionsP(text: string, useTokens = false): Promise<Category[]> {
		const key = 'c' + (this as any).className + 't' + text;

		if (this.getSuggestionsCache[key])
			return Promise.resolve(this.getSuggestionsCache[key]);
		const q = new CachedQuery(this);
		q.equalTo('published', true);
		const col = useTokens ? 'tokens' : 'aliases';
		if (text && text.length) {
			q.startsWith(`${col}.${environment.lang}`, text.toLowerCase());
		}

		return Promise.resolve(
			q.find().then(r => {
				this.getSuggestionsCache[key] = r;
				return r;
			})
		);
	}

	static getSuggestions<T extends Category>(
		this: new () => T,
		text: string,
		useTokens = false
	): Observable<T[]> {
		const key = 'c' + (this as any).className + 't' + text;
		if (Category.getSuggestionsCache[key]) {
			return of(Category.getSuggestionsCache[key]) as any;
		}
		const tmp = this as any;
		const q: Parse.Query<T> = new CachedQuery(tmp) as any;
		q.equalTo('published', true);
		const col = useTokens ? 'tokens' : 'aliases';
		if (text && text.length)
			q.startsWith(col + '.' + environment.lang, text.toLowerCase());

		return new Observable(observer => {
			q.find().then((r: T[]) => {
				Category.getSuggestionsCache[key] = r;
				observer.next(r);
			});
		});
	}

	get name(): string {
		return this.singular;
	}

	get usedName(): string {
		return this.name;
	}

	get singular(): string {
		return this.get('name') ? this.get('name')[environment.lang] : '';
	}

	setSingular(name: string) {
		this.get('name')[environment.lang] = name;
		return this.save();
	}

	get plural(): string {
		return this.get('plural') ? this.get('plural')[environment.lang] : '';
	}

	setPlural(name: string) {
		if (!this.get('plural')) this.set('plural', {})
		this.get('plural')[environment.lang] = name;
		return this.save();
	}

	setTitle(name: string) {
		console.log('ttt', name);
		if (!this.get('title')) this.set('title', {});
		this.get('title')[environment.lang] = name;
		return this.save();
	}

	// tslint:disable-next-line:member-ordering
	private _aliases: { [lang: string]: string[] } | undefined;
	get aliases(): string[] {
		if (!this._aliases) {
			const tmp = this.get('aliases');
			if (tmp) this._aliases = tmp;
			else {
				this._aliases = {};
				this._aliases[environment.lang] = <string[]>[];
			}
		}
		return (this._aliases as any)[environment.lang];
	}

	// tslint:disable-next-line: member-ordering
	private _tokens: { [lang: string]: string[] } | undefined;
	get tokens(): string[] {
		if (!this._tokens) {
			const tmp = this.get('tokens');
			if (tmp) this._tokens = tmp;
			else {
				this._tokens = {};
				this._tokens[environment.lang] = <string[]>[];
			}
		}
		return (this._tokens as any)[environment.lang];
	}

	get mainPhoto(): Photo {
		return this.get('mainPhoto');
	}

	addAlias(n: string) {
		this.aliases.push(n);
		return this.save();
	}

	removeAlias(alias: string) {
		this.aliases.splice(this.aliases.indexOf(alias));
		return this.save();
	}

	async addPhoto(base64: string) {
		const name = Helpers.removeDiacritics(this.name) + '.jpg';
		const photo = await Photo.create(name, base64, this);
		this.photos.push(photo);
		return this;
	}

	setMainPhoto(photo: Photo) {
		this.set('mainPhoto', photo);
		this.save();
	}

	async removePhoto(photo: Photo) {
		await photo.destroy().catch(err => {
			console.log('eee', err);
		});
		this.photos.splice(this.photos.indexOf(photo), 1);
	}
}
