import { Component, OnInit } from '@angular/core';
import { MetaService } from 'cmj/meta.service';

@Component({
	selector: 'page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.styl']
})
export class PageNotFoundComponent implements OnInit {

	constructor(private meta: MetaService) { }

	ngOnInit() {
		this.meta.show404();
	}

}
