<div
	#scrollCardContainer
	(scroll)="measureOverflow()"
	[scrollLeft]="scrollLeftValue"
	[@listAnimation]="items ? items?.length : 0"
	class="card-scroll-container"
>
	<cmj-card [item]="item" *ngFor="let item of items"></cmj-card>
</div>
<div
	(click)="scrollRight()"
	[@fadeInOut]
	class="scroll-button"
	*ngIf="scrollRightEnabled"
>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
		class="feather feather-chevron-right"
	>
		<polyline points="9 18 15 12 9 6"></polyline>
	</svg>
</div>
<div
	(click)="scrollLeft()"
	[@fadeInOut]
	class="scroll-button scroll-left"
	*ngIf="scrollLeftEnabled"
>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		stroke="currentColor"
		stroke-width="2"
		stroke-linecap="round"
		stroke-linejoin="round"
		class="feather feather-chevron-left"
	>
		<polyline points="15 18 9 12 15 6"></polyline>
	</svg>
</div>
