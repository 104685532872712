import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-main',
	templateUrl: './main.component.html',
	styleUrls: ['./main.component.styl']
})
export class MainComponent implements OnInit {

	// animal:Animal|null
	animal = null;
	food = null;

	constructor() { }

	ngOnInit() {
	}

	titleChanged(t: any) {
		console.log('title changed', t);
	}
}
