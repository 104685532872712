export function debounce(delay: number = 300): MethodDecorator {
	let timeout: NodeJS.Timer | null = null;

	return function(
		target: any,
		propertyKey: string | symbol,
		descriptor: PropertyDescriptor
	) {
		const original = descriptor.value;

		descriptor.value = function(...args: any[]) {
			if (timeout) clearTimeout(timeout);
			timeout = setTimeout(() => original.apply(this, args), delay);
		};
		return descriptor;
	};
}
