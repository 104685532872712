/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./adsensearticle.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./adsensearticle.component";
var styles_AdsensearticleComponent = [i0.styles];
var RenderType_AdsensearticleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AdsensearticleComponent, data: {} });
export { RenderType_AdsensearticleComponent as RenderType_AdsensearticleComponent };
function View_AdsensearticleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "ins", [["class", "adsbygoogle"], ["data-ad-client", "ca-pub-6319611442758024"], ["data-ad-format", "fluid"], ["data-ad-layout", "in-article"], ["style", "display:block; text-align:center;"]], [[1, "data-ad-slot", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 0, 0, currVal_0); }); }
function View_AdsensearticleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "ins", [["class", "adsbygoogle"], ["data-ad-client", "ca-pub-6319611442758024"], ["data-ad-format", "auto"], ["data-full-width-responsive", "true"], ["style", "display:block"]], [[1, "data-ad-slot", 0]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 0, 0, currVal_0); }); }
export function View_AdsensearticleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdsensearticleComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AdsensearticleComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.type === "article"); _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.type === "display"); _ck(_v, 3, 0, currVal_1); }, null); }
export function View_AdsensearticleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "adsensearticle", [], null, null, null, View_AdsensearticleComponent_0, RenderType_AdsensearticleComponent)), i1.ɵdid(1, 4243456, null, 0, i3.AdsensearticleComponent, [], null, null)], null, null); }
var AdsensearticleComponentNgFactory = i1.ɵccf("adsensearticle", i3.AdsensearticleComponent, View_AdsensearticleComponent_Host_0, { data: "data", type: "type" }, {}, []);
export { AdsensearticleComponentNgFactory as AdsensearticleComponentNgFactory };
