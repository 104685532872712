import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import * as Parse from 'parse';
import { Router, NavigationEnd } from '@angular/router';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.styl'],
	encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
	isAdmin = false;
	constructor(private router: Router) {
		this.router.events.subscribe(event => {
			console.log('aaa');
			if (event instanceof NavigationEnd) {
				console.log('bbb', event.urlAfterRedirects);
				setTimeout(() => {
					if (!(<any>window).ga) return;
					(<any>window).ga('set', 'page', event.urlAfterRedirects);
					(<any>window).ga('send', 'pageview');
				});
			}
		});
	}
	ngOnInit(): void {
		if (Parse.User.current()) this.isAdmin = true;
	}
	async switch() {
		const isPanel = window.location.pathname.indexOf('/panel') === 0;
		if (isPanel) await this.router.navigate(['/'], {});
		else await this.router.navigate(['/panel']);
		location.reload();
	}
}
