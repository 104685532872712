import { Component, OnInit, Input } from '@angular/core';
import { Eat } from 'cmj/model/eat';
import { Animal } from 'cmj/model/animal';
import { Food } from 'cmj/model/food';
import { DomSanitizer } from '@angular/platform-browser';
import { Photo } from 'cmj/model/photo';

@Component({
	selector: 'cmj-card',
	templateUrl: './card.component.html',
	styleUrls: ['./card.component.styl']
})
export class CardComponent implements OnInit {
	@Input() item: Eat | Animal | Food | undefined;
	@Input() selected: Animal | Food | undefined;
	isEat = false;
	constructor(private sanitizer: DomSanitizer) {}

	canShare = (navigator as any).share;

	ngOnInit() {
		if (this.item instanceof Eat) this.isEat = true;
	}
	get title() {
		// console.log('aaa', this.item, this.item.title);
		if (this.item instanceof Eat) return this.item.title;
		else if (this.item) return this.item.title;
	}

	get preview() {
		// console.log('aaa', this.item, this.item.title);
		if (this.item instanceof Eat) return this.item.text.substr(0, 100);
		else return '';
	}

	get miniPhotoUrl() {
		if (this.item instanceof Eat) return this.item.miniPhotoUrl;
		else if (this.item) return this.item.mainPhoto.urlMini;
		else return '';
	}

	get micro() {
		if (this.item instanceof Eat)
			return this.item.microPhotoUrl || Photo.errorPixel;
		else if (this.item) return this.item.mainPhoto.micro;
		else return '';
	}

	get bg() {
		return this.sanitizer.bypassSecurityTrustStyle(
			'url(' + this.miniPhotoUrl + '), url(' + this.micro + ')'
		);
	}

	get url() {
		if (this.item instanceof Eat)
			return [
				`/czy-${this.item.animal.name}-może-${this.item.food.eatOrDrinkText}-${
					this.item.food.titleUrl
				}`
			];
		else if (this.item instanceof Animal) {
			if (this.selected instanceof Food) {
				const eatText = this.selected.eatOrDrinkText;
				return [
					`/czy-${this.item.name}-może-${eatText}-${this.selected.titleUrl}`
				];
			} else return [`/${Animal.NAME}/${this.item.name}`];
		} else if (this.item instanceof Food) {
			if (this.selected instanceof Animal)
				return [
					`/czy-${this.selected.name}-może-${this.item.eatOrDrinkText}-${
						this.item.titleUrl
					}`
				];
			else return [`/${Food.NAME}/${this.item.titleUrl}`];
		}
	}

	share() {
		(navigator as any).share({
			title: this.title,
			// text: "Czytaj więcej!",
			url: this.url
		});
	}
}
