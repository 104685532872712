/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./footer.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./footer.component";
var styles_FooterComponent = [i0.styles];
var RenderType_FooterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FooterComponent, data: {} });
export { RenderType_FooterComponent as RenderType_FooterComponent };
export function View_FooterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["class", "text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" CzyMo\u017CeJe\u015B\u0107.pl to strona stworzona z mi\u0142o\u015Bci do zwierz\u0105t i ch\u0119ci dzielenia si\u0119 tym co najlepsze z naszymi pupilami. Wielu opiekun\u00F3w nie raz zastanawia\u0142o si\u0119, czy plasterek sera jest odpowiedni dla kota, albo czy podzieli\u0107 si\u0119 z psem truskawkami. Czy zwierzak mo\u017Ce zje\u015B\u0107 par\u0119 czekoladek, czy mo\u017Ce zainteresuj\u0105 go banany? Dla tego w\u0142asnie powsta\u0142a strona CzyMo\u017CeJe\u015B\u0107.pl - szybki i przejrzysty zbi\u00F3r porad dostosowanych do konkretnych przypadk\u00F3w, oparty na wiedzy ekspert\u00F3w.\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["v", ", Copyright\u00A9 2019 czyMozeJesc.pl"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 3, 0, currVal_0); }); }
export function View_FooterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "footer", [], null, null, null, View_FooterComponent_0, RenderType_FooterComponent)), i1.ɵdid(1, 114688, null, 0, i2.FooterComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FooterComponentNgFactory = i1.ɵccf("footer", i2.FooterComponent, View_FooterComponent_Host_0, {}, {}, []);
export { FooterComponentNgFactory as FooterComponentNgFactory };
