import * as Parse from "parse";
import { Helpers } from "cmj/helpers";

// import { Helpers } from 'cmj/helpers';
// const Parse = require('parse');
// import Vibrant = require('node-vibrant');

const Vibrant = require("node-vibrant");
// declare const Vibrant: any;

const NAME = "Photo";

export class Photo extends Parse.Object {
	static readonly MICRO_SIZE = 12;
	static readonly MINI_SIZE = 480;
	static readonly SIZE = 640;
	static readonly BIG_SIZE = 1000;
	static errorPixel =
		"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==";

	showing = false;

	get color(): string {
		return this.get("color");
	}

	get micro(): string {
		return this.get("micro");
	}

	get file(): Parse.File {
		return this.get("file");
	}

	get url(): string {
		const file = this.get("file");
		if (!file) throw new Error("no file");
		return file.url().replace("http://", "https://");
	}

	get urlBig(): string {
		const file = this.get("big");
		if (!file) {
			console.error("no photo file - bigURL");
			return Photo.errorPixel;
		}
		return file.url().replace("http://", "https://");
	}

	get urlMini(): string {
		const file = this.get("mini");
		if (!file) throw new Error("no file " + this.id);
		return file.url().replace("http://", "https://");
	}

	get flipped(): boolean {
		return this.get("flipped");
	}

	set flipped(flipped: boolean) {
		this.set("flipped", flipped);
	}

	static async create(name: string, base64: string, object: Parse.Object) {
		const photo = new Photo();

		const resizedImageBig = await Helpers.createResized(base64, Photo.BIG_SIZE);
		const parseFileBig = new Parse.File(name, { base64: resizedImageBig });
		photo.set("big", parseFileBig);

		const resizedImage = await Helpers.createResized(base64, Photo.SIZE);
		const parseFile = new Parse.File(name, { base64: resizedImage });
		photo.set("file", parseFile);
		const palette = await Vibrant.from(base64).getPalette();

		// console.log(palette.Vibrant.getHex());
		photo.set("color", palette.Vibrant && palette.Vibrant.getHex());
		const resizedImageMini = await Helpers.createResized(
			base64,
			Photo.MINI_SIZE
		);

		const parseFileMini = new Parse.File(name, { base64: resizedImageMini });
		photo.set("mini", parseFileMini);

		const micro = await Helpers.createResized(base64, Photo.MICRO_SIZE);
		photo.set("micro", micro);

		if (object.className === "Animal") photo.set("animal", object);
		else if (object.className === "Food") photo.set("food", object);
		else throw new Error("DDDDD");
		await photo.save();

		return photo;
	}

	constructor(options?: any) {
		super(NAME, options);
	}

	flip() {
		this.set("flipped", !this.flipped);
	}

	async recreateThumbs(name: string) {
		name = Helpers.removeDiacritics(name) + ".jpg";
		const resizedImageMini = await Helpers.createResized(
			this.url,
			Photo.MINI_SIZE
		);

		const parseFileMini = new Parse.File(name, { base64: resizedImageMini });
		this.set("mini", parseFileMini);

		const micro = await Helpers.createResized(this.url, Photo.MICRO_SIZE);
		this.set("micro", micro);
		await this.save();
		return true;
	}
}

Parse.Object.registerSubclass(NAME, Photo);
