/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category-selected.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modules/card/card.component.ngfactory";
import * as i3 from "../modules/card/card.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../overlay404/overlay404.component.ngfactory";
import * as i6 from "../overlay404/overlay404.component";
import * as i7 from "@angular/common";
import * as i8 from "../check-load-more.directive";
import * as i9 from "./category-selected.component";
import * as i10 from "@angular/router";
import * as i11 from "../meta.service";
var styles_CategorySelectedComponent = [i0.styles];
var RenderType_CategorySelectedComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategorySelectedComponent, data: { "animation": [{ type: 7, name: "listAnimation", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":leave", animation: [{ type: 12, timings: 100, animation: [{ type: 6, styles: { opacity: 1, transform: "translateY(0px)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(20px)" }, offset: null }, timings: "0.3s" }] }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 12, timings: 100, animation: [{ type: 6, styles: { opacity: 0, transform: "translateY(-20px)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0px)" }, offset: null }, timings: "0.3s" }] }], options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_CategorySelectedComponent as RenderType_CategorySelectedComponent };
function View_CategorySelectedComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cmj-card", [], null, [[null, "callback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callback" === en)) {
        var pd_0 = (_co.itemClicked(_v.context.$implicit.usedName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardComponent, [i4.DomSanitizer], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
function View_CategorySelectedComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "overlay404", [["offsetTop", "10"]], [[4, "top", "vh"]], null, null, i5.View_Overlay404Component_0, i5.RenderType_Overlay404Component)), i1.ɵdid(1, 114688, null, 0, i6.Overlay404Component, [i7.Location], { offsetTop: [0, "offsetTop"] }, null)], function (_ck, _v) { var currVal_1 = "10"; _ck(_v, 1, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).offsetTop; _ck(_v, 0, 0, currVal_0); }); }
export function View_CategorySelectedComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "list"]], [[24, "@listAnimation", 0]], [[null, "checkLoadMore"], ["window", "scroll"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWindowScroll() !== false);
        ad = (pd_0 && ad);
    } if (("checkLoadMore" === en)) {
        var pd_1 = (_co.fireLoadMore() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i8.CheckLoadMoreDirective, [i1.ElementRef], null, { checkLoadMore: "checkLoadMore" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategorySelectedComponent_1)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategorySelectedComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.notFound; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.items ? ((_co.items == null) ? null : _co.items.length) : 0); _ck(_v, 0, 0, currVal_0); }); }
export function View_CategorySelectedComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "category-selected", [], null, null, null, View_CategorySelectedComponent_0, RenderType_CategorySelectedComponent)), i1.ɵdid(1, 114688, null, 0, i9.CategorySelectedComponent, [i10.ActivatedRoute, i10.Router, i11.MetaService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategorySelectedComponentNgFactory = i1.ɵccf("category-selected", i9.CategorySelectedComponent, View_CategorySelectedComponent_Host_0, {}, {}, []);
export { CategorySelectedComponentNgFactory as CategorySelectedComponentNgFactory };
