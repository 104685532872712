import * as Parse from 'parse';
import { environment } from 'environments/environment';
import { Category } from './category';

export interface FoodIcon {
	png: string;
	svg?: string;
	id: string;
}

const NAME = 'Food';

export class Food extends Category {
	static genderSafetyWords: { [gender: string]: { [safe: string]: string } } = {
		meski: {
			safe: 'bezpieczny',
			ok: 'dopuszczalny',
			nok: 'niewskazany',
			bad: 'szkodliwy'
		},
		zenski: {
			safe: 'bezpieczna',
			ok: 'dopuszczalna',
			nok: 'niewskazana',
			bad: 'szkodliwa'
		},
		nijaki: {
			safe: 'bezpieczne',
			ok: 'dopuszczalne',
			nok: 'niewskazane',
			bad: 'szkodliwe'
		}
	};
	static eatWords: { [lang: string]: string[] } = {
		pl: ['jeść', 'pić']
	};
	private static names: { [lang: string]: string } = {
		pl: 'pokarm'
	};
	public static get NAME() {
		return this.names[environment.lang];
	}
	constructor(params?: any) {
		super(NAME, params);
	}

	_icon: any = { png: undefined };

	get icon(): FoodIcon {
		Object.assign(this._icon, this.get('icon') || {}, {
			png: this.get('iconPNG') && this.get('iconPNG').url()
		});
		return this._icon;
	}
	get iconPNG(): string {
		if (this.tmpIconPNG) return this.tmpIconPNG;
		return (
			this.get('iconPNG') &&
			this.get('iconPNG')
				.url()
				.replace('http://', 'https://')
		);
	}

	get drink(): boolean {
		return this.get('drink') || false;
	}

	set drink(isDrink: boolean) {
		this.set('drink', isDrink);
	}

	get gender(): string {
		return this.get('gender');
	}

	get eatOrDrinkText(): string {
		return Food.eatWords[environment.lang][this.drink ? 1 : 0];
	}

	gotRawIcon(): FoodIcon | undefined {
		return this.get('icon');
	}

	private tmpIconPNG: string | null = null;

	gotPNG() {
		return (this.tmpIconPNG || this.get('iconPNG')) != null;
	}
	iconDownloadPNG() {
		return Parse.Cloud.run('iconDownloadPNG', { id: this.id });
	}

	get summaryLinePrev() {
		let foodPart = '';
		if (this.plural === this.title) {
			console.log('aaa1');

			foodPart = this.plural;
			const safety = Food.genderSafetyWords['nijaki']['safe'];
			return `${foodPart} są <span>${safety}</span>`;
		} else {
			console.log('bbb1');

			foodPart = this.singular;
			const safety =
				Food.genderSafetyWords[this.get('gender') || 'meski']['safe'];
			return `${foodPart} jest <span>${safety}</span>`;
		}
	}

	async addIcon(icon: FoodIcon) {
		this.set('icon', icon);
		await this.save();
		this.tmpIconPNG = await Promise.resolve(this.iconDownloadPNG());
		return this;
	}

	static create(name: string) {
		const tmp = new Food();
		const n: { [lang: string]: string } = {};
		n[environment.lang] = name;
		const p = {};
		const a: { [lang: string]: string[] } = {};
		a[environment.lang] = [name];
		tmp.set('name', n);
		tmp.set('plural', p);
		tmp.set('aliases', a);
		return tmp.save();
	}

	get title(): string {
		if (this.get('title')) return this.get('title')[environment.lang];
		if (this.get('plural') && this.get('plural')[environment.lang])
			return this.get('plural')[environment.lang];
		return this.get('name') ? this.get('name')[environment.lang] : '';
	}

	get titleUrl(): string {
		return this.title
			.toLowerCase()
			.split(' ')
			.join('-');
	}
}

Parse.Object.registerSubclass(NAME, Food);
