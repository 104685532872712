var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// import { of as observableOf, Observable } from 'rxjs';
import * as Parse from 'parse';
import { environment } from 'environments/environment';
import { Helpers } from 'cmj/helpers';
import { Photo } from 'cmj/model/photo';
import { CachedQuery } from 'cmj/model/cachedQuery';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/internal/Observable';
var Category = /** @class */ (function (_super) {
    __extends(Category, _super);
    function Category(parseClassName, params) {
        var _this = _super.call(this, parseClassName, params) || this;
        _this.parseClassName = parseClassName;
        _this.photos = [];
        return _this;
    }
    Category.get = function (id, includePhotos) {
        if (includePhotos === void 0) { includePhotos = false; }
        return __awaiter(this, void 0, void 0, function () {
            var q, obj, qP, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        console.log('ppp', id, includePhotos);
                        q = new CachedQuery(this);
                        q.include('mainPhoto');
                        return [4 /*yield*/, q.get(id)];
                    case 1:
                        obj = _b.sent();
                        if (!includePhotos) return [3 /*break*/, 3];
                        qP = new CachedQuery(Photo);
                        qP.equalTo(this.className.toLowerCase(), obj);
                        qP.exists('big');
                        console.log('qqq', qP);
                        _a = obj;
                        return [4 /*yield*/, qP.find()];
                    case 2:
                        _a.photos = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/, obj];
                }
            });
        });
    };
    Category.getByName = function (name, includePhotos, start, limit) {
        if (includePhotos === void 0) { includePhotos = false; }
        if (start === void 0) { start = 0; }
        if (limit === void 0) { limit = 100; }
        return __awaiter(this, void 0, void 0, function () {
            var q, obj, qP, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        q = new CachedQuery(this);
                        q.include('mainPhoto');
                        q.skip(start);
                        q.limit(limit);
                        q.equalTo('aliases.' + environment.lang, name);
                        return [4 /*yield*/, q.first()];
                    case 1:
                        obj = _b.sent();
                        if (!(obj && includePhotos)) return [3 /*break*/, 3];
                        qP = new CachedQuery(Photo);
                        qP.equalTo(this.className.toLowerCase(), obj);
                        qP.exists('big');
                        _a = obj;
                        return [4 /*yield*/, qP.find()];
                    case 2:
                        _a.photos = _b.sent();
                        _b.label = 3;
                    case 3: return [2 /*return*/, obj];
                }
            });
        });
    };
    Category.getAll = function (withMainPhoto, onlyPublished) {
        if (withMainPhoto === void 0) { withMainPhoto = false; }
        if (onlyPublished === void 0) { onlyPublished = true; }
        return __awaiter(this, void 0, void 0, function () {
            var q, els;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        q = new CachedQuery(this);
                        if (withMainPhoto)
                            q.include('mainPhoto');
                        if (onlyPublished)
                            q.equalTo('published', true);
                        return [4 /*yield*/, q.find()];
                    case 1:
                        els = _a.sent();
                        return [2 /*return*/, els.filter(function (el) { return !withMainPhoto || (el.mainPhoto && el.mainPhoto.get('mini')); })];
                }
            });
        });
    };
    Category.get5 = function (onlyPublished) {
        if (onlyPublished === void 0) { onlyPublished = true; }
        var q = new CachedQuery(this);
        q.include('mainPhoto');
        if (onlyPublished)
            q.equalTo('published', true);
        q.limit(5);
        return Promise.resolve(q
            .find()
            .then(function (els) { return els.filter(function (el) { return el.mainPhoto && el.mainPhoto.get('mini'); }); }));
    };
    Category.getSuggestionsP = function (text, useTokens) {
        var _this = this;
        if (useTokens === void 0) { useTokens = false; }
        var key = 'c' + this.className + 't' + text;
        if (this.getSuggestionsCache[key])
            return Promise.resolve(this.getSuggestionsCache[key]);
        var q = new CachedQuery(this);
        q.equalTo('published', true);
        var col = useTokens ? 'tokens' : 'aliases';
        if (text && text.length) {
            q.startsWith(col + "." + environment.lang, text.toLowerCase());
        }
        return Promise.resolve(q.find().then(function (r) {
            _this.getSuggestionsCache[key] = r;
            return r;
        }));
    };
    Category.getSuggestions = function (text, useTokens) {
        if (useTokens === void 0) { useTokens = false; }
        var key = 'c' + this.className + 't' + text;
        if (Category.getSuggestionsCache[key]) {
            return of(Category.getSuggestionsCache[key]);
        }
        var tmp = this;
        var q = new CachedQuery(tmp);
        q.equalTo('published', true);
        var col = useTokens ? 'tokens' : 'aliases';
        if (text && text.length)
            q.startsWith(col + '.' + environment.lang, text.toLowerCase());
        return new Observable(function (observer) {
            q.find().then(function (r) {
                Category.getSuggestionsCache[key] = r;
                observer.next(r);
            });
        });
    };
    Object.defineProperty(Category.prototype, "name", {
        get: function () {
            return this.singular;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Category.prototype, "usedName", {
        get: function () {
            return this.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Category.prototype, "singular", {
        get: function () {
            return this.get('name') ? this.get('name')[environment.lang] : '';
        },
        enumerable: true,
        configurable: true
    });
    Category.prototype.setSingular = function (name) {
        this.get('name')[environment.lang] = name;
        return this.save();
    };
    Object.defineProperty(Category.prototype, "plural", {
        get: function () {
            return this.get('plural') ? this.get('plural')[environment.lang] : '';
        },
        enumerable: true,
        configurable: true
    });
    Category.prototype.setPlural = function (name) {
        if (!this.get('plural'))
            this.set('plural', {});
        this.get('plural')[environment.lang] = name;
        return this.save();
    };
    Category.prototype.setTitle = function (name) {
        console.log('ttt', name);
        if (!this.get('title'))
            this.set('title', {});
        this.get('title')[environment.lang] = name;
        return this.save();
    };
    Object.defineProperty(Category.prototype, "aliases", {
        get: function () {
            if (!this._aliases) {
                var tmp = this.get('aliases');
                if (tmp)
                    this._aliases = tmp;
                else {
                    this._aliases = {};
                    this._aliases[environment.lang] = [];
                }
            }
            return this._aliases[environment.lang];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Category.prototype, "tokens", {
        get: function () {
            if (!this._tokens) {
                var tmp = this.get('tokens');
                if (tmp)
                    this._tokens = tmp;
                else {
                    this._tokens = {};
                    this._tokens[environment.lang] = [];
                }
            }
            return this._tokens[environment.lang];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Category.prototype, "mainPhoto", {
        get: function () {
            return this.get('mainPhoto');
        },
        enumerable: true,
        configurable: true
    });
    Category.prototype.addAlias = function (n) {
        this.aliases.push(n);
        return this.save();
    };
    Category.prototype.removeAlias = function (alias) {
        this.aliases.splice(this.aliases.indexOf(alias));
        return this.save();
    };
    Category.prototype.addPhoto = function (base64) {
        return __awaiter(this, void 0, void 0, function () {
            var name, photo;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        name = Helpers.removeDiacritics(this.name) + '.jpg';
                        return [4 /*yield*/, Photo.create(name, base64, this)];
                    case 1:
                        photo = _a.sent();
                        this.photos.push(photo);
                        return [2 /*return*/, this];
                }
            });
        });
    };
    Category.prototype.setMainPhoto = function (photo) {
        this.set('mainPhoto', photo);
        this.save();
    };
    Category.prototype.removePhoto = function (photo) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, photo.destroy().catch(function (err) {
                            console.log('eee', err);
                        })];
                    case 1:
                        _a.sent();
                        this.photos.splice(this.photos.indexOf(photo), 1);
                        return [2 /*return*/];
                }
            });
        });
    };
    Category.getSuggestionsCache = {};
    return Category;
}(Parse.Object));
export { Category };
