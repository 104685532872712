export function debounce(delay) {
    if (delay === void 0) { delay = 300; }
    var timeout = null;
    return function (target, propertyKey, descriptor) {
        var original = descriptor.value;
        descriptor.value = function () {
            var _this = this;
            var args = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                args[_i] = arguments[_i];
            }
            if (timeout)
                clearTimeout(timeout);
            timeout = setTimeout(function () { return original.apply(_this, args); }, delay);
        };
        return descriptor;
    };
}
