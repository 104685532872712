import * as Parse from 'parse';
import { Category } from 'cmj/model/category';

export class CachedQuery<
	T extends Parse.Object = Parse.Object
> extends Parse.Query {
	static CACHE_TIME = 5 * 60 * 1000;
	static PROMISE_TIME = 0.1 * 60 * 1000;
	static cache: { [key: string]: { time: number; data: any } } = {};
	static promises: { [key: string]: { time: number; promise: any } } = {};
	constructor(objectClass: new (...args: any[]) => T) {
		super(objectClass);
	}

	private makeKey(type: string, param: string = '') {
		return (
			this.className +
			'>>>' +
			type +
			this.className +
			JSON.stringify(this) +
			param
		);
	}

	find(options?: Parse.Query.FindOptions): Parse.Promise<T[]> {
		const key = this.makeKey('find');
		if (CachedQuery.cache[key])
			if (CachedQuery.cache[key].time > Date.now() - CachedQuery.CACHE_TIME)
				return Parse.Promise.as(CachedQuery.cache[key].data);

		if (CachedQuery.promises[key])
			if (
				CachedQuery.promises[key].time >
				Date.now() - CachedQuery.PROMISE_TIME
			)
				return CachedQuery.promises[key].promise;

		const promise = (super.find(options) as Parse.Promise<T[]>).then(r => {
			delete CachedQuery.promises[key];
			CachedQuery.cache[key] = { time: Date.now(), data: r };
			return r;
		}) as Parse.Promise<T[]>;
		CachedQuery.promises[key] = { time: Date.now(), promise };
		return promise;
	}

	first(options?: Parse.Query.FindOptions): Parse.Promise<T> {
		const key = this.makeKey('first');
		if (CachedQuery.cache[key])
			if (CachedQuery.cache[key].time > Date.now() - CachedQuery.CACHE_TIME)
				return Parse.Promise.as(CachedQuery.cache[key].data);
		if (CachedQuery.promises[key])
			if (
				CachedQuery.promises[key].time >
				Date.now() - CachedQuery.PROMISE_TIME
			)
				return CachedQuery.promises[key].promise;
		const promise = (super.first(options) as Parse.Promise<T>).then(r => {
			delete CachedQuery.promises[key];
			CachedQuery.cache[key] = { time: Date.now(), data: r };
			return r;
		}) as Parse.Promise<T>;
		CachedQuery.promises[key] = { time: Date.now(), promise };
		return promise;
	}

	get(objectId: string, options?: Parse.Query.GetOptions): Parse.Promise<T> {
		const key = this.makeKey('get', objectId);

		if (CachedQuery.cache[key])
			if (CachedQuery.cache[key].time > Date.now() - CachedQuery.CACHE_TIME)
				return Parse.Promise.as(CachedQuery.cache[key].data);
		if (CachedQuery.promises[key])
			if (
				CachedQuery.promises[key].time >
				Date.now() - CachedQuery.PROMISE_TIME
			)
				return CachedQuery.promises[key].promise;
		const promise = (super.get(objectId, options) as Parse.Promise<T>).then(
			r => {
				delete CachedQuery.promises[key];

				CachedQuery.cache[key] = { time: Date.now(), data: r };
				return r;
			}
		) as Parse.Promise<T>;
		CachedQuery.promises[key] = { time: Date.now(), promise };

		return promise;
	}
}
