<div
	class="list"
	[@listAnimation]="items ? items?.length : 0"
	(checkLoadMore)="fireLoadMore()"
>
	<cmj-card
		(callback)="itemClicked(item.usedName)"
		[item]="item"
		*ngFor="let item of items"
	></cmj-card>
</div>
<overlay404 offsetTop="10" *ngIf="notFound"></overlay404>
