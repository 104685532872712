// import { AppModule } from './app.module';
// export interface ICustomWindow extends Window {
// 	__custom_global_stuff: string;
// }
function getWindow() {
    return window;
}
// @Injectable({
// 	providedIn: AppModule,
// })
var WindowService = /** @class */ (function () {
    function WindowService() {
    }
    Object.defineProperty(WindowService.prototype, "nativeWindow", {
        get: function () {
            return getWindow();
        },
        enumerable: true,
        configurable: true
    });
    return WindowService;
}());
export { WindowService };
