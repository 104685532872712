import { Injectable, EventEmitter } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class MetaService {
	constructor(
		private meta: Meta,
		private router: Router,
		private title: Title
	) {
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((nav: any) => {
				console.log('showing404', this.showing404);
				if (this.showing404) this.disable404();
			});
	}

	private showing404 = false;

	show404event = new EventEmitter<boolean>();

	setValues({ keywords, description, title, image }: any) {
		if (keywords) this.meta.updateTag({ name: 'keywords', content: keywords });
		this.meta.updateTag({
			name: 'twitter:card',
			content: 'summary_large_image'
		});
		if (title) {
			this.meta.updateTag({ name: 'twitter:title', content: title });
			this.meta.updateTag({ name: 'twitter:text:title', content: title });
			this.meta.updateTag({ name: 'twitter:image:alt', content: title });
			this.meta.updateTag({ property: 'og:title', content: title });
			this.meta.updateTag({ property: 'og:image:alt', content: title });
			this.title.setTitle(title);
		}
		if (description) {
			this.meta.updateTag({
				name: 'twitter:description',
				content: description
			});
			this.meta.updateTag({ property: 'og:description', content: description });
			this.meta.updateTag({ name: 'description', content: description });
		}

		if (image) {
			this.meta.updateTag({ name: 'twitter:image', content: image });
			this.meta.updateTag({ property: 'og:image', content: image });
		}

		this.meta.updateTag({ property: 'og:url', content: location.href });
	}

	show404() {
		this.title.setTitle('404 - nie odnaleziono');
		this.showing404 = true;
		this.meta.updateTag({ name: 'prerender-status-code', content: '404' });
		this.show404event.emit(true);
	}

	disable404() {
		if (this.title.getTitle() === '404 - nie odnaleziono')
			this.title.setTitle('Czy ... może jeść ... ?');

		this.showing404 = false;
		this.meta.updateTag({ name: 'prerender-status-code', content: '200' });
		this.show404event.emit(false);
	}
}
