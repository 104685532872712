import {
	Component,
	OnInit,
	Input,
	SimpleChanges,
	ElementRef,
	ViewChild,
	HostListener
} from '@angular/core';
import { Eat } from 'cmj/model/eat';
import { Animal } from 'cmj/model/animal';
import { Food } from 'cmj/model/food';
import { trigger, style, transition, animate } from '@angular/animations';
import { listAnimation } from 'cmj/animations';
import { debounce } from 'cmj/debounce.decorator';

@Component({
	selector: 'cmj-one-line-list',
	templateUrl: './one-line-list.component.html',
	styleUrls: ['./one-line-list.component.styl'],
	animations: [
		listAnimation,
		trigger('fadeInOut', [
			transition(':enter', [
				style({ opacity: 0 }),
				animate(
					'275ms ease-in-out',
					style({ opacity: 1, transform: 'scale(1)' })
				)
			]),
			transition(':leave', [
				animate(
					'275ms ease-in-out',
					style({ opacity: 0, transform: 'scale(0)' })
				)
			])
		])
	]
})
export class OneLineListComponent implements OnInit {
	@Input() items: Eat[] | Animal[] | Food[] | undefined;

	scrollRightEnabled = false;
	scrollLeftEnabled = false;
	scrollLeftValue = 0;
	@ViewChild('scrollCardContainer')
	scrollCardContainer: ElementRef | null = null;

	constructor(private elRef: ElementRef) {}

	ngOnInit() {
		if (this.items) this.measureOverflow();
	}

	ngAfterViewInit(): void {
		if (this.items) this.measureOverflow();
	}

	timeout(ms?: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	async ngOnChanges(changes: SimpleChanges) {
		console.log('CHAAAA', changes);
		if (
			changes.items &&
			changes.items.currentValue &&
			changes.items.currentValue.length
		) {
			await this.timeout(100);
			this.measureOverflow();
		}
	}

	@HostListener('window:resize', ['$event'])
	// @debounce(50, Math.random())
	measureOverflow() {
		if (!this.scrollCardContainer) throw 'e';

		const clientWidth = this.scrollCardContainer.nativeElement.clientWidth;
		const scrollWidth = this.scrollCardContainer.nativeElement.scrollWidth;

		this.scrollRightEnabled =
			scrollWidth >
			clientWidth + this.scrollCardContainer.nativeElement.scrollLeft + 64;
		this.scrollLeftEnabled =
			this.scrollCardContainer.nativeElement.scrollLeft > 64;
	}

	async scrollRight() {
		// if (!this.scrollCardContainer) throw "e";
		// this.scrollCardContainer.nativeElement.scrollLeft += 500;
		this.scrollLeftValue += 500;
		await this.timeout(300);
		this.measureOverflow();
	}

	async scrollLeft() {
		// if (!this.scrollCardContainer) throw "e";
		// this.scrollCardContainer.nativeElement.scrollLeft += 500;
		this.scrollLeftValue -= 500;
		await this.timeout(300);
		this.measureOverflow();
	}
}
