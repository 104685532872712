<fab-button></fab-button>
<magic-header #magicHeader [eat]="eat"></magic-header>
<section>
	<div class="content">
		<div class="main-card">
			<h1 [style.color]="eat?.foodPhoto?.color">
				Czy {{ params?.animal }} może {{ eat?.food.eatOrDrinkText }}
				{{ params?.food }}?
			</h1>
			<div class="more">
				<p class="text">{{ eat?.text }}</p>
				<div *ngIf="eat?.dangers">
					<h2>Zagrożenia</h2>
					<p class="text">{{ eat?.dangers }}</p>
				</div>
				<div
					class="aff-container"
					*ngIf="adType === 0 && eat?.gotAffiliateLink"
				>
					<a
						class="btn aff-btn"
						[style.color]="eat?.foodPhoto?.color"
						[style.borderColor]="eat?.foodPhoto?.color"
						[href]="eat?.getRandomAffLink()"
						><span
							*ngIf="
								eat?.getDefaultAffLinkUsed('kakadu');
								else affLinkNotDefault
							"
						>
							sprawdź najlepsze karmy zawierające {{ eat?.food.title }}
						</span></a
					>
				</div>
				<div class="ad-container ad1" *ngIf="adType === 1">
					<adsensearticle type="article" data="7314440165"></adsensearticle>
				</div>
				<div class="ad-container ad2" *ngIf="adType === 2">
					<adsensearticle data="1142061287"></adsensearticle>
				</div>

				<ng-template #affLinkNotDefault>
					sprawdź najlepsze karmy dla zwierzaka
				</ng-template>

				<h2
					[innerHTML]="eat?.summaryLine"
					class="safety"
					[style.color]="eat?.safetyColor"
				></h2>
				<p class="text">{{ eat?.summary }}</p>
				<p class="sources">
					źródła:
					<span *ngFor="let source of eat?.sourceReadable; let i = index">
						<a [href]="'http://' + source">{{ source }}</a
						>{{ i < eat?.sourceReadable.length - 1 ? ', ' : '' }}
					</span>
				</p>
			</div>
		</div>
	</div>
</section>
<section class="comment-section">
	<div class="content">
		<div class="main-card">
			<!-- <div *ngIf="commentsReady" id="disqus_thread"></div> -->
			<div *ngIf="commentsReady" id="hyvor-talk-view"></div>
		</div>
	</div>
</section>
<!-- <script
	*ngIf="commentsReady"
	src="https://czy-moze-jesc.disqus.com/embed.js"
	[data-timestamp]="d"
>

	(function() { // DON'T EDIT BELOW THIS LINE
	var d = document, s = d.createElement('script');
	s.src = 'https://czy-moze-jesc.disqus.com/embed.js';
	s.setAttribute('data-timestamp', +new Date());
	(d.head || d.body).appendChild(s);
	})();
</script> -->
<cmj-one-line-list class="eat-cards" [items]="others"></cmj-one-line-list>
<overlay404 *ngIf="notFound"></overlay404>
