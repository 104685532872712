import { Component, OnInit } from "@angular/core";
import { environment } from "environments/environment";

@Component({
	selector: "footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.styl"]
})
export class FooterComponent implements OnInit {
	version = environment.VERSION;
	constructor() {}

	ngOnInit() {}
}
