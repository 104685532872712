import { Component, OnInit } from "@angular/core";
import { Food } from "cmj/model/food";
import { Animal } from "cmj/model/animal";
import { ActivatedRoute, UrlSegment, Router } from "@angular/router";
import { listAnimation } from "cmj/animations";

@Component({
	selector: "category",
	templateUrl: "./category.component.html",
	styleUrls: ["./category.component.styl"],
	animations: [listAnimation]
})
export class CategoryComponent implements OnInit {
	constructor(private route: ActivatedRoute, private router: Router) {}

	items: Food[] | Animal[] | undefined;
	path: string | undefined;
	itemsReady = false;
	// closing: string | undefined;

	ngOnInit() {
		this.route.url.subscribe(url => this.load(url as any));
	}

	load(url: UrlSegment[]) {
		this.path = url[0].path;
		if (this.path === Animal.NAME)
			Animal.getAll(true).then(a => (this.items = a));
		else if (this.path === Food.NAME)
			Food.getAll(true).then(f => (this.items = f));
		else this.router.navigate(["/"]);
	}

	itemClicked(name: string) {
		this.items = [];
		setTimeout(() => {
			const path = `/${this.path}/${name}`;
			this.router.navigate([path]);
		}, 400);
	}
}
