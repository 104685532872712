import { EventEmitter } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/router";
var MetaService = /** @class */ (function () {
    function MetaService(meta, router, title) {
        var _this = this;
        this.meta = meta;
        this.router = router;
        this.title = title;
        this.showing404 = false;
        this.show404event = new EventEmitter();
        this.router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (nav) {
            console.log('showing404', _this.showing404);
            if (_this.showing404)
                _this.disable404();
        });
    }
    MetaService.prototype.setValues = function (_a) {
        var keywords = _a.keywords, description = _a.description, title = _a.title, image = _a.image;
        if (keywords)
            this.meta.updateTag({ name: 'keywords', content: keywords });
        this.meta.updateTag({
            name: 'twitter:card',
            content: 'summary_large_image'
        });
        if (title) {
            this.meta.updateTag({ name: 'twitter:title', content: title });
            this.meta.updateTag({ name: 'twitter:text:title', content: title });
            this.meta.updateTag({ name: 'twitter:image:alt', content: title });
            this.meta.updateTag({ property: 'og:title', content: title });
            this.meta.updateTag({ property: 'og:image:alt', content: title });
            this.title.setTitle(title);
        }
        if (description) {
            this.meta.updateTag({
                name: 'twitter:description',
                content: description
            });
            this.meta.updateTag({ property: 'og:description', content: description });
            this.meta.updateTag({ name: 'description', content: description });
        }
        if (image) {
            this.meta.updateTag({ name: 'twitter:image', content: image });
            this.meta.updateTag({ property: 'og:image', content: image });
        }
        this.meta.updateTag({ property: 'og:url', content: location.href });
    };
    MetaService.prototype.show404 = function () {
        this.title.setTitle('404 - nie odnaleziono');
        this.showing404 = true;
        this.meta.updateTag({ name: 'prerender-status-code', content: '404' });
        this.show404event.emit(true);
    };
    MetaService.prototype.disable404 = function () {
        if (this.title.getTitle() === '404 - nie odnaleziono')
            this.title.setTitle('Czy ... może jeść ... ?');
        this.showing404 = false;
        this.meta.updateTag({ name: 'prerender-status-code', content: '200' });
        this.show404event.emit(false);
    };
    MetaService.ngInjectableDef = i0.defineInjectable({ factory: function MetaService_Factory() { return new MetaService(i0.inject(i1.Meta), i0.inject(i2.Router), i0.inject(i1.Title)); }, token: MetaService, providedIn: "root" });
    return MetaService;
}());
export { MetaService };
