var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Food } from 'cmj/model/food';
import { Animal } from 'cmj/model/animal';
import { Eat } from 'cmj/model/eat';
import { MetaService } from 'cmj/meta.service';
import * as Parse from 'parse';
var CategorySelectedComponent = /** @class */ (function () {
    function CategorySelectedComponent(route, router, meta) {
        this.route = route;
        this.router = router;
        this.meta = meta;
        this.itemsReady = false;
        this.catAnimal = Animal.NAME;
        this.notFound = false;
        this.canLoadMore = true;
        this.PAGE_SIZE = 10;
    }
    CategorySelectedComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.url.subscribe(function (url) { return _this.load(url); });
    };
    CategorySelectedComponent.prototype.showNotFound = function () {
        this.meta.show404();
        this.notFound = true;
    };
    CategorySelectedComponent.prototype.load = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            var selectedName, _a, _b, _c, _d, usePlural, name_1, add, title;
            return __generator(this, function (_e) {
                switch (_e.label) {
                    case 0:
                        this.category = url[0].path;
                        selectedName = url[1].path.split('-').join(' ');
                        if (!(this.category === Animal.NAME)) return [3 /*break*/, 3];
                        _a = this;
                        return [4 /*yield*/, Animal.getByName(selectedName)];
                    case 1:
                        _a.selected = _e.sent();
                        if (!this.selected) {
                            Parse.Cloud.run('miss', { animal: selectedName });
                            return [2 /*return*/, this.showNotFound()];
                        }
                        _b = this;
                        return [4 /*yield*/, Eat.getManyFromAF(this.selected, undefined, undefined, 0, this.PAGE_SIZE)];
                    case 2:
                        _b.items = _e.sent();
                        this.meta.setValues({
                            keywords: this.selected.aliases.slice().join(', '),
                            description: "Sprawd\u017A co mo\u017Ce je\u015B\u0107 Tw\u00F3j " + this.selected.usedName + ".",
                            title: "Co mog\u0105 je\u015B\u0107 " + this.selected.plural + "?",
                            image: (this.selected.mainPhoto || {}).urlBig
                        });
                        return [3 /*break*/, 6];
                    case 3:
                        if (!(this.category === Food.NAME)) return [3 /*break*/, 6];
                        _c = this;
                        return [4 /*yield*/, Food.getByName(selectedName)];
                    case 4:
                        _c.selected = _e.sent();
                        if (!this.selected) {
                            Parse.Cloud.run('miss', { food: selectedName });
                            return [2 /*return*/, this.showNotFound()];
                        }
                        if (!(this.selected instanceof Food))
                            throw new Error('e');
                        _d = this;
                        return [4 /*yield*/, Eat.getManyFromAF(undefined, this.selected, undefined, 0, this.PAGE_SIZE)];
                    case 5:
                        _d.items = _e.sent();
                        usePlural = this.selected.title === this.selected.plural;
                        name_1 = this.selected.plural
                            ? this.selected.plural
                            : this.selected.singular;
                        add = 'są bezpieczne';
                        if (!usePlural) {
                            if (this.selected.gender === 'meski')
                                add = 'jest bezpieczny';
                            else
                                add = 'jest bezpieczna';
                        }
                        title = "Czy " + name_1 + " " + add + " dla...?";
                        this.meta.setValues({
                            keywords: this.selected.aliases.slice().join(', '),
                            description: "Sprawd\u017A, czy mo\u017Cesz podawa\u0107 " + this.selected.plural + " swojemu...",
                            title: title,
                            image: (this.selected.mainPhoto || {}).urlBig
                        });
                        _e.label = 6;
                    case 6:
                        if (this.items && this.items.length < this.PAGE_SIZE)
                            this.canLoadMore = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    CategorySelectedComponent.prototype.itemClicked = function (item) {
        var _this = this;
        this.items = [];
        setTimeout(function () {
            if (!_this.selected)
                throw new Error('no sel');
            var path = '';
            if (_this.category === 'zwierzak') {
                var eatType = item.food.eatOrDrinkText;
                path = "/czy-" + _this.selected.name + "-mo\u017Ce-" + eatType + "-" + item.food.plural;
            }
            else {
                var eatType = _this.selected.eatOrDrinkText;
                path = "/czy-" + item.animal.name + "-mo\u017Ce-" + eatType + "-" + _this.selected.plural;
            }
            _this.router.navigate([path]);
        }, 400);
    };
    CategorySelectedComponent.prototype.fireLoadMore = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, items, itemsBefore, itemsAfter;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!this.canLoadMore)
                            return [2 /*return*/];
                        itemsBefore = (this.items || []).length;
                        if (!(this.category === Animal.NAME)) return [3 /*break*/, 2];
                        return [4 /*yield*/, Eat.getManyFromAF(this.selected, undefined, undefined, itemsBefore, this.PAGE_SIZE)];
                    case 1:
                        items = _b.sent();
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(this.category === Food.NAME)) return [3 /*break*/, 4];
                        return [4 /*yield*/, Eat.getManyFromAF(undefined, this.selected, undefined, itemsBefore, this.PAGE_SIZE)];
                    case 3:
                        items = _b.sent();
                        _b.label = 4;
                    case 4:
                        if (this.items && items)
                            (_a = this.items).push.apply(_a, items);
                        itemsAfter = (this.items || []).length;
                        if (itemsAfter - itemsBefore < this.PAGE_SIZE)
                            this.canLoadMore = false;
                        return [2 /*return*/];
                }
            });
        });
    };
    return CategorySelectedComponent;
}());
export { CategorySelectedComponent };
