<div
	class="header-container"
	[style.height.px]="h"
	[style.position]="preview ? 'relative' : 'fixed'"
	[style.backgroundColor]="foodColor"
	[style.backgroundImage]="eat ? eat!.microPhotoStyle : 'none'"
>
	<div class="opac" [style.opacity]="state === 0 ? 0 : 1">
		<img
			[alt]="eat?.title"
			#image
			*ngIf="!preview && headerImage"
			[height]="h"
			[src]="headerImage"
		/>
		<canvas [width]="outW" [height]="h" [style.height.px]="h" #c></canvas>
	</div>
	<button *ngIf="preview" (click)="scrollClicked = !scrollClicked" class="btn">
		przełącz tryb
	</button>
</div>
