/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./landing.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modules/one-line-list/one-line-list.component.ngfactory";
import * as i3 from "../modules/one-line-list/one-line-list.component";
import * as i4 from "@angular/common";
import * as i5 from "@angular/router";
import * as i6 from "./landing.component";
var styles_LandingComponent = [i0.styles];
var RenderType_LandingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LandingComponent, data: {} });
export { RenderType_LandingComponent as RenderType_LandingComponent };
export function View_LandingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "cmj-one-line-list", [["class", "eat-cards-landing"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).measureOverflow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OneLineListComponent_0, i2.RenderType_OneLineListComponent)), i1.ɵdid(1, 4833280, null, 0, i3.OneLineListComponent, [i1.ElementRef], { items: [0, "items"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(5, 1), (_l()(), i1.ɵeld(6, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Zwierzaki"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "cmj-one-line-list", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).measureOverflow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OneLineListComponent_0, i2.RenderType_OneLineListComponent)), i1.ɵdid(9, 4833280, null, 0, i3.OneLineListComponent, [i1.ElementRef], { items: [0, "items"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(11, 0, null, null, 4, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 671744, null, 0, i5.RouterLinkWithHref, [i5.Router, i5.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(13, 1), (_l()(), i1.ɵeld(14, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Pokarm"])), (_l()(), i1.ɵeld(16, 0, null, null, 2, "cmj-one-line-list", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 17).measureOverflow($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_OneLineListComponent_0, i2.RenderType_OneLineListComponent)), i1.ɵdid(17, 4833280, null, 0, i3.OneLineListComponent, [i1.ElementRef], { items: [0, "items"] }, null), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.eats)); _ck(_v, 1, 0, currVal_0); var currVal_3 = _ck(_v, 5, 0, ("/" + _co.animalCategoryName)); _ck(_v, 4, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 9, 0, i1.ɵnov(_v, 10).transform(_co.animals)); _ck(_v, 9, 0, currVal_4); var currVal_7 = _ck(_v, 13, 0, ("/" + _co.foodCategoryName)); _ck(_v, 12, 0, currVal_7); var currVal_8 = i1.ɵunv(_v, 17, 0, i1.ɵnov(_v, 18).transform(_co.foods)); _ck(_v, 17, 0, currVal_8); }, function (_ck, _v) { var currVal_1 = i1.ɵnov(_v, 4).target; var currVal_2 = i1.ɵnov(_v, 4).href; _ck(_v, 3, 0, currVal_1, currVal_2); var currVal_5 = i1.ɵnov(_v, 12).target; var currVal_6 = i1.ɵnov(_v, 12).href; _ck(_v, 11, 0, currVal_5, currVal_6); }); }
export function View_LandingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "landing", [], null, null, null, View_LandingComponent_0, RenderType_LandingComponent)), i1.ɵdid(1, 114688, null, 0, i6.LandingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LandingComponentNgFactory = i1.ɵccf("landing", i6.LandingComponent, View_LandingComponent_Host_0, {}, {}, []);
export { LandingComponentNgFactory as LandingComponentNgFactory };
