import { OnInit } from '@angular/core';
import { Eat } from 'cmj/model/eat';
import { Animal } from 'cmj/model/animal';
import { Food } from 'cmj/model/food';
import { DomSanitizer } from '@angular/platform-browser';
import { Photo } from 'cmj/model/photo';
var CardComponent = /** @class */ (function () {
    function CardComponent(sanitizer) {
        this.sanitizer = sanitizer;
        this.isEat = false;
        this.canShare = navigator.share;
    }
    CardComponent.prototype.ngOnInit = function () {
        if (this.item instanceof Eat)
            this.isEat = true;
    };
    Object.defineProperty(CardComponent.prototype, "title", {
        get: function () {
            // console.log('aaa', this.item, this.item.title);
            if (this.item instanceof Eat)
                return this.item.title;
            else if (this.item)
                return this.item.title;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardComponent.prototype, "preview", {
        get: function () {
            // console.log('aaa', this.item, this.item.title);
            if (this.item instanceof Eat)
                return this.item.text.substr(0, 100);
            else
                return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardComponent.prototype, "miniPhotoUrl", {
        get: function () {
            if (this.item instanceof Eat)
                return this.item.miniPhotoUrl;
            else if (this.item)
                return this.item.mainPhoto.urlMini;
            else
                return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardComponent.prototype, "micro", {
        get: function () {
            if (this.item instanceof Eat)
                return this.item.microPhotoUrl || Photo.errorPixel;
            else if (this.item)
                return this.item.mainPhoto.micro;
            else
                return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardComponent.prototype, "bg", {
        get: function () {
            return this.sanitizer.bypassSecurityTrustStyle('url(' + this.miniPhotoUrl + '), url(' + this.micro + ')');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CardComponent.prototype, "url", {
        get: function () {
            if (this.item instanceof Eat)
                return [
                    "/czy-" + this.item.animal.name + "-mo\u017Ce-" + this.item.food.eatOrDrinkText + "-" + this.item.food.titleUrl
                ];
            else if (this.item instanceof Animal) {
                if (this.selected instanceof Food) {
                    var eatText = this.selected.eatOrDrinkText;
                    return [
                        "/czy-" + this.item.name + "-mo\u017Ce-" + eatText + "-" + this.selected.titleUrl
                    ];
                }
                else
                    return ["/" + Animal.NAME + "/" + this.item.name];
            }
            else if (this.item instanceof Food) {
                if (this.selected instanceof Animal)
                    return [
                        "/czy-" + this.selected.name + "-mo\u017Ce-" + this.item.eatOrDrinkText + "-" + this.item.titleUrl
                    ];
                else
                    return ["/" + Food.NAME + "/" + this.item.titleUrl];
            }
        },
        enumerable: true,
        configurable: true
    });
    CardComponent.prototype.share = function () {
        navigator.share({
            title: this.title,
            // text: "Czytaj więcej!",
            url: this.url
        });
    };
    return CardComponent;
}());
export { CardComponent };
