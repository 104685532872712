import { fromEvent as observableFromEvent, Observable } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import {
	Component,
	OnInit,
	Input,
	ViewChild,
	ElementRef,
	AfterViewInit,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
	SimpleChange
} from '@angular/core';

import { Animal } from 'cmj/model/animal';
import { Food } from 'cmj/model/food';

import {
	state,
	trigger,
	transition,
	style,
	animate
} from '@angular/animations';
import { Router } from '@angular/router';
import { debounce } from 'cmj/debounce.decorator';

@Component({
	selector: 'app-title',
	templateUrl: './title.component.html',
	styleUrls: ['./title.component.styl'],
	animations: [
		trigger('optionsAnim', [
			transition(':enter', [
				// animate(100, style({transform: 'translateX(100%)'}))
				style({ opacity: 0, transform: 'scale(0.5)' }),
				animate(150)
			]),
			transition(':leave', [
				animate(
					150,
					style({
						opacity: 0,
						transform: 'scale(0.5)'
					})
				)
			])
		]),
		trigger('inputAnim', [
			state(
				'auto',
				style({
					width: '*'
				})
			),
			state(
				'zero',
				style({
					width: '4vw'
				})
			),
			transition('* => *', animate('300ms ease-in-out'))
		])
	]
})
export class TitleComponent implements OnInit, OnChanges, AfterViewInit {
	@Input('animal') animal: Animal | undefined;
	@Input('food') food: Food | undefined;

	@Input('animalName') animalName: string | null = null;
	@Input('foodName') foodName: string | null = null;

	@ViewChild('animalInput') animalInput: ElementRef | undefined;
	@ViewChild('foodInput') foodInput: ElementRef | undefined;

	@Output('change') change = new EventEmitter<{
		animal?: Animal;
		food?: Food;
	}>();

	animalText: string | undefined;
	foodText: string | undefined;

	// lastAnimalChange: string | undefined;
	// lastFoodChange: string | undefined;

	lastOptions: string | null = null;

	options: { label: string; obj: Animal | Food }[] = [];
	showOverlay = false;
	overlayStyle = {
		top: '10px',
		bottom: undefined,
		left: '10px',
		width: '200px',
		maxHeight: '200px',
		transformOrigin: 'top left'
	};

	animalAnimationState = 'zero';
	animalFocused = false;

	foodAnimationState = 'zero';
	foodFocused = false;

	constructor(private router: Router) {}

	ngOnInit() {
		console.log('ONINIT', this.food);
		if (this.animal) this.animalText = this.animal.name;
		if (this.food) this.foodText = this.food.title;
		if (this.animalName) this.animalText = decodeURI(this.animalName);
		if (this.foodName) this.foodText = decodeURI(this.foodName);
	}

	ngOnChanges(changes: SimpleChanges): void {
		console.log('change', this.food);
		function changed(change: SimpleChange) {
			return change && change.currentValue !== change.previousValue;
		}

		if (changed(changes.animalName)) {
			if (
				changes.animalName.currentValue === Animal.NAME ||
				!changes.animalName.currentValue
			)
				setTimeout(() => (this.animalText = ''));
			else this.animalText = decodeURI(changes.animalName.currentValue);
			this.animalAnimationState =
				changes.animalName.currentValue.length > 1 ? 'auto' : 'zero';
		}
		if (changed(changes.foodName)) {
			console.log('changed foodname', changes.foodName);
			console.log('this.foodText', this.foodText);

			if (
				changes.foodName.currentValue === Food.NAME ||
				!changes.foodName.currentValue
			)
				setTimeout(() => (this.foodText = ''));
			else this.foodText = decodeURI(changes.foodName.currentValue);
			this.foodAnimationState =
				changes.foodName.currentValue.length > 1 ? 'auto' : 'zero';
			console.log('this.foodText2', this.foodText);
		}

		if (changed(changes.animal)) {
			if (changes.animal.currentValue)
				this.animalText = changes.animal.currentValue.usedName;
			else setTimeout(() => (this.animalText = ''));
		}
		if (changed(changes.food)) {
			console.log('changed food', changes.food);
			this.foodText = changes.food.currentValue
				? changes.food.currentValue.title
				: '';
		}
	}

	reset(type: 'a' | 'f') {
		if (type === 'a') {
			this.animal = undefined;
			this.animalText = '';
		} else {
			this.food = undefined;
			this.foodText = '';
		}
		this.showOverlay = false;
		if (this.animal === undefined && this.food === undefined)
			this.router.navigate(['/']);
		this.change.emit({ animal: this.animal, food: this.food });
	}

	async onEnter(e: KeyboardEvent | null, type: 'animal' | 'food') {
		if (e) e.preventDefault();
		if (type === 'animal') {
			const text = this.animalInput!.nativeElement.innerHTML.replace(
				/<br>/g,
				''
			);

			if (text === '') {
				this.reset('a');
				return false;
			}

			const animals = await Animal.getSuggestionsP(text);

			let clickedAnimal;
			if (
				animals[0] &&
				animals[0].aliases.find(alias => alias.indexOf(text) === 0)
			)
				clickedAnimal = animals[0];
			if (!clickedAnimal)
				clickedAnimal = animals.find(a => a.aliases.indexOf(text) > -1);

			if (clickedAnimal) this.optionSelected(clickedAnimal as any);
			else this.animalText = '';
		} else if (type === 'food') {
			const text = this.foodInput!.nativeElement.innerHTML.replace(/<br>/g, '');

			if (text === '') {
				this.reset('f');
				return false;
			}

			const food = await Food.getSuggestionsP(text);

			let clickedFood;
			if (food[0] && food[0].aliases.find(alias => alias.indexOf(text) === 0))
				clickedFood = food[0];
			if (!clickedFood)
				clickedFood = food.find(f => f.aliases.indexOf(text) > -1);

			if (clickedFood) this.optionSelected(clickedFood as any);
			else this.foodText = '';
		}
		return false;
	}

	optionSelected(option: Food | Animal) {
		if (option instanceof Animal) {
			this.animal = option;
			if (!this.animalInput) throw new Error('no input');
			this.animalInput.nativeElement.innerHTML = option.title;
		}
		if (option instanceof Food) {
			this.food = option;
			if (!this.foodInput) throw new Error('no input');
			this.foodInput.nativeElement.innerHTML = option.title;
		}

		this.showOverlay = false;

		if (this.animal && this.food)
			this.router.navigate([
				`/czy-${this.animal.usedName}-może-${this.food.eatOrDrinkText}-${
					this.food.title
				}`
			]);
		else if (this.animal)
			this.router.navigate(['/zwierzak', this.animal.usedName]);
		else if (this.food) this.router.navigate(['/pokarm', this.food.titleUrl]);

		this.change.emit({ animal: this.animal, food: this.food });
	}

	fitOptions(el: HTMLElement, l: number) {
		this.overlayStyle.width = Math.max(200, el.offsetWidth) + 'px';
		const bb = el.getBoundingClientRect();
		this.overlayStyle.left = bb.left + 'px';
		if (
			(window.innerHeight - bb.bottom > 50 && l === 1) ||
			(window.innerHeight - bb.bottom > 100 && l > 1)
		) {
			this.overlayStyle.top = bb.bottom + 4 + 'px';
			this.overlayStyle.bottom = undefined;
			this.overlayStyle.maxHeight = window.innerHeight - bb.bottom - 24 + 'px';
		} else {
			this.overlayStyle.transformOrigin = 'bottom left';
		}
	}

	animalUpdate(objects: Animal[], el: HTMLSpanElement, text: string) {
		const l = objects.length;
		if (l > 0) {
			if ('a' + text !== this.lastOptions) {
				this.options = objects.map(obj => {
					let label = obj.title.toLowerCase();
					if (text && label.indexOf(text.toLowerCase()) !== 0) {
						label = `${obj.aliases.find(
							a => a.toLowerCase().indexOf(text.toLowerCase()) === 0
						)} (${label})`;
					}
					return { label, obj };
				});
				this.lastOptions = 'a' + text;
			}

			this.showOverlay = true;
			this.fitOptions(el, l);
		} else this.showOverlay = false;
	}

	foodUpdate(objects: Food[], el: HTMLSpanElement, text: string) {
		const l = objects.length;
		if (l > 0) {
			if ('f' + text !== this.lastOptions) {
				this.options = objects.map(obj => {
					let label = obj.title.toLowerCase();
					if (text && label.indexOf(text.toLowerCase()) !== 0) {
						label = `${obj.tokens.find(
							a => a.toLowerCase().indexOf(text.toLowerCase()) === 0
						)} (${label})`;
					}
					return { label, obj };
				});
				this.lastOptions = 'f' + text;
			}

			this.showOverlay = true;
			this.fitOptions(el, l);
		} else this.showOverlay = false;
	}

	animalChanged() {
		if (!this.animalInput) throw new Error('no element');
		const el: HTMLSpanElement = this.animalInput.nativeElement;
		const text = el.innerHTML.replace(/<br>/, '');

		if (!this.animal || this.animal.name !== text) {
			Animal.getSuggestions(text).subscribe(opts =>
				this.animalUpdate(opts, el, text)
			);
		}
	}

	foodChanged() {
		if (!this.foodInput) throw new Error('no element');
		const el: HTMLSpanElement = this.foodInput.nativeElement;
		const text = el.innerHTML.replace(/<br>/, '');

		if (!this.food || this.food.name !== text) {
			Food.getSuggestions(text, true).subscribe(opts =>
				this.foodUpdate(opts, el, text)
			);
		}
	}

	ngAfterViewInit() {
		if (!this.animalInput || !this.foodInput) throw new Error('no element');
		observableFromEvent(this.animalInput.nativeElement, 'input')
			.pipe(debounceTime(400))
			.subscribe(() => this.animalChanged());

		observableFromEvent(this.foodInput.nativeElement, 'input')
			.pipe(debounceTime(400))
			.subscribe(() => this.foodChanged());
	}

	focusedAnimal() {
		this.animalFocused = true;
		// console.log('focusedAnimal',this.animalInput.nativeElement.innerHTML,this.animalInput.nativeElement.innerHTML === '')
		if (!this.animalInput) throw new Error('no element');
		if (this.animalInput.nativeElement.innerHTML === '') this.animalChanged();
	}

	@debounce(200)
	blurred(type: 'animal' | 'food') {
		// console.log('focusedAnimal',this.animalInput.nativeElement.innerHTML,this.animalInput.nativeElement.innerHTML === '')
		if (type === 'animal') this.animalFocused = false;
		if (type === 'food') this.foodFocused = false;
		this.onEnter(null, type);
	}

	focusedFood() {
		this.foodFocused = true;
		if (!this.foodInput) throw new Error('no element');
		if (this.foodInput.nativeElement.innerHTML === '') this.foodChanged();
	}

	// changed(field) {

	// }
}
