import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Animal } from 'cmj/model/animal';
import { Food } from 'cmj/model/food';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.styl']
})
export class HomeComponent implements OnInit {
	animal: Animal | undefined;
	food: Food | undefined;
	foodName: string | undefined;
	animalName: string | undefined;
	height = window.innerHeight;
	width = window.innerWidth;

	constructor(public location: Location, private router: Router) {}

	routeChanged() {}
	ngOnInit() {
		const parts = this.location.path().split('/');
		if (parts[2]) {
			if (parts[1] === Animal.NAME) this.animalName = parts[2];
			if (parts[1] === Food.NAME)
				this.foodName = decodeURIComponent(parts[2])
					.split('-')
					.join(' ');
		}
		this.update();
		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((nav: any) => {
				const category = nav.url.split('/')[1];
				const selected = nav.url.split('/').pop();
				if (category === Animal.NAME) this.animalName = selected;
				else if (category === Food.NAME)
					this.foodName = decodeURIComponent(selected)
						.split('-')
						.join(' ');
				else {
					this.animal = undefined;
					this.food = undefined;
					this.animalName = '';
					this.foodName = '';
				}
				console.log('HOME SUB', this.foodName, 'zzz');
				this.update();
			});
		// this.route.url.subscribe(params => this.update(params));
	}

	async update() {
		if (this.animalName) this.animal = await Animal.getByName(this.animalName);
		if (this.foodName) this.food = await Food.getByName(this.foodName);
	}

	titleChanged(t: any) {
		console.log('title changed', t);
	}
}
