/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overlay404.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./overlay404.component";
import * as i4 from "@angular/common";
var styles_Overlay404Component = [i0.styles];
var RenderType_Overlay404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Overlay404Component, data: {} });
export { RenderType_Overlay404Component as RenderType_Overlay404Component };
export function View_Overlay404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Ups!"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Niestety, nie wiemy jeszcze nic na ten temat. Zajrzyj do nas za jaki\u015B czas, postaramy si\u0119 poprawi\u0107 :)"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.back() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["wr\u00F3\u0107"])), (_l()(), i1.ɵeld(7, 0, null, null, 2, "button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 8).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(8, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["strong g\u0142\u00F3wna"])), (_l()(), i1.ɵeld(10, 0, null, null, 0, "img", [["src", "/assets/images/sad.png"]], null, null, null, null, null))], function (_ck, _v) { var currVal_0 = "/"; _ck(_v, 8, 0, currVal_0); }, null); }
export function View_Overlay404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "overlay404", [], [[4, "top", "vh"]], null, null, View_Overlay404Component_0, RenderType_Overlay404Component)), i1.ɵdid(1, 114688, null, 0, i3.Overlay404Component, [i4.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).offsetTop; _ck(_v, 0, 0, currVal_0); }); }
var Overlay404ComponentNgFactory = i1.ɵccf("overlay404", i3.Overlay404Component, View_Overlay404Component_Host_0, { offsetTop: "offsetTop" }, {}, []);
export { Overlay404ComponentNgFactory as Overlay404ComponentNgFactory };
