import {
	Component,
	OnInit,
	OnChanges,
	SimpleChanges,
	ElementRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Eat } from 'cmj/model/eat';
import { Location } from '@angular/common';
import { MetaService } from 'cmj/meta.service';
import * as Parse from 'parse';
import { timeoutPromise } from 'cmj/helpers';

@Component({
	selector: 'item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.styl']
})
export class ItemComponent implements OnInit {
	// animal: Animal | undefined;
	// food: Food | undefined;
	eat: Eat | undefined;
	others: Eat[] = [];
	params: { animal: string; food: string } | undefined;
	notFound = false;
	d: Date = new Date();
	commentsReady = false;
	commentsScript: HTMLScriptElement | undefined;
	adType = Math.floor(Math.random() * 2) + 1;
	constructor(
		private route: ActivatedRoute,
		public location: Location,
		private meta: MetaService,
		private elementRef: ElementRef
	) {
		// console.log(this.location.path());
		// const path = this.location.path().split('-');
		// this.params = {
		// 	animal: decodeURI(path[1]),
		// 	food: decodeURI(path[4])
		// };
		// console.log('QQQ1');
	}

	ngOnInit() {
		this.route.params.subscribe(params => {
			if (this.commentsScript) this.unloadComments(this.commentsScript);
			this.params = {
				animal: params.animal,
				food: params.food.split('-').join(' ')
			};
			this.loadAF(this.params);
		});
	}

	unloadComments(scr: HTMLScriptElement) {
		this.commentsReady = false;
		const parent = scr.parentElement;
		if (!parent) throw new Error('x');
		parent.removeChild(scr);
	}

	loadComments() {
		const self = this;

		this.commentsReady = true;

		this.d = new Date();
		// (window as any).disqus_config = function() {
		// 	if (!self.eat) throw new Error('x');
		// 	this.page.url = self.eat.canonicalUrl;
		// 	this.page.identifier = 'eat' + self.eat.id;
		// };
		setTimeout(() => {
			this.commentsScript = document.createElement('script');
			this.commentsScript.src = '//talk.hyvor.com/web-api/embed';
			this.commentsScript.setAttribute('data-timestamp', new Date().toString());
			(document.head || document.body).appendChild(this.commentsScript);
		});
	}

	async loadAF(params: { animal: string; food: string }) {
		this.eat = await Eat.getFromNames(params.animal, params.food);
		this.elementRef.nativeElement.style.setProperty(
			'--main-color',
			(this.eat.foodPhoto || { color: '#444' }).color
		);

		if (!this.eat) {
			this.notFound = true;
			Parse.Cloud.run('miss', { animal: params.animal, food: params.food });
			return this.meta.show404();
		}

		this.meta.setValues({
			keywords: [...this.eat.animal.aliases, ...this.eat.food.aliases].join(
				', '
			),
			// tslint:disable-next-line:max-line-length
			description: `Nie ryzykuj, sprawdź czy Twój ${
				this.eat.animal.usedName
			} może bezpiecznie jeść ${
				this.eat.food.title
			}. Zobacz klasyfikację oraz dokładny opis korzyści i zagrożeń, informacje tylko ze sprawdzionych źródeł.`,
			title: this.eat.title,
			image: this.eat.getPhotoUrl('s480')
		});

		this.others = await Eat.getManyFromAF(
			this.eat.animal,
			undefined,
			undefined,
			0,
			6
		);
		this.others = this.others.filter(eat => this.eat && eat.id !== this.eat.id);

		await timeoutPromise(500);
		this.loadComments();
	}
}
