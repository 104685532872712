/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./card.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "./card.component";
import * as i5 from "@angular/platform-browser";
var styles_CardComponent = [i0.styles];
var RenderType_CardComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CardComponent, data: {} });
export { RenderType_CardComponent as RenderType_CardComponent };
function View_CardComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "preview"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.preview; _ck(_v, 1, 0, currVal_0); }); }
function View_CardComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "share"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.share() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 5, ":svg:svg", [["aria-hidden", "true"], ["class", "feather feather-share-2 sc-dnqmqq jxshSx"], ["data-reactid", "1036"], ["fill", "none"], ["height", "42"], ["stroke", "currentColor"], ["stroke-linecap", "round"], ["stroke-linejoin", "round"], ["stroke-width", "1.2"], ["viewBox", "0 0 24 24"], ["width", "42"], ["xmlns", "http://www.w3.org/2000/svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, ":svg:circle", [["cx", "18"], ["cy", "5"], ["r", "3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, ":svg:circle", [["cx", "6"], ["cy", "12"], ["r", "3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, ":svg:circle", [["cx", "18"], ["cy", "19"], ["r", "3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, ":svg:line", [["x1", "8.59"], ["x2", "15.42"], ["y1", "13.51"], ["y2", "17.49"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, ":svg:line", [["x1", "15.41"], ["x2", "8.59"], ["y1", "6.51"], ["y2", "10.49"]], null, null, null, null, null))], null, null); }
export function View_CardComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "card"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "a", [["class", "card-header"]], [[4, "backgroundImage", null], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 671744, null, 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 6, "div", [["class", "actions"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_1)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 1, "button", [["class", "btn read"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Czytaj wi\u0119cej"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CardComponent_2)), i1.ɵdid(11, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.url; _ck(_v, 2, 0, currVal_3); var currVal_5 = _co.isEat; _ck(_v, 7, 0, currVal_5); var currVal_6 = _co.canShare; _ck(_v, 11, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.bg; var currVal_1 = i1.ɵnov(_v, 2).target; var currVal_2 = i1.ɵnov(_v, 2).href; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = _co.title; _ck(_v, 4, 0, currVal_4); }); }
export function View_CardComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cmj-card", [], null, null, null, View_CardComponent_0, RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i4.CardComponent, [i5.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CardComponentNgFactory = i1.ɵccf("cmj-card", i4.CardComponent, View_CardComponent_Host_0, { item: "item", selected: "selected" }, {}, []);
export { CardComponentNgFactory as CardComponentNgFactory };
