var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Eat } from 'cmj/model/eat';
import { Location } from '@angular/common';
import { MetaService } from 'cmj/meta.service';
import * as Parse from 'parse';
import { timeoutPromise } from 'cmj/helpers';
var ItemComponent = /** @class */ (function () {
    function ItemComponent(route, location, meta, elementRef) {
        this.route = route;
        this.location = location;
        this.meta = meta;
        this.elementRef = elementRef;
        this.others = [];
        this.notFound = false;
        this.d = new Date();
        this.commentsReady = false;
        this.adType = Math.floor(Math.random() * 2) + 1;
        // console.log(this.location.path());
        // const path = this.location.path().split('-');
        // this.params = {
        // 	animal: decodeURI(path[1]),
        // 	food: decodeURI(path[4])
        // };
        // console.log('QQQ1');
    }
    ItemComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            if (_this.commentsScript)
                _this.unloadComments(_this.commentsScript);
            _this.params = {
                animal: params.animal,
                food: params.food.split('-').join(' ')
            };
            _this.loadAF(_this.params);
        });
    };
    ItemComponent.prototype.unloadComments = function (scr) {
        this.commentsReady = false;
        var parent = scr.parentElement;
        if (!parent)
            throw new Error('x');
        parent.removeChild(scr);
    };
    ItemComponent.prototype.loadComments = function () {
        var _this = this;
        var self = this;
        this.commentsReady = true;
        this.d = new Date();
        // (window as any).disqus_config = function() {
        // 	if (!self.eat) throw new Error('x');
        // 	this.page.url = self.eat.canonicalUrl;
        // 	this.page.identifier = 'eat' + self.eat.id;
        // };
        setTimeout(function () {
            _this.commentsScript = document.createElement('script');
            _this.commentsScript.src = '//talk.hyvor.com/web-api/embed';
            _this.commentsScript.setAttribute('data-timestamp', new Date().toString());
            (document.head || document.body).appendChild(_this.commentsScript);
        });
    };
    ItemComponent.prototype.loadAF = function (params) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this;
                        return [4 /*yield*/, Eat.getFromNames(params.animal, params.food)];
                    case 1:
                        _a.eat = _c.sent();
                        this.elementRef.nativeElement.style.setProperty('--main-color', (this.eat.foodPhoto || { color: '#444' }).color);
                        if (!this.eat) {
                            this.notFound = true;
                            Parse.Cloud.run('miss', { animal: params.animal, food: params.food });
                            return [2 /*return*/, this.meta.show404()];
                        }
                        this.meta.setValues({
                            keywords: this.eat.animal.aliases.concat(this.eat.food.aliases).join(', '),
                            // tslint:disable-next-line:max-line-length
                            description: "Nie ryzykuj, sprawd\u017A czy Tw\u00F3j " + this.eat.animal.usedName + " mo\u017Ce bezpiecznie je\u015B\u0107 " + this.eat.food.title + ". Zobacz klasyfikacj\u0119 oraz dok\u0142adny opis korzy\u015Bci i zagro\u017Ce\u0144, informacje tylko ze sprawdzionych \u017Ar\u00F3de\u0142.",
                            title: this.eat.title,
                            image: this.eat.getPhotoUrl('s480')
                        });
                        _b = this;
                        return [4 /*yield*/, Eat.getManyFromAF(this.eat.animal, undefined, undefined, 0, 6)];
                    case 2:
                        _b.others = _c.sent();
                        this.others = this.others.filter(function (eat) { return _this.eat && eat.id !== _this.eat.id; });
                        return [4 /*yield*/, timeoutPromise(500)];
                    case 3:
                        _c.sent();
                        this.loadComments();
                        return [2 /*return*/];
                }
            });
        });
    };
    return ItemComponent;
}());
export { ItemComponent };
