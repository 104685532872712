import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Location } from '@angular/common';

@Component({
	selector: 'overlay404',
	templateUrl: './overlay404.component.html',
	styleUrls: ['./overlay404.component.styl']
})
export class Overlay404Component implements OnInit {

	constructor(private location: Location) { }

	@Input()
	@HostBinding('style.top.vh')
	offsetTop = 0;

	ngOnInit() {
		console.log('ll', window.history);
	}

	back() {
		window.history.back();
	}

}
