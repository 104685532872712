/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./header.component";
import * as i4 from "../../window.service";
import * as i5 from "@angular/platform-browser";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [["image", 1]], null, 0, "img", [], [[8, "alt", 0], [8, "height", 0], [8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.eat == null) ? null : _co.eat.title); var currVal_1 = _co.h; var currVal_2 = _co.headerImage; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_HeaderComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.scrollClicked = !_co.scrollClicked) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" prze\u0142\u0105cz tryb "]))], null, null); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { cRef: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "header-container"]], [[4, "height", "px"], [4, "position", null], [4, "backgroundColor", null], [4, "backgroundImage", null]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "opac"]], [[4, "opacity", null]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(5, 0, [[1, 0], ["c", 1]], null, 0, "canvas", [], [[8, "width", 0], [8, "height", 0], [4, "height", "px"]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_2)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_5 = (!_co.preview && _co.headerImage); _ck(_v, 4, 0, currVal_5); var currVal_9 = _co.preview; _ck(_v, 7, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.h; var currVal_1 = (_co.preview ? "relative" : "fixed"); var currVal_2 = _co.foodColor; var currVal_3 = (_co.eat ? _co.eat.microPhotoStyle : "none"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = ((_co.state === 0) ? 0 : 1); _ck(_v, 2, 0, currVal_4); var currVal_6 = _co.outW; var currVal_7 = _co.h; var currVal_8 = _co.h; _ck(_v, 5, 0, currVal_6, currVal_7, currVal_8); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "magic-header", [], null, [["window", "scroll"], ["window", "deviceorientation"], ["window", "resize"], ["window", "mousemove"]], function (_v, en, $event) { var ad = true; if (("window:scroll" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onScroll($event) !== false);
        ad = (pd_0 && ad);
    } if (("window:deviceorientation" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onOrientation($event) !== false);
        ad = (pd_1 && ad);
    } if (("window:resize" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_2 && ad);
    } if (("window:mousemove" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMoueseMove($event) !== false);
        ad = (pd_3 && ad);
    } return ad; }, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵdid(1, 638976, null, 0, i3.HeaderComponent, [i4.WindowService, i5.DOCUMENT, i1.Injector, i1.NgZone], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("magic-header", i3.HeaderComponent, View_HeaderComponent_Host_0, { animalPhoto: "animalPhoto", foodPhoto: "foodPhoto", foodIcon: "foodIcon", eat: "eat", preview: "preview" }, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
