<p class="text">
	CzyMożeJeść.pl to strona stworzona z miłości do zwierząt i chęci dzielenia się
	tym co najlepsze z naszymi pupilami. Wielu opiekunów nie raz zastanawiało się,
	czy plasterek sera jest odpowiedni dla kota, albo czy podzielić się z psem
	truskawkami. Czy zwierzak może zjeść parę czekoladek, czy może zainteresują go
	banany? Dla tego własnie powstała strona CzyMożeJeść.pl - szybki i przejrzysty
	zbiór porad dostosowanych do konkretnych przypadków, oparty na wiedzy
	ekspertów.
</p>
<p>v{{ version }}, Copyright© 2019 czyMozeJesc.pl</p>
