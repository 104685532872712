<ins
	class="adsbygoogle"
	*ngIf="type === 'article'"
	style="display:block; text-align:center;"
	data-ad-layout="in-article"
	data-ad-format="fluid"
	[attr.data-ad-slot]="data"
	data-ad-client="ca-pub-6319611442758024"
></ins>

<ins
	class="adsbygoogle"
	*ngIf="type === 'display'"
	style="display:block"
	data-ad-client="ca-pub-6319611442758024"
	[attr.data-ad-slot]="data"
	data-ad-format="auto"
	data-full-width-responsive="true"
></ins>
