import { Component, OnInit } from '@angular/core';
import { listAnimation } from 'cmj/animations';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Food } from 'cmj/model/food';
import { Animal } from 'cmj/model/animal';
import { Eat } from 'cmj/model/eat';
import { MetaService } from 'cmj/meta.service';
import * as Parse from 'parse';

@Component({
	selector: 'category-selected',
	templateUrl: './category-selected.component.html',
	styleUrls: ['./category-selected.component.css'],
	animations: [listAnimation]
})
export class CategorySelectedComponent implements OnInit {
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private meta: MetaService
	) {}
	items: Eat[] | undefined;
	selected: Food | Animal | undefined;
	category: string | undefined;
	itemsReady = false;
	catAnimal = Animal.NAME;
	notFound = false;
	canLoadMore = true;
	PAGE_SIZE = 10;

	ngOnInit() {
		this.route.url.subscribe(url => this.load(url as any));
	}

	showNotFound() {
		this.meta.show404();
		this.notFound = true;
	}

	async load(url: UrlSegment[]) {
		this.category = url[0].path;
		const selectedName = url[1].path.split('-').join(' ');
		if (this.category === Animal.NAME) {
			this.selected = await Animal.getByName(selectedName);
			if (!this.selected) {
				Parse.Cloud.run('miss', { animal: selectedName });
				return this.showNotFound();
			}
			this.items = await Eat.getManyFromAF(
				this.selected,
				undefined,
				undefined,
				0,
				this.PAGE_SIZE
			);
			this.meta.setValues({
				keywords: [...this.selected.aliases].join(', '),
				description: `Sprawdź co może jeść Twój ${this.selected.usedName}.`,
				title: `Co mogą jeść ${this.selected.plural}?`,
				image: (this.selected.mainPhoto || {}).urlBig
			});
		} else if (this.category === Food.NAME) {
			this.selected = await Food.getByName(selectedName);
			if (!this.selected) {
				Parse.Cloud.run('miss', { food: selectedName });
				return this.showNotFound();
			}
			if (!(this.selected instanceof Food)) throw new Error('e');
			this.items = await Eat.getManyFromAF(
				undefined,
				this.selected,
				undefined,
				0,
				this.PAGE_SIZE
			);
			const usePlural = this.selected.title === this.selected.plural;
			const name = this.selected.plural
				? this.selected.plural
				: this.selected.singular;
			let add = 'są bezpieczne';
			if (!usePlural) {
				if (this.selected.gender === 'meski') add = 'jest bezpieczny';
				else add = 'jest bezpieczna';
			}
			const title = `Czy ${name} ${add} dla...?`;
			this.meta.setValues({
				keywords: [...this.selected.aliases].join(', '),
				description: `Sprawdź, czy możesz podawać ${
					this.selected.plural
				} swojemu...`,
				title,
				image: (this.selected.mainPhoto || {}).urlBig
			});
		}
		if (this.items && this.items.length < this.PAGE_SIZE)
			this.canLoadMore = false;
	}

	itemClicked(item: Eat) {
		this.items = [];
		setTimeout(() => {
			if (!this.selected) throw new Error('no sel');
			let path = '';
			if (this.category === 'zwierzak') {
				const eatType = item.food.eatOrDrinkText;
				path = `/czy-${this.selected.name}-może-${eatType}-${item.food.plural}`;
			} else {
				const eatType = (this.selected as Food).eatOrDrinkText;
				path = `/czy-${item.animal.name}-może-${eatType}-${
					this.selected.plural
				}`;
			}

			this.router.navigate([path]);
		}, 400);
	}

	async fireLoadMore() {
		if (!this.canLoadMore) return;
		let items;
		const itemsBefore = (this.items || []).length;
		if (this.category === Animal.NAME) {
			items = await Eat.getManyFromAF(
				this.selected,
				undefined,
				undefined,
				itemsBefore,
				this.PAGE_SIZE
			);
		} else if (this.category === Food.NAME) {
			items = await Eat.getManyFromAF(
				undefined,
				this.selected as any,
				undefined,
				itemsBefore,
				this.PAGE_SIZE
			);
		}
		if (this.items && items) this.items.push(...items);
		const itemsAfter = (this.items || []).length;
		if (itemsAfter - itemsBefore < this.PAGE_SIZE) this.canLoadMore = false;
	}
}
