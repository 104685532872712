/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./fab-button.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./fab-button.component";
var styles_FabButtonComponent = [i0.styles];
var RenderType_FabButtonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FabButtonComponent, data: {} });
export { RenderType_FabButtonComponent as RenderType_FabButtonComponent };
export function View_FabButtonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "fab-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "div", [["class", "fab-button btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 1), (_l()(), i1.ɵted(-1, null, ["strona g\u0142\u00F3wna"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "/"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_FabButtonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fab-button", [], null, null, null, View_FabButtonComponent_0, RenderType_FabButtonComponent)), i1.ɵdid(1, 114688, null, 0, i3.FabButtonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FabButtonComponentNgFactory = i1.ɵccf("fab-button", i3.FabButtonComponent, View_FabButtonComponent_Host_0, {}, {}, []);
export { FabButtonComponentNgFactory as FabButtonComponentNgFactory };
