/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./category.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../modules/card/card.component.ngfactory";
import * as i3 from "../modules/card/card.component";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./category.component";
import * as i7 from "@angular/router";
var styles_CategoryComponent = [i0.styles];
var RenderType_CategoryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CategoryComponent, data: { "animation": [{ type: 7, name: "listAnimation", definitions: [{ type: 1, expr: "* => *", animation: [{ type: 11, selector: ":leave", animation: [{ type: 12, timings: 100, animation: [{ type: 6, styles: { opacity: 1, transform: "translateY(0px)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 0, transform: "translateY(20px)" }, offset: null }, timings: "0.3s" }] }], options: { optional: true } }, { type: 11, selector: ":enter", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 12, timings: 100, animation: [{ type: 6, styles: { opacity: 0, transform: "translateY(-20px)" }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1, transform: "translateY(0px)" }, offset: null }, timings: "0.3s" }] }], options: { optional: true } }], options: null }], options: {} }] } });
export { RenderType_CategoryComponent as RenderType_CategoryComponent };
function View_CategoryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "cmj-card", [], null, [[null, "callback"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("callback" === en)) {
        var pd_0 = (_co.itemClicked(_v.context.$implicit.usedName) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_CardComponent_0, i2.RenderType_CardComponent)), i1.ɵdid(1, 114688, null, 0, i3.CardComponent, [i4.DomSanitizer], { item: [0, "item"] }, null)], function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CategoryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "list"]], [[24, "@listAnimation", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CategoryComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.items; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.items ? ((_co.items == null) ? null : _co.items.length) : 0); _ck(_v, 0, 0, currVal_0); }); }
export function View_CategoryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "category", [], null, null, null, View_CategoryComponent_0, RenderType_CategoryComponent)), i1.ɵdid(1, 114688, null, 0, i6.CategoryComponent, [i7.ActivatedRoute, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CategoryComponentNgFactory = i1.ɵccf("category", i6.CategoryComponent, View_CategoryComponent_Host_0, {}, {}, []);
export { CategoryComponentNgFactory as CategoryComponentNgFactory };
