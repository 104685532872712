import {
	CanActivate,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	Router
} from '@angular/router';
import { Observable } from 'rxjs';
import * as Parse from 'parse';
import { Injectable } from '@angular/core';

@Injectable()
export class AuthGuard implements CanActivate {
	constructor(public router: Router) {}

	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (state.url === '/panel/login') return true;
		if (Parse.User.current() === null) {
			this.router.navigate(['/panel/login']);
			return false;
		} else return true;
	}
}
