<div class="card">
	<a class="card-header" [style.backgroundImage]="bg" [routerLink]="url">
		<h3>{{ title }}</h3>
		<!-- <h4>kliknij i dowiedz się więcej</h4> -->
	</a>
	<!-- <p>
    Content of card
  </p> -->
	<div class="actions">
		<div class="preview" *ngIf="isEat">{{ preview }}</div>
		<button class="btn read">Czytaj więcej</button>
		<div (click)="share()" *ngIf="canShare" class="share">
			<svg
				class="feather feather-share-2 sc-dnqmqq jxshSx"
				xmlns="http://www.w3.org/2000/svg"
				width="42"
				height="42"
				viewBox="0 0 24 24"
				fill="none"
				stroke="currentColor"
				stroke-width="1.2"
				stroke-linecap="round"
				stroke-linejoin="round"
				aria-hidden="true"
				data-reactid="1036"
			>
				<circle cx="18" cy="5" r="3"></circle>
				<circle cx="6" cy="12" r="3"></circle>
				<circle cx="18" cy="19" r="3"></circle>
				<line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
				<line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
			</svg>
		</div>
	</div>
</div>
