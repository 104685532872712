var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import * as Parse from 'parse';
import { environment } from 'environments/environment';
import { Category } from './category';
var NAME = 'Animal';
var Animal = /** @class */ (function (_super) {
    __extends(Animal, _super);
    function Animal(params) {
        return _super.call(this, NAME, params) || this;
    }
    Object.defineProperty(Animal, "NAME", {
        get: function () {
            return this.names[environment.lang];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Animal.prototype, "title", {
        get: function () {
            if (this.get('title'))
                return this.get('title')[environment.lang];
            return this.get('name') ? this.get('name')[environment.lang] : '';
        },
        enumerable: true,
        configurable: true
    });
    Animal.names = {
        pl: 'zwierzak'
    };
    return Animal;
}(Category));
export { Animal };
Parse.Object.registerSubclass(NAME, Animal);
