var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { fromEvent as observableFromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { OnInit, ElementRef, AfterViewInit, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { Animal } from 'cmj/model/animal';
import { Food } from 'cmj/model/food';
import { Router } from '@angular/router';
import { debounce } from 'cmj/debounce.decorator';
var TitleComponent = /** @class */ (function () {
    function TitleComponent(router) {
        this.router = router;
        this.animalName = null;
        this.foodName = null;
        this.change = new EventEmitter();
        // lastAnimalChange: string | undefined;
        // lastFoodChange: string | undefined;
        this.lastOptions = null;
        this.options = [];
        this.showOverlay = false;
        this.overlayStyle = {
            top: '10px',
            bottom: undefined,
            left: '10px',
            width: '200px',
            maxHeight: '200px',
            transformOrigin: 'top left'
        };
        this.animalAnimationState = 'zero';
        this.animalFocused = false;
        this.foodAnimationState = 'zero';
        this.foodFocused = false;
    }
    TitleComponent.prototype.ngOnInit = function () {
        console.log('ONINIT', this.food);
        if (this.animal)
            this.animalText = this.animal.name;
        if (this.food)
            this.foodText = this.food.title;
        if (this.animalName)
            this.animalText = decodeURI(this.animalName);
        if (this.foodName)
            this.foodText = decodeURI(this.foodName);
    };
    TitleComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        console.log('change', this.food);
        function changed(change) {
            return change && change.currentValue !== change.previousValue;
        }
        if (changed(changes.animalName)) {
            if (changes.animalName.currentValue === Animal.NAME ||
                !changes.animalName.currentValue)
                setTimeout(function () { return (_this.animalText = ''); });
            else
                this.animalText = decodeURI(changes.animalName.currentValue);
            this.animalAnimationState =
                changes.animalName.currentValue.length > 1 ? 'auto' : 'zero';
        }
        if (changed(changes.foodName)) {
            console.log('changed foodname', changes.foodName);
            console.log('this.foodText', this.foodText);
            if (changes.foodName.currentValue === Food.NAME ||
                !changes.foodName.currentValue)
                setTimeout(function () { return (_this.foodText = ''); });
            else
                this.foodText = decodeURI(changes.foodName.currentValue);
            this.foodAnimationState =
                changes.foodName.currentValue.length > 1 ? 'auto' : 'zero';
            console.log('this.foodText2', this.foodText);
        }
        if (changed(changes.animal)) {
            if (changes.animal.currentValue)
                this.animalText = changes.animal.currentValue.usedName;
            else
                setTimeout(function () { return (_this.animalText = ''); });
        }
        if (changed(changes.food)) {
            console.log('changed food', changes.food);
            this.foodText = changes.food.currentValue
                ? changes.food.currentValue.title
                : '';
        }
    };
    TitleComponent.prototype.reset = function (type) {
        if (type === 'a') {
            this.animal = undefined;
            this.animalText = '';
        }
        else {
            this.food = undefined;
            this.foodText = '';
        }
        this.showOverlay = false;
        if (this.animal === undefined && this.food === undefined)
            this.router.navigate(['/']);
        this.change.emit({ animal: this.animal, food: this.food });
    };
    TitleComponent.prototype.onEnter = function (e, type) {
        return __awaiter(this, void 0, void 0, function () {
            var text_1, animals, clickedAnimal, text_2, food, clickedFood;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (e)
                            e.preventDefault();
                        if (!(type === 'animal')) return [3 /*break*/, 2];
                        text_1 = this.animalInput.nativeElement.innerHTML.replace(/<br>/g, '');
                        if (text_1 === '') {
                            this.reset('a');
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, Animal.getSuggestionsP(text_1)];
                    case 1:
                        animals = _a.sent();
                        clickedAnimal = void 0;
                        if (animals[0] &&
                            animals[0].aliases.find(function (alias) { return alias.indexOf(text_1) === 0; }))
                            clickedAnimal = animals[0];
                        if (!clickedAnimal)
                            clickedAnimal = animals.find(function (a) { return a.aliases.indexOf(text_1) > -1; });
                        if (clickedAnimal)
                            this.optionSelected(clickedAnimal);
                        else
                            this.animalText = '';
                        return [3 /*break*/, 4];
                    case 2:
                        if (!(type === 'food')) return [3 /*break*/, 4];
                        text_2 = this.foodInput.nativeElement.innerHTML.replace(/<br>/g, '');
                        if (text_2 === '') {
                            this.reset('f');
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, Food.getSuggestionsP(text_2)];
                    case 3:
                        food = _a.sent();
                        clickedFood = void 0;
                        if (food[0] && food[0].aliases.find(function (alias) { return alias.indexOf(text_2) === 0; }))
                            clickedFood = food[0];
                        if (!clickedFood)
                            clickedFood = food.find(function (f) { return f.aliases.indexOf(text_2) > -1; });
                        if (clickedFood)
                            this.optionSelected(clickedFood);
                        else
                            this.foodText = '';
                        _a.label = 4;
                    case 4: return [2 /*return*/, false];
                }
            });
        });
    };
    TitleComponent.prototype.optionSelected = function (option) {
        if (option instanceof Animal) {
            this.animal = option;
            if (!this.animalInput)
                throw new Error('no input');
            this.animalInput.nativeElement.innerHTML = option.title;
        }
        if (option instanceof Food) {
            this.food = option;
            if (!this.foodInput)
                throw new Error('no input');
            this.foodInput.nativeElement.innerHTML = option.title;
        }
        this.showOverlay = false;
        if (this.animal && this.food)
            this.router.navigate([
                "/czy-" + this.animal.usedName + "-mo\u017Ce-" + this.food.eatOrDrinkText + "-" + this.food.title
            ]);
        else if (this.animal)
            this.router.navigate(['/zwierzak', this.animal.usedName]);
        else if (this.food)
            this.router.navigate(['/pokarm', this.food.titleUrl]);
        this.change.emit({ animal: this.animal, food: this.food });
    };
    TitleComponent.prototype.fitOptions = function (el, l) {
        this.overlayStyle.width = Math.max(200, el.offsetWidth) + 'px';
        var bb = el.getBoundingClientRect();
        this.overlayStyle.left = bb.left + 'px';
        if ((window.innerHeight - bb.bottom > 50 && l === 1) ||
            (window.innerHeight - bb.bottom > 100 && l > 1)) {
            this.overlayStyle.top = bb.bottom + 4 + 'px';
            this.overlayStyle.bottom = undefined;
            this.overlayStyle.maxHeight = window.innerHeight - bb.bottom - 24 + 'px';
        }
        else {
            this.overlayStyle.transformOrigin = 'bottom left';
        }
    };
    TitleComponent.prototype.animalUpdate = function (objects, el, text) {
        var l = objects.length;
        if (l > 0) {
            if ('a' + text !== this.lastOptions) {
                this.options = objects.map(function (obj) {
                    var label = obj.title.toLowerCase();
                    if (text && label.indexOf(text.toLowerCase()) !== 0) {
                        label = obj.aliases.find(function (a) { return a.toLowerCase().indexOf(text.toLowerCase()) === 0; }) + " (" + label + ")";
                    }
                    return { label: label, obj: obj };
                });
                this.lastOptions = 'a' + text;
            }
            this.showOverlay = true;
            this.fitOptions(el, l);
        }
        else
            this.showOverlay = false;
    };
    TitleComponent.prototype.foodUpdate = function (objects, el, text) {
        var l = objects.length;
        if (l > 0) {
            if ('f' + text !== this.lastOptions) {
                this.options = objects.map(function (obj) {
                    var label = obj.title.toLowerCase();
                    if (text && label.indexOf(text.toLowerCase()) !== 0) {
                        label = obj.tokens.find(function (a) { return a.toLowerCase().indexOf(text.toLowerCase()) === 0; }) + " (" + label + ")";
                    }
                    return { label: label, obj: obj };
                });
                this.lastOptions = 'f' + text;
            }
            this.showOverlay = true;
            this.fitOptions(el, l);
        }
        else
            this.showOverlay = false;
    };
    TitleComponent.prototype.animalChanged = function () {
        var _this = this;
        if (!this.animalInput)
            throw new Error('no element');
        var el = this.animalInput.nativeElement;
        var text = el.innerHTML.replace(/<br>/, '');
        if (!this.animal || this.animal.name !== text) {
            Animal.getSuggestions(text).subscribe(function (opts) {
                return _this.animalUpdate(opts, el, text);
            });
        }
    };
    TitleComponent.prototype.foodChanged = function () {
        var _this = this;
        if (!this.foodInput)
            throw new Error('no element');
        var el = this.foodInput.nativeElement;
        var text = el.innerHTML.replace(/<br>/, '');
        if (!this.food || this.food.name !== text) {
            Food.getSuggestions(text, true).subscribe(function (opts) {
                return _this.foodUpdate(opts, el, text);
            });
        }
    };
    TitleComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (!this.animalInput || !this.foodInput)
            throw new Error('no element');
        observableFromEvent(this.animalInput.nativeElement, 'input')
            .pipe(debounceTime(400))
            .subscribe(function () { return _this.animalChanged(); });
        observableFromEvent(this.foodInput.nativeElement, 'input')
            .pipe(debounceTime(400))
            .subscribe(function () { return _this.foodChanged(); });
    };
    TitleComponent.prototype.focusedAnimal = function () {
        this.animalFocused = true;
        // console.log('focusedAnimal',this.animalInput.nativeElement.innerHTML,this.animalInput.nativeElement.innerHTML === '')
        if (!this.animalInput)
            throw new Error('no element');
        if (this.animalInput.nativeElement.innerHTML === '')
            this.animalChanged();
    };
    TitleComponent.prototype.blurred = function (type) {
        // console.log('focusedAnimal',this.animalInput.nativeElement.innerHTML,this.animalInput.nativeElement.innerHTML === '')
        if (type === 'animal')
            this.animalFocused = false;
        if (type === 'food')
            this.foodFocused = false;
        this.onEnter(null, type);
    };
    TitleComponent.prototype.focusedFood = function () {
        this.foodFocused = true;
        if (!this.foodInput)
            throw new Error('no element');
        if (this.foodInput.nativeElement.innerHTML === '')
            this.foodChanged();
    };
    __decorate([
        debounce(200),
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], TitleComponent.prototype, "blurred", null);
    return TitleComponent;
}());
export { TitleComponent };
