var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Parse from 'parse';
import { environment } from 'environments/environment';
import { Category } from './category';
var NAME = 'Food';
var Food = /** @class */ (function (_super) {
    __extends(Food, _super);
    function Food(params) {
        var _this = _super.call(this, NAME, params) || this;
        _this._icon = { png: undefined };
        _this.tmpIconPNG = null;
        return _this;
    }
    Object.defineProperty(Food, "NAME", {
        get: function () {
            return this.names[environment.lang];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Food.prototype, "icon", {
        get: function () {
            Object.assign(this._icon, this.get('icon') || {}, {
                png: this.get('iconPNG') && this.get('iconPNG').url()
            });
            return this._icon;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Food.prototype, "iconPNG", {
        get: function () {
            if (this.tmpIconPNG)
                return this.tmpIconPNG;
            return (this.get('iconPNG') &&
                this.get('iconPNG')
                    .url()
                    .replace('http://', 'https://'));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Food.prototype, "drink", {
        get: function () {
            return this.get('drink') || false;
        },
        set: function (isDrink) {
            this.set('drink', isDrink);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Food.prototype, "gender", {
        get: function () {
            return this.get('gender');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Food.prototype, "eatOrDrinkText", {
        get: function () {
            return Food.eatWords[environment.lang][this.drink ? 1 : 0];
        },
        enumerable: true,
        configurable: true
    });
    Food.prototype.gotRawIcon = function () {
        return this.get('icon');
    };
    Food.prototype.gotPNG = function () {
        return (this.tmpIconPNG || this.get('iconPNG')) != null;
    };
    Food.prototype.iconDownloadPNG = function () {
        return Parse.Cloud.run('iconDownloadPNG', { id: this.id });
    };
    Object.defineProperty(Food.prototype, "summaryLinePrev", {
        get: function () {
            var foodPart = '';
            if (this.plural === this.title) {
                console.log('aaa1');
                foodPart = this.plural;
                var safety = Food.genderSafetyWords['nijaki']['safe'];
                return foodPart + " s\u0105 <span>" + safety + "</span>";
            }
            else {
                console.log('bbb1');
                foodPart = this.singular;
                var safety = Food.genderSafetyWords[this.get('gender') || 'meski']['safe'];
                return foodPart + " jest <span>" + safety + "</span>";
            }
        },
        enumerable: true,
        configurable: true
    });
    Food.prototype.addIcon = function (icon) {
        return __awaiter(this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.set('icon', icon);
                        return [4 /*yield*/, this.save()];
                    case 1:
                        _b.sent();
                        _a = this;
                        return [4 /*yield*/, Promise.resolve(this.iconDownloadPNG())];
                    case 2:
                        _a.tmpIconPNG = _b.sent();
                        return [2 /*return*/, this];
                }
            });
        });
    };
    Food.create = function (name) {
        var tmp = new Food();
        var n = {};
        n[environment.lang] = name;
        var p = {};
        var a = {};
        a[environment.lang] = [name];
        tmp.set('name', n);
        tmp.set('plural', p);
        tmp.set('aliases', a);
        return tmp.save();
    };
    Object.defineProperty(Food.prototype, "title", {
        get: function () {
            if (this.get('title'))
                return this.get('title')[environment.lang];
            if (this.get('plural') && this.get('plural')[environment.lang])
                return this.get('plural')[environment.lang];
            return this.get('name') ? this.get('name')[environment.lang] : '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Food.prototype, "titleUrl", {
        get: function () {
            return this.title
                .toLowerCase()
                .split(' ')
                .join('-');
        },
        enumerable: true,
        configurable: true
    });
    Food.genderSafetyWords = {
        meski: {
            safe: 'bezpieczny',
            ok: 'dopuszczalny',
            nok: 'niewskazany',
            bad: 'szkodliwy'
        },
        zenski: {
            safe: 'bezpieczna',
            ok: 'dopuszczalna',
            nok: 'niewskazana',
            bad: 'szkodliwa'
        },
        nijaki: {
            safe: 'bezpieczne',
            ok: 'dopuszczalne',
            nok: 'niewskazane',
            bad: 'szkodliwe'
        }
    };
    Food.eatWords = {
        pl: ['jeść', 'pić']
    };
    Food.names = {
        pl: 'pokarm'
    };
    return Food;
}(Category));
export { Food };
Parse.Object.registerSubclass(NAME, Food);
