<h1>
	Czy
	<!-- <span [(ngModel)]="animal" ngDefaultControl (focus)="focused(1)" class="edit-me" spellcheck="false" contenteditable="true">{{animal}}</span> -->
	<span
		#animalInput
		[@inputAnim]="animalFocused ? 'auto' : animalAnimationState"
		ngDefaultControl
		(focus)="focusedAnimal()"
		(blur)="blurred('animal')"
		(keyup.enter)="onEnter($event, 'animal')"
		class="edit-me"
		spellcheck="false"
		[textContent]="animalText"
		contenteditable="true"
		>{{ animalText }}</span
	>
	może {{ this.food?.drink ? 'pić' : 'jeść' }}
	<span class="no-break"
		><span
			#foodInput
			[@inputAnim]="foodFocused ? 'auto' : foodAnimationState"
			ngDefaultControl
			(focus)="focusedFood()"
			(blur)="blurred('food')"
			(keyup.enter)="onEnter($event, 'food')"
			class="edit-me"
			spellcheck="false"
			[textContent]="foodText"
			contenteditable="true"
			>{{ foodText }}</span
		>&nbsp;?</span
	>
</h1>
<!-- (wheel)="preventDefault($event)"  -->
<div class="overlay">
	<div
		*ngIf="showOverlay"
		appNoscroll
		[ov]="showOverlay"
		(click)="showOverlay = false"
		class="overlay-bg"
	></div>
	<ul
		*ngIf="showOverlay"
		appNoscroll
		[ov]="showOverlay"
		@optionsAnim
		[ngStyle]="overlayStyle"
		class="overlay-options"
	>
		<li *ngFor="let o of options" (click)="optionSelected(o.obj)">
			{{ o.label }}
		</li>
	</ul>
</div>
