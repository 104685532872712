import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MainComponent } from './main/main.component';

import { AuthGuard } from './panel/auth.guard';
import { ItemComponent } from './item/item.component';
import { LandingComponent } from './landing/landing.component';
import { CategoryComponent } from './category/category.component';
import { CategorySelectedComponent } from './category-selected/category-selected.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

export function matchSentence(url: UrlSegment[]) {
	console.log('MATCHING...', url[0].path);
	const regex = /^czy-([A-Za-zĄ-žóÓ]+)-(?:mo[żz]e|mog[aą])-z?je[sś][cć]-([A-Za-zĄ-žóÓ\-]+)\??$/gm;
	const regex2 = /^czy-([A-Za-zĄ-žóÓ]+)-(?:mo[żz]e|mog[aą])-pi[cć]-([A-Za-zĄ-žóÓ\-]+)\??$/gm;
	let found = regex.exec(url[0].path);
	if (!found) found = regex2.exec(url[0].path);

	if (!found || !found.length) return { consumed: [] };
	return {
		consumed: url,
		posParams: {
			animal: new UrlSegment(found[1], {}),
			food: new UrlSegment(found[2], {})
		}
	};
}

export const routes: Routes = [
	{
		path: 'panel',
		loadChildren: './panel/panel.module#PanelModule'
	},
	{
		path: '',
		component: MainComponent,
		children: [
			{
				path: '',
				component: HomeComponent,
				children: [
					{ path: '', pathMatch: 'full', component: LandingComponent },
					{ path: 'zwierzak', component: CategoryComponent },
					{ path: 'zwierzak/:name', component: CategorySelectedComponent },
					{ path: 'pokarm', component: CategoryComponent },
					{ path: 'pokarm/:name', component: CategorySelectedComponent }
				]
			},
			{ matcher: matchSentence, component: ItemComponent },
			{ path: '**', component: PageNotFoundComponent }
		]
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabled',
			enableTracing: false,
			scrollPositionRestoration: 'enabled'
		})
	],
	exports: [RouterModule],
	providers: [AuthGuard]
})
export class AppRoutingModule {}
