import { Component, OnInit } from "@angular/core";
import { Eat } from "cmj/model/eat";
import { Animal } from "cmj/model/animal";
import { Food } from "cmj/model/food";

@Component({
	selector: "landing",
	templateUrl: "./landing.component.html",
	styleUrls: ["./landing.component.styl"]
})
export class LandingComponent implements OnInit {
	eats: Promise<Eat[]>;
	animals: Promise<Animal[]>;
	foods: Promise<Food[]>;

	animalCategoryName = Animal.NAME;
	foodCategoryName = Food.NAME;

	constructor() {
		this.eats = Eat.get5();
		this.animals = Animal.get5();
		this.foods = Food.get5();
	}

	ngOnInit() {}
}
