import { Component, AfterViewInit, Input } from '@angular/core';

@Component({
	selector: 'adsensearticle',
	templateUrl: './adsensearticle.component.html',
	styleUrls: ['./adsensearticle.component.styl']
})
export class AdsensearticleComponent implements AfterViewInit {
	@Input() data = '';
	@Input() type: 'display' | 'article' = 'display';

	constructor() {}

	ngAfterViewInit() {
		setTimeout(() => {
			try {
				((window as any)['adsbygoogle'] =
					(window as any)['adsbygoogle'] || []).push({});
			} catch (e) {
				console.error(e);
			}
		}, 2000);
	}
}
