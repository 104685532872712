/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./title.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./noscroll.directive";
import * as i3 from "@angular/common";
import * as i4 from "@angular/forms";
import * as i5 from "./title.component";
import * as i6 from "@angular/router";
var styles_TitleComponent = [i0.styles];
var RenderType_TitleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TitleComponent, data: { "animation": [{ type: 7, name: "optionsAnim", definitions: [{ type: 1, expr: ":enter", animation: [{ type: 6, styles: { opacity: 0, transform: "scale(0.5)" }, offset: null }, { type: 4, styles: null, timings: 150 }], options: null }, { type: 1, expr: ":leave", animation: [{ type: 4, styles: { type: 6, styles: { opacity: 0, transform: "scale(0.5)" }, offset: null }, timings: 150 }], options: null }], options: {} }, { type: 7, name: "inputAnim", definitions: [{ type: 0, name: "auto", styles: { type: 6, styles: { width: "*" }, offset: null }, options: undefined }, { type: 0, name: "zero", styles: { type: 6, styles: { width: "4vw" }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: { type: 4, styles: null, timings: "300ms ease-in-out" }, options: null }], options: {} }] } });
export { RenderType_TitleComponent as RenderType_TitleComponent };
function View_TitleComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["appNoscroll", ""], ["class", "overlay-bg"]], null, [[null, "click"], [null, "wheel"], [null, "touchmove"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("wheel" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onWheel($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchmove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouchMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onTouchStart($event) !== false);
        ad = (pd_2 && ad);
    } if (("click" === en)) {
        var pd_3 = ((_co.showOverlay = false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.NoscrollDirective, [], { ov: [0, "ov"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showOverlay; _ck(_v, 1, 0, currVal_0); }, null); }
function View_TitleComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.optionSelected(_v.context.$implicit.obj) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, [" ", " "]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.label; _ck(_v, 1, 0, currVal_0); }); }
function View_TitleComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["appNoscroll", ""], ["class", "overlay-options"]], [[24, "@optionsAnim", 0]], [[null, "wheel"], [null, "touchmove"], [null, "touchstart"]], function (_v, en, $event) { var ad = true; if (("wheel" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onWheel($event) !== false);
        ad = (pd_0 && ad);
    } if (("touchmove" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onTouchMove($event) !== false);
        ad = (pd_1 && ad);
    } if (("touchstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onTouchStart($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 278528, null, 0, i3.NgStyle, [i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngStyle: [0, "ngStyle"] }, null), i1.ɵdid(2, 16384, null, 0, i2.NoscrollDirective, [], { ov: [0, "ov"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleComponent_3)), i1.ɵdid(4, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.overlayStyle; _ck(_v, 1, 0, currVal_1); var currVal_2 = _co.showOverlay; _ck(_v, 2, 0, currVal_2); var currVal_3 = _co.options; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = undefined; _ck(_v, 0, 0, currVal_0); }); }
export function View_TitleComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { animalInput: 0 }), i1.ɵqud(402653184, 2, { foodInput: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 12, "h1", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Czy "])), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["animalInput", 1]], null, 3, "span", [["class", "edit-me"], ["contenteditable", "true"], ["ngDefaultControl", ""], ["spellcheck", "false"]], [[24, "@inputAnim", 0], [8, "textContent", 0]], [[null, "focus"], [null, "blur"], [null, "keyup.enter"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.focusedAnimal() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.blurred("animal") !== false);
        ad = (pd_5 && ad);
    } if (("keyup.enter" === en)) {
        var pd_6 = (_co.onEnter($event, "animal") !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(6, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(8, null, [" mo\u017Ce ", " "])), (_l()(), i1.ɵeld(9, 0, null, null, 5, "span", [["class", "no-break"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, [[2, 0], ["foodInput", 1]], null, 3, "span", [["class", "edit-me"], ["contenteditable", "true"], ["ngDefaultControl", ""], ["spellcheck", "false"]], [[24, "@inputAnim", 0], [8, "textContent", 0]], [[null, "focus"], [null, "blur"], [null, "keyup.enter"], [null, "input"], [null, "compositionstart"], [null, "compositionend"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.focusedFood() !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.blurred("food") !== false);
        ad = (pd_5 && ad);
    } if (("keyup.enter" === en)) {
        var pd_6 = (_co.onEnter($event, "food") !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i1.ɵprd(5120, null, i4.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.DefaultValueAccessor]), i1.ɵdid(12, 16384, null, 0, i4.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i4.COMPOSITION_BUFFER_MODE]], null, null), (_l()(), i1.ɵted(13, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\u00A0?"])), (_l()(), i1.ɵeld(15, 0, null, null, 4, "div", [["class", "overlay"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleComponent_1)), i1.ɵdid(17, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TitleComponent_2)), i1.ɵdid(19, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.showOverlay; _ck(_v, 17, 0, currVal_7); var currVal_8 = _co.showOverlay; _ck(_v, 19, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.animalFocused ? "auto" : _co.animalAnimationState); var currVal_1 = _co.animalText; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.animalText; _ck(_v, 7, 0, currVal_2); var currVal_3 = (((_co.food == null) ? null : _co.food.drink) ? "pi\u0107" : "je\u015B\u0107"); _ck(_v, 8, 0, currVal_3); var currVal_4 = (_co.foodFocused ? "auto" : _co.foodAnimationState); var currVal_5 = _co.foodText; _ck(_v, 10, 0, currVal_4, currVal_5); var currVal_6 = _co.foodText; _ck(_v, 13, 0, currVal_6); }); }
export function View_TitleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-title", [], null, null, null, View_TitleComponent_0, RenderType_TitleComponent)), i1.ɵdid(1, 4833280, null, 0, i5.TitleComponent, [i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TitleComponentNgFactory = i1.ɵccf("app-title", i5.TitleComponent, View_TitleComponent_Host_0, { animal: "animal", food: "food", animalName: "animalName", foodName: "foodName" }, { change: "change" }, []);
export { TitleComponentNgFactory as TitleComponentNgFactory };
