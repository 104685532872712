/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./home.component.styl.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../title/title.component.ngfactory";
import * as i4 from "../title/title.component";
import * as i5 from "@angular/router";
import * as i6 from "./home.component";
var styles_HomeComponent = [i0.styles];
var RenderType_HomeComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HomeComponent, data: {} });
export { RenderType_HomeComponent as RenderType_HomeComponent };
export function View_HomeComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "main"]], [[4, "height", "px"]], null, null, null, null)), i1.ɵdid(1, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(2, { category: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "bg-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 7, "div", [["class", "container"]], null, null, null, null, null)), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { category: 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 1, "app-title", [], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.titleChanged($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_TitleComponent_0, i3.RenderType_TitleComponent)), i1.ɵdid(9, 4833280, null, 0, i4.TitleComponent, [i5.Router], { animal: [0, "animal"], food: [1, "food"], animalName: [2, "animalName"], foodName: [3, "foodName"] }, { change: "change" }), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "subtitle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Porady \u017Cywieniowe dla twojego pupila"])), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "section-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(15, 212992, null, 0, i5.RouterOutlet, [i5.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "main"; var currVal_2 = _ck(_v, 2, 0, (_co.animal || _co.food)); _ck(_v, 1, 0, currVal_1, currVal_2); var currVal_3 = "container"; var currVal_4 = _ck(_v, 7, 0, (_co.animal || _co.food)); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.animal; var currVal_6 = _co.food; var currVal_7 = _co.animalName; var currVal_8 = _co.foodName; _ck(_v, 9, 0, currVal_5, currVal_6, currVal_7, currVal_8); _ck(_v, 15, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.height + _co.width) * ((_co.animal || _co.food) ? 0.15 : 0.3)); _ck(_v, 0, 0, currVal_0); }); }
export function View_HomeComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-home", [], null, null, null, View_HomeComponent_0, RenderType_HomeComponent)), i1.ɵdid(1, 114688, null, 0, i6.HomeComponent, [i2.Location, i5.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HomeComponentNgFactory = i1.ɵccf("app-home", i6.HomeComponent, View_HomeComponent_Host_0, {}, {}, []);
export { HomeComponentNgFactory as HomeComponentNgFactory };
