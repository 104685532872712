var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as Parse from "parse";
import { Helpers } from "cmj/helpers";
// import { Helpers } from 'cmj/helpers';
// const Parse = require('parse');
// import Vibrant = require('node-vibrant');
var Vibrant = require("node-vibrant");
// declare const Vibrant: any;
var NAME = "Photo";
var Photo = /** @class */ (function (_super) {
    __extends(Photo, _super);
    function Photo(options) {
        var _this = _super.call(this, NAME, options) || this;
        _this.showing = false;
        return _this;
    }
    Object.defineProperty(Photo.prototype, "color", {
        get: function () {
            return this.get("color");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, "micro", {
        get: function () {
            return this.get("micro");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, "file", {
        get: function () {
            return this.get("file");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, "url", {
        get: function () {
            var file = this.get("file");
            if (!file)
                throw new Error("no file");
            return file.url().replace("http://", "https://");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, "urlBig", {
        get: function () {
            var file = this.get("big");
            if (!file) {
                console.error("no photo file - bigURL");
                return Photo.errorPixel;
            }
            return file.url().replace("http://", "https://");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, "urlMini", {
        get: function () {
            var file = this.get("mini");
            if (!file)
                throw new Error("no file " + this.id);
            return file.url().replace("http://", "https://");
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Photo.prototype, "flipped", {
        get: function () {
            return this.get("flipped");
        },
        set: function (flipped) {
            this.set("flipped", flipped);
        },
        enumerable: true,
        configurable: true
    });
    Photo.create = function (name, base64, object) {
        return __awaiter(this, void 0, void 0, function () {
            var photo, resizedImageBig, parseFileBig, resizedImage, parseFile, palette, resizedImageMini, parseFileMini, micro;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        photo = new Photo();
                        return [4 /*yield*/, Helpers.createResized(base64, Photo.BIG_SIZE)];
                    case 1:
                        resizedImageBig = _a.sent();
                        parseFileBig = new Parse.File(name, { base64: resizedImageBig });
                        photo.set("big", parseFileBig);
                        return [4 /*yield*/, Helpers.createResized(base64, Photo.SIZE)];
                    case 2:
                        resizedImage = _a.sent();
                        parseFile = new Parse.File(name, { base64: resizedImage });
                        photo.set("file", parseFile);
                        return [4 /*yield*/, Vibrant.from(base64).getPalette()];
                    case 3:
                        palette = _a.sent();
                        // console.log(palette.Vibrant.getHex());
                        photo.set("color", palette.Vibrant && palette.Vibrant.getHex());
                        return [4 /*yield*/, Helpers.createResized(base64, Photo.MINI_SIZE)];
                    case 4:
                        resizedImageMini = _a.sent();
                        parseFileMini = new Parse.File(name, { base64: resizedImageMini });
                        photo.set("mini", parseFileMini);
                        return [4 /*yield*/, Helpers.createResized(base64, Photo.MICRO_SIZE)];
                    case 5:
                        micro = _a.sent();
                        photo.set("micro", micro);
                        if (object.className === "Animal")
                            photo.set("animal", object);
                        else if (object.className === "Food")
                            photo.set("food", object);
                        else
                            throw new Error("DDDDD");
                        return [4 /*yield*/, photo.save()];
                    case 6:
                        _a.sent();
                        return [2 /*return*/, photo];
                }
            });
        });
    };
    Photo.prototype.flip = function () {
        this.set("flipped", !this.flipped);
    };
    Photo.prototype.recreateThumbs = function (name) {
        return __awaiter(this, void 0, void 0, function () {
            var resizedImageMini, parseFileMini, micro;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        name = Helpers.removeDiacritics(name) + ".jpg";
                        return [4 /*yield*/, Helpers.createResized(this.url, Photo.MINI_SIZE)];
                    case 1:
                        resizedImageMini = _a.sent();
                        parseFileMini = new Parse.File(name, { base64: resizedImageMini });
                        this.set("mini", parseFileMini);
                        return [4 /*yield*/, Helpers.createResized(this.url, Photo.MICRO_SIZE)];
                    case 2:
                        micro = _a.sent();
                        this.set("micro", micro);
                        return [4 /*yield*/, this.save()];
                    case 3:
                        _a.sent();
                        return [2 /*return*/, true];
                }
            });
        });
    };
    Photo.MICRO_SIZE = 12;
    Photo.MINI_SIZE = 480;
    Photo.SIZE = 640;
    Photo.BIG_SIZE = 1000;
    Photo.errorPixel = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z8BQDwAEhQGAhKmMIQAAAABJRU5ErkJggg==";
    return Photo;
}(Parse.Object));
export { Photo };
Parse.Object.registerSubclass(NAME, Photo);
