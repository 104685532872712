import {
	Component,
	OnInit,
	Input,
	ViewChild,
	HostBinding,
	ElementRef,
	OnChanges,
	HostListener,
	Injector,
	NgZone
} from '@angular/core';
import { Photo } from 'cmj/model/photo';
import { DOCUMENT } from '@angular/platform-browser';
import { WindowService } from 'cmj/window.service';
import { Inject } from '@angular/core';
import { Eat } from 'cmj/model/eat';
import { debounce } from 'cmj/debounce.decorator';
import { MissingService } from 'cmj/panel/missing.service';

enum States {
	Initializing,
	Ready,
	Opening,
	Open,
	Closing
}

@Component({
	selector: 'magic-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit, OnChanges {
	readonly SIZES = [320, 480, 768, 992, 1200, 1680, 1920].reverse();

	@Input() readonly animalPhoto: Photo | undefined;
	@Input() readonly foodPhoto: Photo | undefined;
	@Input() readonly foodIcon: string | undefined;
	headerImage: string | undefined;
	srcSet: string | undefined;
	srcSetSizes: string | undefined;
	drawRunning = false;

	@Input() readonly eat: Eat | undefined;

	@Input() readonly preview: boolean | undefined;

	get animalPhotoOut(): Photo | undefined {
		return this.animalPhoto || (this.eat && this.eat.animalPhoto);
	}

	get foodPhotoOut(): Photo | undefined {
		return this.foodPhoto || (this.eat && this.eat.foodPhoto);
	}

	get foodIconOut(): string | undefined {
		return (
			this.foodIcon || (this.eat && this.eat.food && this.eat.food.iconPNG)
		);
	}

	state: States = States.Initializing;

	// @HostBinding('style.background-color')
	get foodColor() {
		return this.foodPhotoOut ? this.foodPhotoOut.color : '#999';
	}

	@ViewChild('c') cRef: ElementRef | undefined;
	c: HTMLCanvasElement | undefined;
	ctx: CanvasRenderingContext2D | null = null;

	rawW: number | undefined;
	// @HostBinding('style.width.px') outW: number | undefined;
	outW: number | undefined;
	// @HostBinding('style.height.px') h = 360;
	h = 360;
	opening = 0;

	scroll = 0;
	mouseX: number | null = null;
	mouseY: number | null = null;

	initialDevGamma: number | undefined;
	initialDevBeta: number | undefined;
	devGamma: number | undefined;
	devBeta: number | undefined;
	// @HostBinding('style.background-color') foodColorBG = this.foodColor;

	maskedLeft: HTMLCanvasElement | undefined;
	maskedRight: HTMLCanvasElement | undefined;

	iconGrid: [number, number][] = [];
	iconImg: HTMLImageElement | null = null;

	scrollClicked = false;
	private missing: MissingService | undefined;

	constructor(
		private windowRef: WindowService,
		@Inject(DOCUMENT) private doc: Document,
		// private missing: MissingService
		private injector: Injector,
		public ngZone: NgZone
	) {
		if (window.location.pathname.indexOf('/panel') === 0) {
			this.missing = <MissingService>this.injector.get(MissingService);
		}
	}

	private getOutW(w: number) {
		if (w > this.SIZES[0]) return this.SIZES[0];
		let found = 0;
		this.SIZES.forEach(s => {
			if (w <= s) found = s;
		});
		return found;
	}

	@HostListener('window:scroll', ['$event'])
	onScroll(e: any) {
		this.scroll = e.path[1].scrollY;
	}

	@HostListener('window:deviceorientation', ['$event'])
	onOrientation(e: any) {
		if (!this.initialDevGamma) this.initialDevGamma = e.gamma;
		if (!this.initialDevBeta) this.initialDevBeta = e.beta;
		this.devGamma = e.gamma;
		this.devBeta = e.beta;
	}

	@HostListener('window:resize', ['$event'])
	@debounce()
	onResize(e: any) {
		if (this.outW !== this.getOutW(window.innerWidth)) {
			this.sizeInit();
			this.redraw();
		}
	}

	@HostListener('window:mousemove', ['$event'])
	onMoueseMove(e: any) {
		this.mouseX = e.x;
		this.mouseY = e.y;
	}

	// @HostListener('window:resize', ['$event'])
	// onEveryResize(e: any) {
	// 	console.log('resizingXXX...');

	// }

	ngOnInit() {
		if (!this.cRef) throw new Error('no element');
		this.c = this.cRef.nativeElement;
		this.sizeInit();
	}

	sizeInit() {
		const window = this.windowRef.nativeWindow;
		this.rawW = window.innerWidth;
		this.outW = this.getOutW(window.innerWidth);
		this.adjustHeight();

		this.scroll = window.scrollY;
	}

	ngOnChanges(changes: any): void {
		this.redraw();
	}

	adjustHeight() {
		if (this.outW && this.outW <= 320) this.h = 180;
		else if (this.outW && this.outW <= 480) this.h = 260;
		else this.h = 360;
	}

	redraw() {
		this.opening = 0;
		this.state = States.Initializing;
		this.headerImage = undefined;
		setTimeout(() => this.initDraw());
	}

	private makeCanvasForText(w = this.outW) {
		if (!w) throw new Error('no w');
		const c = this.doc.createElement('canvas');
		c.height = this.h;
		c.width = w;
		const ctx = c.getContext('2d');
		if (!ctx) throw new Error('no ctx');
		ctx.fillStyle = '#000000';
		ctx.font = 2 * this.h + 'px Arial';
		ctx.textAlign = 'center';
		return { c, ctx };
	}

	private checkChangeH(c0: HTMLCanvasElement) {
		const c = this.doc.createElement('canvas');
		c.height = c0.height / 2;
		c.width = c0.width / 2;
		const ctx = c.getContext('2d');
		if (!ctx) throw new Error('no ctx');

		// this.logImage(c0);

		ctx.drawImage(
			c0,
			0,
			0,
			c0.width,
			c0.height,
			0,
			0,
			c0.width / 2,
			c0.height / 2
		);

		const imgData = ctx.getImageData(0, 0, c.width, c.height);
		const data = imgData.data;
		// this.logImage(c0);

		// this.logImage(c);
		let found = 0;
		let found2 = 9999;
		let hChecked = null;
		for (let i = 0; i < data.length; i += 4) {
			const pixel = i / 4;
			const w = pixel % c.width;
			const h = (pixel - w) / c.width;
			if (h === hChecked) continue;
			// console.log('wh', w, h);
			const alpha = data[i + 3];
			// console.log('aa', alpha);
			if (alpha === 0) {
				hChecked = h;
				found = Math.max(found, w);
			}
			if (alpha < 255) {
				found2 = Math.min(found2, w);
			}
		}
		return [found * 2 * 1.05, found2 * 2 * 0.95];
	}

	private makeMasks(): Promise<{
		l: HTMLCanvasElement;
		r: HTMLCanvasElement;
		p: number;
		p2: number;
	}> {
		return new Promise((resolve, reject) => {
			if (!this.outW) throw new Error('no out-w');

			const { c: c1h, ctx: ctx1h } = this.makeCanvasForText(this.outW / 2);
			ctx1h.fillText('?', 0, 1.4 * this.h);

			const { c: c1, ctx: ctx1 } = this.makeCanvasForText();
			ctx1.fillText('?', this.outW / 2, 1.4 * this.h);
			for (let i = 0; i < 95; i++) {
				ctx1.drawImage(c1h, this.outW / 2 + i * (this.h * 0.05), 0);
			}

			const { c: c2, ctx: ctx2 } = this.makeCanvasForText();
			for (let i = 0; i < 65; i++) {
				ctx2.fillText('?', this.outW / 2 - i * (this.h * 0.05), 1.4 * this.h);
			}

			const points = this.checkChangeH(c2);

			resolve({ l: c1, r: c2, p: points[0], p2: points[1] });
		});
	}

	loadIcon() {
		return new Promise((resolve, reject) => {
			const img = new Image();
			this.iconImg = img;
			img.crossOrigin = 'Anonymous';
			const loaded = () => resolve();
			img.onload = loaded;
			img.crossOrigin = 'anonymous';
			if (!this.foodIconOut)
				return this.missing && this.missing.newMiss('icon');
			img.src = this.foodIconOut;
		});
	}

	loadImage(
		photo: Photo,
		side: 'l' | 'r',
		point: number,
		point2: number
	): Promise<HTMLCanvasElement> {
		return new Promise((resolve, reject) => {
			const img = new Image();
			img.crossOrigin = 'Anonymous';
			const loaded = () => {
				const c = this.doc.createElement('canvas');
				c.height = this.h;
				if (!this.outW) throw new Error('no out-w');
				c.width = this.outW;
				const ctx = c.getContext('2d');
				if (!ctx) throw new Error('no ctx');
				let targetW = side === 'l' ? point : this.outW - point2;
				let targetH = targetW / (img.width / img.height);
				let pullLeft = 0;

				if (targetH < this.h) {
					const prevH = targetH;
					const prevW = targetW;
					targetH = this.h;
					targetW = targetW / (prevH / targetH);
					pullLeft = prevW - targetW;
				}

				ctx.save();
				if (photo.flipped) {
					ctx.translate(targetW, 0);
					ctx.scale(-1, 1);
					pullLeft = pullLeft * -1;
				}
				const outX = (side === 'l' ? 0 : point2) + pullLeft / 2;
				const outY = (targetH - this.h) / -2;

				ctx.drawImage(img, outX, outY, targetW, targetH);
				ctx.restore();
				// this.logImage(c);
				resolve(c);
			};
			img.onload = loaded;
			img.src = this.outW && this.outW >= 1200 ? photo.urlBig : photo.url;
		});
	}

	logImage(c: HTMLCanvasElement, name?: string) {
		if (name) console.log('Image ' + name + ' :');
		console.log(
			`%c       `,
			`
			font-size: 100px;
			background-image: url(${c.toDataURL()});
			background-repeat: no-repeat;
			background-size: contain;
		`
		);
	}

	maskImages() {
		let leftMask: HTMLCanvasElement | undefined;
		let rightMask: HTMLCanvasElement | undefined;
		let point: number | null = null;
		let point2: number | null = null;
		return this.makeMasks()
			.then(({ l, r, p, p2 }) => {
				leftMask = l;
				rightMask = r;
				point = p;
				point2 = p2;
				// this.logImage(leftMask, 'leftMask');
				// this.logImage(rightMask, 'rightMask');

				if (!this.animalPhotoOut) throw new Error('no this.animalPhoto');
				if (!this.foodPhotoOut) throw new Error('no this.foodPhoto');

				return Promise.all([
					this.loadImage(this.animalPhotoOut, 'l', point, point2),
					this.loadImage(this.foodPhotoOut, 'r', point, point2)
				]);
			})
			.then(canvses => {
				const [animalImageCanvas, foodImageCanvas] = canvses;
				const ctxA = animalImageCanvas.getContext('2d');
				if (!ctxA) throw new Error('canvas context creation failed');
				ctxA.save();
				ctxA.globalCompositeOperation = 'destination-out';
				if (!leftMask) throw new Error('no left');
				ctxA.drawImage(leftMask, 0, 0);
				ctxA.restore();
				this.maskedLeft = animalImageCanvas;
				// }).then(foodImageCanvas => {
				const ctxF = foodImageCanvas.getContext('2d');
				if (!ctxF) throw new Error('canvas context creation failed');
				ctxF.save();
				ctxF.globalCompositeOperation = 'destination-out';
				if (!rightMask) throw new Error('no right');
				ctxF.drawImage(rightMask, 0, 0);
				ctxF.restore();
				this.maskedRight = foodImageCanvas;

				if (!this.ctx || !this.foodColor || !this.outW)
					throw new Error('no context');

				this.ctx.fillStyle = this.foodColor;
				this.ctx.fillRect(0, 0, this.outW, this.h);

				// this.logImage(this.maskedRight);
				this.ctx.drawImage(foodImageCanvas, 0, 0);
				if (!this.maskedLeft) throw new Error('no this.maskedLeft');
				// this.logImage(this.maskedLeft, 'this.maskedLeft');

				this.ctx.drawImage(animalImageCanvas, 0, 0);

				// this.logImage(this.c!);

				this.state = States.Ready;
				// alert('aaa');
				// console.log('%c       ', 'font-size: 100px; background: url(' + this.c.toDataURL() + ') no-repeat;');
			});
	}

	async initDraw() {
		if (!this.c) return false;
		this.ctx = this.ctx || this.c.getContext('2d');
		if (!this.ctx || !this.outW) return false;
		this.ctx.fillStyle = this.foodColor;
		this.ctx.fillRect(0, 0, this.outW, this.h);
		// if (this.eat) {

		// 	const tmpSizes = [...this.SIZES].reverse();
		// 	this.srcSet = tmpSizes.map(s => {
		// 		if (!this.eat) throw new Error('impossibiru');
		// 		return this.eat.getPhotoUrl('s' + s) + ' ' + s + 'w';
		// 	}).join(', ');

		// 	this.srcSetSizes = tmpSizes.map(s => {
		// 		return `(max-width: ${s}px) ${s}px`;
		// 	}).join(', ');
		// 	this.headerImage = this.eat.getPhotoUrl('s' + this.outW);
		// }
		if (this.eat) this.headerImage = this.eat.getPhotoUrl('s' + this.outW);

		if (!(this.foodPhotoOut && this.animalPhotoOut) && !this.eat) {
			if (this.c) this.c.style.opacity = '0';
			return false;
		}

		if (this.c) this.c.style.opacity = '1';

		this.createIconGrid();

		await this.loadIcon();

		await this.maskImages();

		if (!this.drawRunning) window.requestAnimationFrame(() => this.draw());
		this.drawRunning = true;
	}

	async makeHeaderImg() {
		this.scrollClicked = false;
		this.scroll = 0;
		const prevW = this.rawW;
		const out: { [s: string]: string } = {};
		for (const size of this.SIZES) {
			if (!this.c) throw new Error('no canvas');

			this.rawW = size;
			this.outW = this.getOutW(this.rawW);
			this.adjustHeight();
			this.c.width = this.outW;
			this.c.width = this.h;
			this.state = States.Initializing;
			await this.initDraw();
			out['s' + size] = this.c.toDataURL('image/jpeg', 0.8);
		}
		this.rawW = prevW;
		this.outW = this.getOutW(window.innerWidth);
		this.adjustHeight();
		this.state = States.Initializing;
		await this.initDraw();
		return out;
	}

	createIconGrid() {
		if (!this.outW || !this.h) throw new Error('uu');

		const offset = 40;
		const space = 60;
		let x = -offset;
		let y = -offset;
		let i = 1;

		while (x < this.outW + offset) {
			while (y < this.h + offset) {
				const tmp = i % 2 ? space / 2 : 0;
				this.iconGrid.push([x, y + tmp]);
				y += space;
			}
			y = -offset;
			x += space;
			i++;
		}
	}

	draw() {
		if (!this.c) return false;
		if (!this.ctx) return false;

		const isScrolled = this.preview ? this.scrollClicked : this.scroll > 4;

		if (this.state === States.Ready && isScrolled) this.state = States.Opening;

		if (
			this.maskedLeft &&
			this.maskedRight &&
			this.outW &&
			this.rawW &&
			this.iconImg &&
			this.foodColor &&
			![States.Initializing, States.Ready].includes(this.state)
		) {
			this.ctx.fillStyle = this.foodColor;
			this.ctx.fillRect(0, 0, this.outW, this.h);
			const opening0 = this.opening + 1;
			// 2000 - 2
			// 320 - 4

			const speed =
				1 + Math.pow(opening0, 1 / Math.pow(16000 / this.outW, 1 / 3));
			if (this.state === States.Opening) this.opening += speed;
			else if (this.state === States.Closing) this.opening -= speed;
			this.opening = Math.min(100, Math.max(0, this.opening));
			const position = (this.opening / 100) * this.outW;

			const offset = 40;
			const space = 60;
			let x = -offset;
			let y = 2 - offset;
			let i = 1;

			const parallaxScroll = this.scroll / 3;

			const mouseEffDist = this.outW / 8;

			while (x < this.outW + offset) {
				while (y - parallaxScroll < this.h + offset) {
					const tmp = i % 2 ? space / 2 : 0;
					const targetY = y + tmp - parallaxScroll;
					if (targetY < -offset || targetY > this.h + offset) {
						y += space;
						continue;
					}
					if (
						this.initialDevBeta &&
						this.devBeta &&
						this.initialDevGamma &&
						this.devGamma
					) {
						const scaleX =
							1 -
							(0.01 *
								(x / this.outW - 0.5) *
								(this.initialDevGamma - this.devGamma)) /
								this.initialDevGamma;
						const scaleY =
							1 +
							(0.002 *
								(targetY / this.h - 0.5) *
								(this.initialDevBeta - this.devBeta)) /
								this.initialDevBeta;
						const scale = scaleX * scaleY;
						this.ctx.scale(scale, scale);
						const drawX = x / scale - this.iconImg.width / 2;
						const drawY = targetY / scale - this.iconImg.height / 2;
						this.ctx.globalAlpha =
							(this.opening / 100) * 0.08 * Math.sqrt(scale);
						this.ctx.drawImage(this.iconImg, drawX, drawY);
						this.ctx.scale(1 / scale, 1 / scale);
					} else if (this.mouseX !== null && this.mouseY !== null) {
						const realMouseX = this.mouseX - (this.rawW - this.outW) / 2;
						const distX = x - realMouseX;
						const distY = targetY - this.mouseY;
						const dist = Math.sqrt(Math.pow(distX, 2) + Math.pow(distY, 2));
						let scale = 1;
						if (dist < mouseEffDist)
							scale = 1 + (mouseEffDist - dist) / (mouseEffDist * 2);
						this.ctx.scale(scale, scale);
						const drawX = x / scale - this.iconImg.width / 2;
						const drawY = targetY / scale - this.iconImg.height / 2;
						this.ctx.globalAlpha = (this.opening / 100) * 0.08 * scale;
						this.ctx.drawImage(this.iconImg, drawX, drawY);
						this.ctx.scale(1 / scale, 1 / scale);
					} else {
						this.ctx.globalAlpha = (this.opening / 100) * 0.08;
						const drawX = x - this.iconImg.width / 2;
						const drawY = targetY - this.iconImg.height / 2;
						this.ctx.drawImage(this.iconImg, drawX, drawY);
						// this.ctx.drawImage(this.iconImg, x, targetY);
					}

					// this.ctx.drawImage(this.iconImg, x, targetY);
					y += space;
				}
				y = -offset;
				x += space;
				i++;
			}

			this.ctx.globalAlpha = (100 - this.opening) / 100;

			if (this.state !== States.Open) {
				this.ctx.drawImage(this.maskedLeft, -1 * position, 0);
				this.ctx.drawImage(this.maskedRight, position, 0);
			}

			this.ctx.globalAlpha = 1;

			if (this.state === States.Opening && this.opening >= 100) {
				this.opening = 100;
				this.state = States.Open;
			} else if (!isScrolled && this.state !== States.Closing) {
				this.state = States.Closing;
			} else if (this.state === States.Closing && this.opening <= 0) {
				this.opening = 0;
				this.state = States.Ready;
			}
		}

		this.ngZone.runOutsideAngular(() => {
			window.requestAnimationFrame(() => this.draw());
		});
	}
}
