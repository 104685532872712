import * as Parse from "parse";
import { Injectable } from "@angular/core";

@Injectable()
export class AppConfig {
	constructor() {
		(Parse as any).serverURL = "https://parseapi.back4app.com"; // This is your Server URL
		// Remember to inform BOTH the Back4App Application ID AND the JavaScript KEY
		Parse.initialize(
			"fKpqdBeMiuYrhwb8ENnFuVeBIwhsyZSMWvRtKetz", // This is your Application ID
			"2ipNgwXey4nZoYCYht3ILt0auCDF6FRSVZMhOado" // This is your Javascript key
		);

		// Parse.initialize("cmj", "sk8jh65BGGF54");
		// (<any>Parse).serverURL =
		// 	"https://cmj-backend-lb-www.j5z17i493.at.d2c.io/parse";
		// // (<any>Parse).serverURL = 'https://wp7wwmui7z1cehfevd8g42wesyfrqu.biken.pl/1/';
	}
}
