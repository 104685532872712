import { Injectable } from '@angular/core';
import { EventEmitter } from 'events';
import { Observable, Observer } from 'rxjs';

@Injectable()
export class MissingService {
	observer: Observer<string> | null = null;
	constructor() {
		this.stream = new Observable(observer => {
			this.observer = observer;
		});
	}
	stream: Observable<string>;
	newMiss(type: string) {
		if (this.observer) this.observer.next(type);
	}
	removeMiss(type: string) {
		if (this.observer) this.observer.next(type + '---');
	}
}
